import { useCallback, useState } from 'react'
import { formatUserId, isUserIdValid } from '../ReferralEarnings.utils'
import { useAppSelector, useDebounce } from '@/utils'
import { t } from '@lingui/macro'

export const useSearchInput = (
  from: string,
  to: string,
  callbackFn: (value: string | undefined, start: string, end: string) => any
) => {
  const {
    earnings: { earningUser },
  } = useAppSelector(state => state.referrals)
  const [searchValueInput, setSearchValueInput] = useState('')
  const [searchValueError, setSearchValueError] = useState('')

  const searchInputHandler = useCallback(
    (value: string) => {
      setSearchValueInput(value)

      const valueFormatted = formatUserId(value.trim())

      if (valueFormatted === earningUser) return

      const valid = isUserIdValid(valueFormatted)

      if (!valid && valueFormatted.length > 0) {
        setSearchValueError(
          t({
            id: 'referralEarnings.wrong.uuid',
            comment:
              'Неверный UUID. Введите полный идентификатор из 36 символов в формате: xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx.',
          })
        )
      } else {
        setSearchValueError('')
      }

      if (valid) {
        searchHandler(valueFormatted, from, to)
      } else if (valueFormatted.length === 0) {
        searchHandler(undefined, from, to)
      }
    },
    [earningUser, from, to]
  )

  const searchHandler = useDebounce((value: string | undefined, start: string, end: string) => {
    callbackFn(value, start, end)
  }, 700)

  return {
    searchInputHandler,
    searchValueInput,
    searchValueError,
  }
}
