import { FC } from 'react'

import { SVG } from '@/assets/svg'
import { t } from '@lingui/macro'
import { Button, ButtonVariant, Hint, usePopperRef } from '@/ui/kit'
import style from '../../style.module.scss'
import { PinCodeModal } from './components/PinCodeModal/PinCodeModal'
import { useAppSelector, useFlag, useToggle } from '@/utils'

type ButtonState = {
  buttonText: string
  hint: string
  description: string
  disabled: boolean
}

export const PinCode: FC = () => {
  const [showPinCodeModal, togglePinCodeModal] = useToggle(false)

  const { pin } = useAppSelector(state => state.profile)
  const [ref, setRef] = usePopperRef<HTMLDivElement>()
  const [isVisible, , enableHint, disableHint] = useFlag()

  const getPinCodeButtonState = (enabledRaw?: boolean, resetStateRaw?: string): ButtonState => {
    const enabled = !!enabledRaw
    const resetState = resetStateRaw ?? 'READY' // fallback к READY

    switch (resetState) {
      case 'IN_PROGRESS':
        return {
          buttonText: t({ message: 'message sent', id: 'profile.pinCode.messageSent' }),
          hint: t({ id: 'profile.pinCode.inProgress.hint' }),
          description: t({ id: 'profile.pinCode.description.inProgress' }),
          disabled: true,
        }

      case 'USED':
        if (enabled) {
          return {
            buttonText: t({ message: 'reset', id: 'profile.whitelist.disable' }),
            hint: t({ id: 'profile.pinCode.reset.hint' }),
            description: t({ id: 'profile.pinCode.description.used' }),
            disabled: true,
          }
        } else {
          return {
            buttonText: t({ message: 'enable', id: 'profile.whitelist.enable' }),
            hint: t({ id: 'profile.whitelist.enable.hint' }),
            description: t({ id: 'profile.pinCode.description' }),
            disabled: false,
          }
        }

      case 'READY':
      default:
        if (enabled) {
          return {
            buttonText: t({ message: 'reset', id: 'profile.whitelist.disable' }),
            hint: t({ id: 'profile.pinCode.reset.hint' }),
            description: t({ id: 'profile.pinCode.description' }),
            disabled: false,
          }
        } else {
          return {
            buttonText: t({ message: 'enable', id: 'profile.whitelist.enable' }),
            hint: t({ id: 'profile.whitelist.enable.hint' }),
            description: t({ id: 'profile.pinCode.description' }),
            disabled: false,
          }
        }
    }
  }

  const pinCodeState = getPinCodeButtonState(pin.enabled, pin.resetState)

  return (
    <>
      <div className={style.setting}>
        <SVG.Settings.PinCode className={style.sectionIcon} />
        <div className={style.settingContainer}>
          <div className={style.settingTitle}>{t({ id: 'profile.pinCode.title' })}</div>
          <div className={style.settingStatus}>
            <div className={style.settingStatus}>{pinCodeState.description}</div>
          </div>
        </div>
        <div
          className={style.settingActions}
          ref={setRef}
          onMouseOver={enableHint}
          onFocus={enableHint}
          onMouseOut={disableHint}
          onBlur={disableHint}
        >
          <Button
            label={pinCodeState.buttonText}
            onClick={togglePinCodeModal}
            disabled={pinCodeState.disabled}
            variant={ButtonVariant.Primary}
          />
          {isVisible && <Hint placement={'bottom'} text={pinCodeState.hint} targetRef={ref} />}
        </div>
      </div>
      <PinCodeModal
        isOpen={showPinCodeModal}
        setIsOpen={togglePinCodeModal}
        isPinEnable={pin.enabled ? pin.enabled : false}
      />
    </>
  )
}
