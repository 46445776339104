import { TitleH2 } from '@/ui/atoms/TitleH2'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { TitleH3 } from '@/ui/atoms/TitleH3'

export const HowItWorksRightBar = () => {
  return (
    <div className={style.rightBar}>
      <TitleH2
        className={style.title}
        label={t({
          id: 'howitworks.title.msg-1',
          message: `Your commission reduction percentage is based on the trading volume in the last 30 days: the more you trade, the lower your commissions.`,
        })}
      />
      <TitleH3
        label={t({
          id: 'howitworks.subtitle.priorityRefcode',
          message: `Your referral code may affect the reduction value`,
        })}
        className={style.subtitle}
      />
    </div>
  )
}
