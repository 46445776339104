import { TAccountType } from '@/core/types/overview'

export type ReferralCustomCode = {
  code: string
  bonusPeriodDays: number
  bonusCommissionPercent: number
  bonusBinanceCashbackPercent: number
  bonusBybitCashbackPercent: number
}

export type ReferralOverviewResponseDTO = {
  userCode: string
  referrerCode: string
  referrerLocked: boolean
  invitedTotal: number
  invitedMonth: number
  referralVolumeMonth: number
  referralVolumeTotal: number
  profitMonth: number
  profitTotal: number
  customCodes: ReferralCustomCode[]
}

export type ApplyReferralCodeRequestDTO = {
  referrerCode: string
}

export type UpdateReferralCodeRequestDTO = {
  code: string
}

export type UpdateReferralCustomCodeRequestDTO = {
  oldCode: string
  newCode: string
}

export type GetReferralCodeInfoDTO = {
  id: string
  userId: string
  locked: boolean
}

export type CreateReferralCodeRequestDTO = {
  bonusPeriodDays?: number
  bonusBinanceCashbackPercent: number
  bonusBybitCashbackPercent: number
  isPermanent: boolean
}

export type CreateReferralCodeResponseDTO = ReferralCustomCode

export type TGetReferralEarningsPayload = {
  dateFrom: string //2023-08-23
  dateTo: string //2023-08-23
  userId?: string //ceaa8167-594b-4ede-b2fd-0ce79a875bbd, nullable
  exchangeSections?: ReferralsExchangeSectionPair
  awardClaimed?: boolean
  onlyTeamMembers?: boolean
  sortBy?: string
  sortDesc?: boolean
  page: number //0
  size: number //10
}

export type TGetReferralEarnings = {
  userId: string
  tradingDay: string //2023-08-24
  tradingCommission: number
  partnerEarnings: number
  tradingSection: string
  awardClaimed: boolean
  tradingVolume: number
  userJoined: string
}

export type TGetReferralEarningsDTO = {
  data: TGetReferralEarnings[]
}

export type TGetBenefitsDTO = {
  maxCommissionPercent: number
  maxReferrerCommissionPercent: number
}

export enum ReferralCodePercent {
  Binance = 'binance',
  Bybit = 'bybit',
  OKX = 'okx',
}

export type ReferralsExchangeSectionPair =
  | 'BINANCE_SPOT'
  | 'BINANCE_FUTURE'
  | 'BINANCE_FUTURE_COIN'
  | 'BYBIT_UNIFIED'
  | 'OKX_TRADING'
  | 'X_BINANCE_SPOT'
  | 'X_BINANCE_PERP'
  | 'X_OKX_SPOT'
  | 'X_OKX_PERP'

export type TGetReferralExchangeSectionsDTO = {
  data: {
    active: boolean
    exchangeSection: string
    id: ReferralsExchangeSectionPair
  }[]
}

export type TReferralEarningsExportDTO = {
  url: string
  fileBase64: string[]
}
