import { t } from '@lingui/macro'
import { PinScreenShow } from './components/PinCodeModal/PinCodeModal'

export const getPinHeaderTitle = (screenType: PinScreenShow) => {
  switch (screenType) {
    case PinScreenShow.SETUP:
      return t({
        id: 'profile.pinCode.setup.title',
      })
    case PinScreenShow.ENTER_PIN:
      return t({
        id: 'profile.pinCode.enterPin.title',
      })
    case PinScreenShow.RESET:
      return t({
        id: 'profile.pinCode.resetPin.title',
      })
    default:
      return ''
  }
}
