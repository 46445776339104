import { SVG } from '@/assets/svg'
import { ExchangeTypeConfig } from '../exchage.types'
import { urls } from '@/router/urls'

export enum ETigerXInstruments {
  'BINANCE_X' = 'BINANCE_X',
  'OKX_X' = 'OKX_X',
}

export enum ETigerXExchangeAccountOriginal {
  'TIGER_X' = 'TIGER_X',
}

export enum ETigerXAccount {
  'TIGER_X_BINANCE' = 'TIGER_X_BINANCE',
  'TIGER_X_OKX' = 'TIGER_X_OKX',
}

export enum ETigerXAccountOriginal {
  'OKX' = 'OKX',
  'BINANCE' = 'BINANCE',
}

// Temprora disabled deposit
const now = Date.now()

// Временной промежуток, когда депозиты недоступны (2:00–8:00 UTC 1 апреля)
const blockStart = Date.UTC(2025, 3, 1, 2, 0, 0) // 1 апреля, 02:00 UTC
const blockEnd = Date.UTC(2025, 3, 1, 10, 0, 0)

export const TigerXConfig: ExchangeTypeConfig = {
  name: 'Tiger X', // Имя биржи
  type: 'TIGER_X', // Тип биржи
  instruments: [ETigerXInstruments.BINANCE_X, ETigerXInstruments.OKX_X],
  accounts: ['TIGER_X_BINANCE', 'TIGER_X_OKX'],
  isAvailable: true,
  hasSubExchanges: true,
  pages: {
    profile: {
      bnbBurn: false,
      convertSmallBalance: false,
      assetMode: false,
      positionMode: false,
      additionalInfo: true,
    },
    api: {
      availableKeys: 5,
    },
    chooseExchange: {
      isAvailable: true,
      percent: 45,
    },
  },
  redirect: {
    redirectURL: urls.wallet,
    blockedPath: [urls.riskManager, '/journal'],
  },
  blockedNavMenu: [urls.journal],
  transactions: {
    transfer: {
      isAvailable: true,
    },
    deposit: {
      isAvailable: !(now >= blockStart && now < blockEnd),
      minDeposite: 10,
    },
    withdraw: {
      isAvailable: true,
      minWithdraw: 15,
    },
  },
}
