import { FC } from 'react'

type HoldProps = {
  className?: string
}
export const Hold: FC<HoldProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" className={className}>
    <path
      fill="#676573"
      d="M13.492 1.667H6.508c-3.033 0-4.841 1.808-4.841 4.841v6.975c0 3.042 1.808 4.85 4.841 4.85h6.975c3.034 0 4.842-1.808 4.842-4.841V6.508c.008-3.033-1.8-4.841-4.833-4.841Zm-.159 8.958H6.667A.63.63 0 0 1 6.042 10a.63.63 0 0 1 .625-.625h6.666a.63.63 0 0 1 .625.625.63.63 0 0 1-.625.625Z"
    />
  </svg>
)
