import { FC } from 'react'
import clsx from 'clsx'
import { Button } from '@/ui/kit'
import { SVG } from '@/assets/svg'
import { useOrder } from '@/ui/organisms/Order/hooks/useOrder'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import s from './style.module.scss'
import { TOpenOrder } from '@/backend/models/OverviewDTO'
import { ServicesOrderSide } from '@/utils/types/contracts.types'
import { SideIndicator } from '@/ui/kit/SideIndicator'
import { FormatNumberDotWithTooltip } from '@/ui/molecules/FormatNumberDotWithTooltip/FormatNumberWithTooltip'
import { formatPrice } from '@/ui/utils/format'
import { isExchangeTigerX } from '@/utils/lib/exchange'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { TpSlDisplay } from '@/ui/molecules'

const OrdersTable: FC<TOpenOrder> = order => {
  const { executedQty, price, priceOutput = price, side, symbol, tpTriggerPrice, slTriggerPrice } = order
  const { amount, typeToName, closeOrderHandler, deleting, accountTypeName } = useOrder(order)
  const { exchangeType } = useExchageType()
  const isTigerX = isExchangeTigerX(exchangeType)

  const futuresTypes = ['Futures', 'Swap', 'linear']
  const perpTypes = ['Perp']
  const marginTypes = ['Margin']

  return (
    <div
      className={clsx(s.order, s.table, { [s.deleted]: deleting, [s.tableTigerX]: isTigerX })}
      data-testid={DataTestIds.OrderListItem}
    >
      <div className={s.itemWrapper}>
        <div>
          {futuresTypes.includes(accountTypeName) ? (
            <SVG.Tmm.Futures />
          ) : perpTypes.includes(accountTypeName) ? (
            <SVG.Tmm.PerpIcon />
          ) : marginTypes.includes(accountTypeName) ? (
            <SVG.Tmm.MarginIcon />
          ) : (
            <SVG.Tmm.Spot />
          )}
        </div>
        <div className={s.currency}>
          <div className={s.noWrapContainer}>
            <div data-testid={`${DataTestIds.OrderSymbol}-${symbol}`}>{symbol}</div>
            <SideIndicator profit={side === ServicesOrderSide.OrderBuy} label={side} className={s.orderSide} />
          </div>

          <div className={s.quote} data-testid={`${DataTestIds.OrderType}-${symbol}`}>
            {typeToName}
          </div>
        </div>
      </div>

      <div className={s.item}>
        <div data-testid={`${DataTestIds.OrderPrice}-${symbol}`}>{formatPrice(priceOutput)}</div>
      </div>
      {isTigerX && (
        <div className={s.item}>
          <div data-testid={`${DataTestIds.OrderPrice}-${symbol}`}>
            <TpSlDisplay slTriggerPrice={slTriggerPrice} tpTriggerPrice={tpTriggerPrice} className={s.tpSl} />
          </div>
        </div>
      )}

      <div className={s.item}>
        <div data-testid={`${DataTestIds.OrderAmount}-${symbol}`}>
          <FormatNumberDotWithTooltip value={amount} symbol={symbol} qtyChars={5} />
        </div>
      </div>

      <div className={s.item} data-testid={`${DataTestIds.OrderStatus}-${symbol}`}>
        <div className={s.wrapper}>
          {executedQty}/<FormatNumberDotWithTooltip value={amount} qtyChars={5} />
        </div>
      </div>

      <Button.Primary
        onClick={closeOrderHandler}
        className={s.closeButton}
        leftIcon={<SVG.Additional.Close />}
        dataTestId={`${DataTestIds.CloseOrderButton}-${symbol}`}
      />
    </div>
  )
}

export { OrdersTable }
