import { ExchangeTypeConfig } from '../exchage.types'
import { urls } from '@/router/urls'
import { EBybitInstruments } from '@/services/bybit'

export const BybitConfig: ExchangeTypeConfig = {
  name: 'Bybit',
  type: 'BYBIT_BROKER',
  instruments: [EBybitInstruments.spot, EBybitInstruments.linear],
  accounts: ['BYBIT_UNIFIED'],
  isAvailable: true,
  hasSubExchanges: false,
  pages: {
    profile: {
      bnbBurn: false,
      convertSmallBalance: false,
      assetMode: false,
      positionMode: false,
      additionalInfo: true,
    },
    api: {
      availableKeys: 19,
    },
    chooseExchange: {
      isAvailable: true,
      percent: 82,
    },
  },
  redirect: {
    redirectURL: urls.wallet,
    blockedPath: [],
  },
  blockedNavMenu: [],
  transactions: {
    transfer: {
      isAvailable: false,
    },
    deposit: {
      isAvailable: true,
      minDeposite: null,
    },
    withdraw: {
      isAvailable: true,
      minWithdraw: 10,
    },
  },
}
