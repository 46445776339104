import { FC, memo, useEffect } from 'react'
import { TitleH1 } from '@/ui/atoms/TitleH1'
import style from './style.module.scss'
import { TeamAvatar } from '@/ui/atoms/TeamAvatar/TeamAvatar'
import { Team } from '@/ui/molecules/Team'
import { useTeamData } from '@/utils/hooks/useTeamData'
import { CardWrapper } from '@/ui/kit/CardWrapper'
import { Loader } from '@/ui/kit/Loader'
import { MembersDefaultCashback } from '@/ui/molecules/MembersDefaultCashback'
import { useActions, useAppSelector } from '@/utils'
import { teamActions } from '@/redux/team/team.slice'
import { useNavigate } from 'react-router-dom'
import { E_TEAM_USER_MODE } from '@/redux/team/team.types'
import { urls } from '@/router/urls'

type LeftBarPropsType = {}

const TeamLeftBar: FC<LeftBarPropsType> = () => {
  const navigate = useNavigate()
  const { teamInfoRequestInitialStatus, userMode } = useAppSelector(state => state.team)
  const { GetUserTeamInfoTC } = useActions(teamActions)

  const {
    userData: { teamManagerInfo, status },
  } = useTeamData()

  useEffect(() => {
    if (userMode !== E_TEAM_USER_MODE.manager) {
      navigate(urls.wallet)
    }
  }, [userMode, navigate])

  useEffect(() => {
    if (teamInfoRequestInitialStatus === 'idle') {
      GetUserTeamInfoTC()
    }
  }, [GetUserTeamInfoTC, teamInfoRequestInitialStatus])

  if (status !== 'succeeded')
    return (
      <CardWrapper className={style.loader}>
        <Loader />
      </CardWrapper>
    )

  const { teamLogoUrl, teamTitle } = teamManagerInfo

  return (
    <div className={style.leftBar}>
      <TeamAvatar teamUrl={teamLogoUrl} size={100} />
      <TitleH1 label={teamTitle} className={style.title} />
      <Team />
      <MembersDefaultCashback />
    </div>
  )
}

export default memo(TeamLeftBar)
