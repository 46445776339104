export type LeaderBoardUser = {
  name: string
  user_id: number
  value: string
}

export type PersonalResultUser = {
  name: string
  user_id: number
  value: string
  position: number
}

export type LeadersResultUser = {
  name: string
  user_id: number
  value: string
  position: number
  isUser: boolean
}

export type TypeLeaderList = {
  leaders: LeadersResultUser[]
  userLeader: PersonalResultUser | null
}

// Функция для поиска пользователя по name
export function findById(arr: LeaderBoardUser[], id: string): PersonalResultUser | null {
  if (!arr || arr.length === 0) {
    return null
  }

  const index = arr.findIndex(user => user.name === id)

  if (index !== -1) {
    // Возвращаем объект с добавлением поля position
    return {
      ...arr[index],
      position: index + 1, // Добавляем позицию, прибавляя 1, чтобы считать с 1, а не с 0
    }
  }

  return null
}

// Функция для выбора топ-3 пользователей из уже отсортированного массива
export function findTop3Users(arr: LeaderBoardUser[]): PersonalResultUser[] {
  if (!arr || arr.length === 0) {
    return []
  }

  // Выбираем первые три элемента из массива
  const top3 = arr.slice(0, 3).map((user, index) => ({
    ...user,
    position: index + 1, // Добавляем поле position (1, 2, 3)
  }))

  // Сортируем их в порядке как на олимпийском пьедестале: 2-е, 1-е, 3-е места
  const olympicOrder = [top3[1], top3[0], top3[2]].filter(Boolean)

  return olympicOrder
}

// Функция для поиска 5 пользователей с максимальным значением value (по алфавиту)
export function find5UsersByPosition(arr: LeaderBoardUser[], currentUserId: string): TypeLeaderList {
  if (!arr || arr.length === 0) {
    return {
      leaders: [],
      userLeader: null,
    }
  }

  // 1. Добавляем позицию каждому пользователю и выбираем топ-5 лидеров
  const leaders = arr.slice(0, 5).map((user, index) => ({
    ...user,
    position: index + 1,
    isUser: user.name === currentUserId,
  }))

  // 2. Если текущего пользователя нет в топ-5, находим его и добавляем позицию
  const isUserInTop5 = leaders.some(user => user.isUser)

  let userLeader = null

  if (!isUserInTop5) {
    arr.map((user, index) => {
      if (user.name === currentUserId) {
        userLeader = {
          ...user,
          position: index + 1,
        }
      }
    })
  }

  return {
    leaders,
    userLeader,
  }
}

export function formatNumberWithCommas(number: number): string {
  if (typeof number !== 'number' || isNaN(number)) {
    return '-'
  }

  const formattedNumber = number.toFixed(2) // Округляем до двух знаков после запятой

  return Number(formattedNumber).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}
