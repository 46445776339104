import { LeaderBoardAPI } from '@/backend/api/leaderboard.api'
import { LeadersRequestPayload } from '@/backend/api/leaderboard.api'
import { ThunkError } from '@/types'
import { ThunkCreator } from '@/utils/lib/ThunkCreator'
import { createAsyncThunk } from '@reduxjs/toolkit'
import nameof from 'ts-nameof.macro'

export const GetSpotLeaderboardTC = createAsyncThunk<any, LeadersRequestPayload, ThunkError>(
  nameof(LeaderBoardAPI.getSpotLeaders),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: LeaderBoardAPI.getSpotLeaders, payload }, thunkAPI)
  }
)

export const GetFuturesLeaderboardTC = createAsyncThunk<any, LeadersRequestPayload, ThunkError>(
  nameof(LeaderBoardAPI.getFuturesLeaders),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: LeaderBoardAPI.getFuturesLeaders, payload }, thunkAPI)
  }
)

export const GetPnlLeaderboardTC = createAsyncThunk<any, LeadersRequestPayload, ThunkError>(
  nameof(LeaderBoardAPI.getPnlLeaders),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: LeaderBoardAPI.getPnlLeaders, payload }, thunkAPI)
  }
)

export const GetTeamMemberTC = createAsyncThunk<any, void, ThunkError>(
  nameof(LeaderBoardAPI.getMember),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: LeaderBoardAPI.getMember, payload }, thunkAPI)
  }
)

export const PutUpdateNicknameTC = createAsyncThunk<any, any, ThunkError>(
  nameof(LeaderBoardAPI.updateNickname),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: LeaderBoardAPI.updateNickname, payload }, thunkAPI)
  }
)
