import { TExchangesAvailable } from '@/core/constants'
import { ButtonVariant } from '@/ui/kit'
import { FC, ReactNode } from 'react'

export enum NotififcationPresets {
  OKXAffiliateAlert = 'okx-affiliate-alert',
  BinanceTechnicalWork = 'binance-technical-work',
  CashbackDelayAlert = 'cashback-alert',
  OkxSuspensionAlert = 'okx-suspension-alert',
  TradeSuspendedAlert = 'trading-alert',
  TigerXTechnicalWork = 'tigerx-technical-work',
}

export enum SystemNotifiactionIcon {
  Alert = 'alert',
}

export type UniqId = string

interface IModalProps {
  isOpen: boolean
  onClose: () => void
}

export type ModalComponent = FC<IModalProps>

export interface INotificationConfig {
  /** Unique id for notification (must always be static) */
  id: NotififcationPresets

  /**  */
  icon?: SystemNotifiactionIcon

  /**  */
  heading?: ReactNode

  /** */
  description?: ReactNode

  /** Special conditions for displaying notification */
  shouldShown?: boolean

  /** A “Details” button is added. When clicked, the transmitted component is rendered */
  Modal?: ModalComponent
  modalBtnClassname?: string

  /** Exchanges for which the notification will be shown */
  availableExchange?: TExchangesAvailable[]

  isUseHandleButton?: boolean

  /** Adding a close button to the notification */
  isClosable?: boolean
  link?: {
    uri: string
    external?: boolean
  }
}

export interface INotificationRenderProps {
  id: NotififcationPresets
  heading?: ReactNode
  description?: ReactNode
  icon?: SystemNotifiactionIcon
  Modal?: ModalComponent
  onClose?: () => void
  isMobile?: boolean
  isClosable?: boolean
  modalBtnClassname?: string
  isUseHandleButton?: boolean
  link?: {
    uri: string
    external?: boolean
  }
}
