import { FC } from 'react'

type PinCodeProps = {
  className?: string
}
const PinCode: FC<PinCodeProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={41} fill="none" className={className}>
    <rect width={40} height={40} y={0.5} fill="url(#a)" rx={20} />
    <path
      fill="#FAFAFA"
      d="M14.25 12.5c-2.21 0-4 1.79-4 4v8c0 2.21 1.79 4 4 4h5c.55 0 1-.45 1-1v-14c0-.55-.45-1-1-1h-5Zm.17 8.38c-.05.12-.12.23-.21.33-.1.09-.21.16-.33.21-.12.05-.25.08-.38.08s-.26-.03-.38-.08-.23-.12-.33-.21c-.09-.1-.16-.21-.22-.33a.986.986 0 0 1-.07-.38c0-.26.11-.52.29-.71l.15-.12c.06-.04.12-.07.18-.09.06-.03.12-.05.18-.06.34-.07.68.04.91.27.18.19.29.45.29.71 0 .13-.03.26-.08.38Zm3.5 0c-.05.12-.12.23-.21.33-.1.09-.21.16-.33.21-.12.05-.25.08-.38.08s-.26-.03-.38-.08-.23-.12-.33-.21a.99.99 0 0 1-.21-1.09c.05-.13.12-.23.21-.33.37-.37 1.04-.37 1.42 0 .18.19.29.45.29.71 0 .13-.03.26-.08.38ZM25.75 12.5H23.5v-1.25c0-.41-.34-.75-.75-.75s-.75.34-.75.75v18.5c0 .41.34.75.75.75s.75-.34.75-.75V28.5h2.25c2.21 0 4-1.79 4-4v-8c0-2.21-1.79-4-4-4Z"
    />
    <defs>
      <linearGradient id="a" x1={0} x2={30.351} y1={40.5} y2={-5.822} gradientUnits="userSpaceOnUse">
        <stop stopColor="#BFA8FF" />
        <stop offset={0.472} stopColor="#FF51BC" />
        <stop offset={1} stopColor="#FC4F49" />
      </linearGradient>
    </defs>
  </svg>
)
export { PinCode }
