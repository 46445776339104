import { PinInput } from '@/ui/organisms/PinInput'
import { FC, useEffect, useState } from 'react'
import style from './style.module.scss'
import { AlertMessage } from '@/ui/organisms/AlertMessage'
import { Button, ButtonSize, ButtonVariant } from '@/ui/kit'
import { t } from '@lingui/macro'
import { PinScreenShow, PinScreenShowType } from '../../PinCodeModal'
import { Trans } from '@lingui/react'
import { useActions, useAppSelector } from '@/utils'
import { profileActions } from '@/redux/profile/profile.slice'

const PIN_LENGTH = 6

type PinSet = PinScreenShowType

export const PinSet: FC<PinSet> = ({ setPinScreenShow }) => {
  const [pin, setPin] = useState('')
  const [hasError, setHasError] = useState(false)
  const { setPinStatus } = useAppSelector(state => state.profile)
  const isDisabled = setPinStatus === 'loading' || hasError || pin.length < PIN_LENGTH
  const { SetPin, GetProfileTC } = useActions(profileActions)

  const btnTitle = isDisabled
    ? t({
        id: 'profile.whitelist.next',
        message: `next`,
      })
    : t({
        id: 'profile.pinCode.submit',
      })

  const setPinHandler = () => {
    SetPin({
      pin: pin,
    })
  }

  useEffect(() => {
    if (setPinStatus === 'succeeded') {
      setPinScreenShow(PinScreenShow.SUCCESS)
      GetProfileTC()
    }
  }, [setPinStatus])

  return (
    <div className={style.container}>
      <PinInput
        value={pin}
        onChange={setPin}
        setHasError={setHasError}
        checkPatternPincode
        helpText={t({ id: 'core.pinInput.error.subsequence' })}
      />
      <AlertMessage
        text={t({
          id: 'profile.pinCode.enterPin.alert',
        })}
      />
      <div className={style.btnContainer}>
        <div className={style.btns}>
          <Button
            variant={ButtonVariant.Primary}
            size={ButtonSize.Medium}
            onClick={() => setPinScreenShow(PinScreenShow.SETUP)}
          >
            {t({
              id: 'profile.pinCode.enterPin.backButton',
            })}
          </Button>
          <Button variant={ButtonVariant.Accent} size={ButtonSize.Medium} disabled={isDisabled} onClick={setPinHandler}>
            {btnTitle}
          </Button>
        </div>
        <div className={style.support}>
          <Trans
            id="profile.pinCode.enterPin.support"
            components={{
              link: (
                <a
                  className={style.link}
                  href={`mailto:${t({ id: 'core.supportEmail', message: 'broker.support@tiger.trade' })}`}
                />
              ),
            }}
          />
        </div>
      </div>
    </div>
  )
}
