import style from './style.module.scss'
import { StatisticsTransfer } from '../StatisticsTransfer'
import { useStatisticsWidget } from './hooks/useStatisticsWidget'
import { formatNumber } from '@/utils'
import { LabelWithQuestion } from '@/ui/molecules'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { t } from '@lingui/macro'
import clsx from 'clsx'
import { formatDate } from '@/utils/lib/formatDate'
import { FC } from 'react'
import { isExchangeBinance, isExchangeBybit } from '@/utils/lib/exchange'

const OrdersStatisticsWidget: FC = () => {
  const {
    totalMarginBalance,
    unrealizedPnl,
    exchangeType,
    symbol,
    isLoading,
    balancesArray,
    statisticsStartMoment,
    statisticsMoment,
    resetStatistics,
  } = useStatisticsWidget()

  if (isLoading) {
    return null
  }

  return (
    <div className={style.widget}>
      <div className={style.statistics}>
        <div className={style.labelWrap}>
          <LabelWithQuestion
            hintText={t({
              message: 'This is account balance plus your Unrealized PnL',
              id: 'mainPage.topBarLabels-1.hint',
            })}
            label={t({ message: 'Margin balance', id: 'mainPage.topBarLabels-1.label' })}
            dataTestId={DataTestIds.WalletMarginBalanceText}
          />
          <span className={style.amount} data-testid={DataTestIds.WalletMarginBalance}>
            {formatNumber(totalMarginBalance)}&nbsp;
            <span className={style.symbol}>{symbol}</span>
          </span>
        </div>

        <div className={style.labelWrap}>
          <LabelWithQuestion
            hintText={t({
              message: 'This is current Profit and Loss on open positions.',
              id: 'mainPage.topBarLabels-2.hint',
            })}
            label={t({ message: 'Unrealized PnL', id: 'mainPage.topBarLabels-2.label' })}
            dataTestId={DataTestIds.WalletUnrealizedPnlText}
          />
          <span className={style.amount} data-testid={DataTestIds.WalletUnrealizedPnl}>
            {formatNumber(unrealizedPnl)}&nbsp;
            <span className={style.symbol}>{symbol}</span>
          </span>
        </div>

        {balancesArray.map((item, index) => {
          if (!item.profit) {
            return null
          }

          return (
            <div key={index} className={style.pnlSince}>
              <div className={style.leftPart}>
                <span className={style.title}>
                  {statisticsStartMoment ? (
                    <>
                      {t({
                        id: 'statistics.afterReset',
                        comment: 'Changes after',
                      })}
                      &nbsp;{formatDate(Number(statisticsMoment))}
                    </>
                  ) : (
                    t({
                      id: 'statistics.today',
                      comment: 'Changes for today',
                    })
                  )}
                </span>
                <LabelWithQuestion
                  hintText={t({
                    id: 'statistics.reset.hint',
                  })}
                />
                <span className={style.reset} onClick={resetStatistics}>
                  {t({ id: 'statistics.reset', comment: 'Reset statistic' })}
                </span>
              </div>

              <span
                className={clsx(style.rightPart, {
                  [style.colorGreen]: +item.profit > 0,
                  [style.colorRed]: +item.profit < 0,
                })}
              >
                {item.profit} <span className={style.symbol}>USDT</span>
              </span>
            </div>
          )
        })}
      </div>

      {isExchangeBinance(exchangeType) && (
        <div className={style.statisticsTransfer}>
          <StatisticsTransfer />
        </div>
      )}
    </div>
  )
}

export { OrdersStatisticsWidget }
