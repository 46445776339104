import { useEffect, useState } from 'react'
import { useActions, useAppSelector } from '@/utils'
import overviewActions from '@/redux/overview/overview.actions'
import { EXCHANGES_TYPE } from '@/core/constants'
import { AccountOverviewPositionsView, TSetPositionMarginPayloadDTO } from '@/backend/models/OverviewDTO'
import { ActionMargin, TOGGLE_DATA } from './consts'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { LabelOption } from './ToggleButton/ToggleButton'
import { useOverview } from '@/utils/hooks/useOverview'
import {
  trimTo4Decimals,
  trimTo4DecimalsBybit,
  trimTo8DecimalsBinance,
} from '@/ui/molecules/FormatNumberDotWithTooltip/utils'

export const useHandleMargin = (position: AccountOverviewPositionsView) => {
  const { exchangeType } = useExchageType()
  const { allPositions } = useOverview()
  const { GetAccountInformationV3TC, SetPositionMarginTC } = useActions(overviewActions)
  const { isLoadingMargin, accountV3, accountType, walletBalanceBybit } = useAppSelector(state => state.overview)
  const [option, setOption] = useState<LabelOption>(TOGGLE_DATA.ADD)
  const [amount, setAmount] = useState('')
  const [maxAmount, setMaxAmount] = useState<number | null>(null)

  const isBinance = exchangeType === EXCHANGES_TYPE.BINANCE_BROKER_FUTURE
  useEffect(() => {
    if (isBinance) {
      GetAccountInformationV3TC({})
    }
  }, [option, exchangeType])

  useEffect(() => {
    if (isBinance) {
      GetAccountInformationV3TC({ isPooling: true })

      const interval = setInterval(() => {
        GetAccountInformationV3TC({ isPooling: true })
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [isBinance, exchangeType])

  useEffect(() => {
    // ADD MARGIN
    if (!isLoadingMargin && option.value === ActionMargin.ADD) {
      // Binance
      if (accountV3 && isBinance) {
        const { totalCrossWalletBalance, totalOpenOrderInitialMargin, totalMaintMargin, availableBalance } = accountV3
        const totalBalance =
          parseFloat(totalCrossWalletBalance) - parseFloat(totalOpenOrderInitialMargin) - parseFloat(totalMaintMargin)
        const maxAddable = Math.max(0, Math.min(totalBalance, parseFloat(availableBalance)))

        setMaxAmount(trimTo8DecimalsBinance(maxAddable))
        return
      }

      // Bybit
      if (!isBinance && walletBalanceBybit) {
        const { coin, totalEquity } = walletBalanceBybit
        const totalPositionBalance = allPositions.reduce(
          (sum: number, item: any) => sum + parseFloat(item.positionBalance || '0'),
          0
        )

        const totalOrderIM = coin.reduce((sum: number, item: any) => sum + parseFloat(item.totalOrderIM || '0'), 0)

        const totalOrderMM = coin.reduce((sum: number, item: any) => sum + parseFloat(item.totalPositionMM || '0'), 0)

        const currentPosition = allPositions.find(
          (item: AccountOverviewPositionsView) => item.baseAsset === position.baseAsset
        )

        if (currentPosition) {
          // Formula = max (0, min(
          // totalEquity - totalOrderIM - totalPositionMM,
          // totalEquity - sum(positionBalance),
          // positionValue-positionBalance))

          const totalBalance = parseFloat(totalEquity) - totalOrderIM - totalOrderMM

          const totalAvailablaBalance = parseFloat(totalEquity) - totalPositionBalance

          const totalPositionValue =
            parseFloat(currentPosition.positionValue || '0') - parseFloat(currentPosition.positionBalance || '0')

          const maxAddable = Math.max(0, Math.min(totalBalance, totalAvailablaBalance, totalPositionValue))

          setMaxAmount(trimTo4DecimalsBybit(maxAddable))
          return
        }
      }
    }

    // REMOVE MARGIN
    if (!isLoadingMargin && option.value === ActionMargin.REMOVE) {
      // Binance
      if (accountV3 && accountV3.positions && accountV3.positions.length > 0 && position.isolatedMargin) {
        const currentPosition = accountV3.positions.find(item => item.symbol === position.symbol)

        //         # Данные из API
        // isolated_wallet_balance = 0.16914634  # isolatedWallet
        // initial_margin = 0.46135821  # initialMargin
        // maint_margin = 0.09688521  # maintMargin
        // notional = 6.45901424  # notional (Mark Price * abs(size))
        // unrealized_profit = 0.93421424  # unrealizedProfit
        // position_amt = 16.0  # positionAmt (размер позиции)

        // # Вычисляем максимальную сумму снятия
        // max_removable = max(0, min(
        //     isolated_wallet_balance - maint_margin,
        //     isolated_wallet_balance + unrealized_profit - notional * initial_margin / abs(position_amt)

        if (
          currentPosition?.isolatedMargin &&
          currentPosition?.initialMargin &&
          currentPosition?.isolatedWallet &&
          position?.notional &&
          position?.isolatedMargin &&
          position?.positionAmt
        ) {
          const firstPart = parseFloat(currentPosition.isolatedMargin) - parseFloat(currentPosition.initialMargin)
          const walletBalanceAndUProfit =
            parseFloat(position?.isolatedMargin) / Math.abs(parseFloat(position.positionAmt))

          const result = Math.min(
            firstPart,
            parseFloat(currentPosition.isolatedWallet) +
              parseFloat(currentPosition.unrealizedProfit) -
              parseFloat(position.notional) * walletBalanceAndUProfit
          )

          const maxRemovable = Math.max(0, result)

          setMaxAmount(trimTo8DecimalsBinance(maxRemovable))
          return
        }

        setMaxAmount(null)
        return
      }

      // Bybit
      if (!isBinance && position.positionIM && position.positionBalance) {
        const maxRemovable = Math.max(0, parseFloat(position.positionBalance) - parseFloat(position.positionIM))
        setMaxAmount(trimTo4DecimalsBybit(maxRemovable))
        return
      }
    }

    setMaxAmount(null)
  }, [isLoadingMargin, accountV3, option, position, walletBalanceBybit])

  const submitHandler = () => {
    let submitConfig: TSetPositionMarginPayloadDTO | any

    if (accountType) {
      const actionType = option.value === ActionMargin.ADD ? 1 : 2

      if (isBinance) {
        submitConfig = {
          accountType: accountType,
          symbol: position.symbol,
          positionSide: position.positionSide,
          amount: trimTo8DecimalsBinance(parseFloat(amount)),
          type: actionType,
        }
      } else {
        const margin = option.value === ActionMargin.ADD ? parseFloat(amount) : -parseFloat(amount)

        submitConfig = {
          accountType: accountType,
          symbol: position.symbol,
          margin: trimTo4Decimals(margin),
          positionIdx: position.positionIdx,
        }
      }

      SetPositionMarginTC(submitConfig)
    }
  }

  return {
    isBinance,
    maxAmount,
    amount,
    setAmount,
    option,
    setOption,
    submitHandler,
    isLoadingMargin,
  }
}
