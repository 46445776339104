export const truncateEmail = (email?: string, maxLength: number = 18): string => {
  if (!email || typeof email !== 'string') return '' // Проверяем, что email — это строка

  const [localPart, domain] = email.split('@')

  if (!domain) return email // Если email некорректный (без @), просто возвращаем его

  if (localPart.length > maxLength) {
    return `${localPart.slice(0, maxLength)}...@${domain}`
  }

  return email
}
