import { t } from '@lingui/macro'
import clsx from 'clsx'
import { FC, memo, ReactElement, ReactNode } from 'react'
import { SVG } from '@/assets/svg'
import { Status as StatusVariant, Status as TStatus } from '@/backend/types'
import { CopyIcon } from '@/ui/kit'
import { commonIcons } from '@/ui/kit'
import { ButtonVariant, Button as UiKitButton, IButtonProps as UiKitButtonProps } from '@/ui/kit/Button'
import { YoutubePlayer } from '@/ui/molecules/YoutubePlayer/YoutubePlayer'
import { Extends, makeShortString } from '@/utils'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import style from './style.module.scss'

/** Icon above Header */
const Icon = memo<IconProps>(({ icon, className }) => {
  return (
    <div className={clsx(style.icon, className)} data-testid={DataTestIds.HistoryModalIcon}>
      {icon && commonIcons[icon]}
    </div>
  )
})

export type IconProps = { icon?: keyof typeof commonIcons; className?: string }

/** Header od the Modal, goes at the top */
const Header = memo<HeaderProps>(({ text, className }) => {
  return (
    <div className={clsx(style.header, className)} data-testid={DataTestIds.HistoryModalHeader}>
      {text}
    </div>
  )
})

type HeaderProps = { text: string; className?: string }

/** SubHeader of the modal, goes between other components */
const SubHeader = memo<SubHeaderProps>(({ text, className }) => {
  return (
    <div className={clsx(style.subHeader, className)} data-testid={DataTestIds.ModalConfirmTitle}>
      {text}
    </div>
  )
})
type SubHeaderProps = { text: ReactNode; className?: string }

/** SubHeader of the modal, goes between other components */
const SubHeaderL2 = memo<SubHeaderL2Props>(({ text, className }) => {
  return (
    <div className={clsx(style.subHeaderL2, className)} data-testid={DataTestIds.ModalConfirmInfo}>
      {text}
    </div>
  )
})

type SubHeaderL2Props = { text: ReactNode; className?: string }

/** SubHeader of the modal, goes between other components */
const SubHeaderL3 = memo<SubHeaderL3Props>(({ text }) => {
  return (
    <div className={style.subHeaderL3} data-testid={DataTestIds.ModalConfirmInfo}>
      {text}
    </div>
  )
})

type SubHeaderL3Props = { text: ReactNode }

/** Text below Header */
const Explanation = memo<ExplanationProps>(({ text, className }) => {
  return (
    <div className={clsx(style.explanation, className)} data-testid={DataTestIds.ExplanationField}>
      {text}
    </div>
  )
})

type SubExplanationProps = { text: ReactNode; className?: string }
/** Text below SubHeader */
const SubExplanation = memo<SubExplanationProps>(({ text, className }) => {
  return (
    <div className={clsx(style.subExplanation, className)} data-testid={DataTestIds.ModalConfirmInfo}>
      {text}
    </div>
  )
})

type ExplanationProps = { text: ReactNode; className?: string }

/** Main data at the Modal, the biggest text */
const MainInfo = memo<MainInfoProps>(({ text, className }) => {
  return (
    <div
      className={clsx(style.mainInfo, className, {
        [style.mainInfo_small]: text.length > 18,
        [style.mainInfo_extraSmall]: text.length > 24,
      })}
      data-testid={DataTestIds.HistoryModalAmount}
    >
      {text}
    </div>
  )
})

type MainInfoProps = { text: string; className?: string }

/** Status of the operation: icon + date */
const Status = memo<StatusProps>(({ status, date, className }) => {
  /** mapped object to get icon and text for Status */
  //todo: remove it after fix lingui
  const statusVariants: Record<TStatus, { icon: ReactElement; name: string }> = {
    loading: {
      icon: <SVG.Status.Pending />,
      name: t({
        id: 'core.status.pending',
        message: `Pending`,
      }),
    },
    succeeded: {
      icon: <SVG.Status.Completed />,
      name: t({
        id: 'core.status.completed',
        message: `Completed`,
      }),
    },
    failed: {
      icon: <SVG.Status.Failed />,
      name: t({
        id: 'core.status.failed',
        message: `Failed`,
      }),
    },
    idle: {
      icon: <>add icon if sometimes needed</>,
      name: t({
        id: 'core.status.idle',
        message: `Idle`,
      }),
    },
  }

  return (
    <div className={clsx(style.statusAndDate, style[status], className)}>
      {statusVariants[status].icon}
      <span className={style.statusName} data-testid={DataTestIds.HistoryModalStatus}>
        {statusVariants[status].name}
      </span>
      {date && (
        <span className={style.statusDate} data-testid={DataTestIds.HistoryModalDate}>
          {date}
        </span>
      )}
    </div>
  )
})

export type StatusProps = { status: StatusVariant; date?: string; className?: string }

/** Grid to display data at the left and right */
const Table = memo<TableProps>(({ left, right, dataTestId, className }) => {
  return (
    <div className={clsx(style.tableItem, className)}>
      <span>{left}</span>
      <span data-testid={dataTestId}>{right}</span>
    </div>
  )
})

type TableProps = { left: string | number; right: string | number; dataTestId?: string; className?: string }

/** The same as Table, but string at the right can be copied to clipboard */
const TableCopy = memo<TableCopyProps>(({ left, right, isShortenView, dataTestId, className }) => {
  return (
    <div className={clsx(style.tableItem, className)}>
      <span>{left}</span>
      <span data-testid={dataTestId}>
        {isShortenView ? makeShortString(String(right)) : right}

        <CopyIcon
          text={String(right)}
          className={style.copyButton}
          dataTestId={`${dataTestId}${DataTestIds.BtnSuffix}`}
        />
      </span>
    </div>
  )
})

type TableCopyProps = TableProps & {
  isShortenView?: boolean
  dataTestId?: string
  className?: string
}

/** The same as Table, but string at the right can be copied to clipboard */
const Button = memo<ButtonProps>(
  ({ additionalText, text, variant, icon, onClick, isDisabled, dataTestId, className }) => {
    return (
      <UiKitButton
        variant={variant}
        leftIcon={icon}
        onClick={onClick}
        label={text}
        description={additionalText}
        disabled={isDisabled}
        className={clsx(variant === ButtonVariant.Clean && style.clean, style.wrap, className)}
        dataTestId={dataTestId}
      />
    )
  }
)

type ButtonProps = Pick<UiKitButtonProps, 'onClick'> & {
  text: string
  additionalText?: string
  variant: Extends<
    ButtonVariant,
    ButtonVariant.Accent | ButtonVariant.White | ButtonVariant.Primary | ButtonVariant.Clean
  >
  icon?: ReactElement<any, any>
  onClick?: () => void
  isDisabled?: boolean
  dataTestId?: string
  className?: string
}

const HeaderIcon: FC = props => {
  return <div className={style.headerIcon}>{props.children}</div>
}

type SingleValueProps = {
  label: string
  value: string
}

const SingleValue: FC<SingleValueProps> = props => {
  return (
    <div className={style.single}>
      {props.label}
      <span className={style.separator}> • </span>
      {props.value}
    </div>
  )
}

export type TSheetItem = {
  title: string
  value: string | number
}

const SheetItem: FC<{ sourceItem: TSheetItem }> = ({ sourceItem }) => {
  return (
    <div className={style.sheetItem}>
      <div className={style.sheetItemTitle}>{sourceItem.title}</div>
      <div>{sourceItem.value}</div>
    </div>
  )
}

const Sheet: FC<{ source: TSheetItem[] }> = ({ source }) => {
  return (
    <div className={style.sheet}>
      {source.map((sourceItem, index) => {
        return <SheetItem key={index} sourceItem={sourceItem} />
      })}
    </div>
  )
}

const Player: FC<{ videoId: string }> = ({ videoId }) => {
  return (
    <div className={style.player}>
      <YoutubePlayer videoId={videoId} />
    </div>
  )
}

export const InteractiveModalParts = {
  Table,
  HeaderIcon,
  Header,
  MainInfo,
  Icon,
  SubExplanation,
  SubHeader,
  SubHeaderL2,
  SubHeaderL3,
  Explanation,
  Status,
  TableCopy,
  Button,
  SingleValue,
  Sheet,
  Player,
}
