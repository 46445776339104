import axios, { AxiosResponse } from 'axios'
import { getAuthToken } from '../exchangeAuthToken'

export const TIGER_X_API_URL = process.env.REACT_APP_TIGERX_URI
  ? `${process.env.REACT_APP_TIGERX_URI}/protected`
  : 'https://x-api.tiger.black/protected'

const tigerxAxiosRestClient = axios.create({
  baseURL: TIGER_X_API_URL,
  timeout: 10000, // Можно уменьшить таймаут, если нужно быстрее получать ответ
  headers: {
    Accept: 'application/json',
  },
})

// ✅ Динамически получаем токен перед каждым запросом
tigerxAxiosRestClient.interceptors.request.use(
  config => {
    const token = getAuthToken() // 🛠 Теперь каждый запрос использует актуальный токен
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => Promise.reject(error)
)

// Сервис для запроса getAccount без рекурсивного пуллинга
export const tigerxRest = {
  async getAccountData(): Promise<AxiosResponse<any>> {
    try {
      const response = await tigerxAxiosRestClient.get('/api/v1/trading/account')
      return response
    } catch (error) {
      console.error('Ошибка при получении аккаунта:', error)
      throw error
    }
  },
}
