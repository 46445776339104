import { tigerBackRest } from './tigerBack.rest'

export interface ApiError {
  source: string
  status: number
  message: string
}

export const TigerBackApi = {
  getDepositData: async () => {
    try {
      const [assetsRes] = await Promise.all([
        tigerBackRest.getCoins().catch(err => {
          throw { source: 'assets', status: err.response?.status || 500, message: err.message }
        }),
      ])

      const mappingDepositData = (data: any[]) => {
        return (
          data
            // 1. Фильтруем по subExchangeType, если оно есть, оставляем только 'BINANCE'
            .filter(item => !item.subExchangeType || item.subExchangeType === 'BINANCE')

            // 2. Сортируем по coin
            .sort((a, b) => a.coin.localeCompare(b.coin))

            // 3. Фильтруем networks по depositEnable === true
            .map(item => ({
              ...item,
              networks: item.networks.filter((network: any) => network.depositEnable),
            }))
        )
      }

      const filteredDepositOptions = mappingDepositData(assetsRes.data)

      return {
        options: filteredDepositOptions,
      }
    } catch (error: unknown) {
      const apiError = error as ApiError
      console.error('Error deposit data:', apiError)

      return {
        error: {
          source: apiError.source,
          status: apiError.status,
          message: apiError.message,
        },
      }
    }
  },
  getWithdrawData: async () => {
    try {
      const [assetsRes, balanceRes, limitsRes, addressesRes, whitelistRes, withdrawRes] = await Promise.all([
        tigerBackRest.getCoins().catch(err => {
          throw { source: 'assets', status: err.response?.status || 500, message: err.message }
        }),
        tigerBackRest.getCoinsBalance().catch(err => {
          throw { source: 'balance', status: err.response?.status || 500, message: err.message }
        }),
        tigerBackRest.getAvailableLimitsKYC().catch(err => {
          throw { source: 'limits', status: err.response?.status || 500, message: err.message }
        }),
        tigerBackRest.getWithdrawAddress().catch(err => {
          throw { source: 'addresses', status: err.response?.status || 500, message: err.message }
        }),
        tigerBackRest.getWhiteList().catch(err => {
          throw { source: 'whitelist', status: err.response?.status || 500, message: err.message }
        }),
        tigerBackRest.getWithdraw().catch(err => {
          throw { source: 'withdraw', status: err.response?.status || 500, message: err.message }
        }),
      ])

      const withdraw = {
        canWithdraw: withdrawRes.data.canWithdraw,
        blockType: withdrawRes.data.blockType ?? null,
        pinCodeMaxAttempts: withdrawRes.data.pinCodeMaxAttempts,
        pinCodeCurrentAttempt: withdrawRes.data.pinCodeCurrentAttempt,
      }

      // Добавляем баланс к каждому коину в assets
      const addBalanceToAssets = (assets: any[], balances: any[]) => {
        return assets
          .map(asset => {
            const { coin, subExchangeType } = asset

            const matchedBalance = balances.find(b => {
              if (subExchangeType) {
                return b.asset === coin && b.subExchange === subExchangeType
              }
              return b.asset === coin
            })

            return {
              ...asset,
              balance: matchedBalance?.balance ?? 0,
            }
          })
          .filter(asset => Number(asset.balance) >= 0.0001)
      }
      const mappingWithdrawData = (data: any[]) => {
        if (data.length === 0) return data

        // если есть subExchangeType то сортируем по нему если не то по coin
        const hasSubExchangeType = 'subExchangeType' in data[0]

        return data.sort((a, b) => {
          if (hasSubExchangeType) {
            return a.subExchangeType.localeCompare(b.subExchangeType)
          } else {
            return a.coin.localeCompare(b.coin)
          }
        })
      }

      const attachNetworkStatusToAddresses = (addresses: any[], withdrawCoins: any[]) => {
        return addresses.map((address: any) => {
          const coin = withdrawCoins.find(item => item.coin === address.currency)
          const network = coin?.networks?.find((net: any) => net.network === address.network)
          return {
            ...address,
            withdrawEnable: network?.withdrawEnable ?? false,
          }
        })
      }

      const filteredWithdrawCoins = mappingWithdrawData(assetsRes.data)
      const withdrawCoinsWithBalance = addBalanceToAssets(filteredWithdrawCoins, balanceRes.data)
      const enrichedAddresses = attachNetworkStatusToAddresses(addressesRes.data, filteredWithdrawCoins)

      return {
        coins: withdrawCoinsWithBalance,
        availableLimit: Number(limitsRes.data.withdrawalLimit.available) / 100,
        addresses: enrichedAddresses,
        whitelistEnabled: whitelistRes.data.enabled,
        withdraw: withdraw,
      }
    } catch (error: unknown) {
      const apiError = error as ApiError
      console.error('Error deposit data:', apiError)

      return {
        error: {
          source: apiError.source,
          status: apiError.status,
          message: apiError.message,
        },
      }
    }
  },
}
