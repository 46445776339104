import clsx from 'clsx'
import { FC, memo, ReactNode, useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useMobileSizeDetect } from '@/utils'
import style from './style.module.scss'
import { SVG } from '@/assets/svg'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { EXCHANGES_TYPE } from '@/core/constants'

export type HeaderMenuLinkPropsSource = {
  /** text */
  text: string

  /** url */
  href: string

  /*Badge props*/
  badge?: {
    className?: string
    label: string
  }
  dataTestId?: string
  external?: boolean
  icon?: ReactNode
  isActive?: boolean
  className?: string
}

type HeaderMenuDropdownProps = {
  text?: string
  items: HeaderMenuLinkPropsSource[]
  icon?: ReactNode
  className?: string
}

export type HeaderMenuLinkProps = {
  item?: HeaderMenuLinkPropsSource
  url?: string
  dropdown?: HeaderMenuDropdownProps
}

const MenuItem: FC<HeaderMenuLinkPropsSource> = ({ text, badge, icon, isActive = false, className }) => {
  const [isMobile] = useMobileSizeDetect()

  return (
    <>
      <span
        className={clsx(className, {
          [style.text]: !isMobile,
          [style.textMobile]: isMobile,
          [style.activeText]: isActive && !isMobile,
          [style.activeTextMobile]: isActive && isMobile,
          [style.icon]: icon,
        })}
      >
        {text}
        {icon && icon}
      </span>
      {badge && <div className={clsx(style.badge, badge.className)}>{badge.label}</div>}
    </>
  )
}

const MenuDropdown: FC<HeaderMenuDropdownProps> = props => {
  const [isMobile] = useMobileSizeDetect()
  const { pathname } = useLocation()

  const { items, icon, text } = props

  const [activeIndex, isActive] = useMemo(() => {
    const indexFound = items.findIndex(item => {
      return item.href === '/' ? pathname === item.href : pathname.startsWith(item.href)
    })
    return [indexFound, indexFound > -1]
  }, [items, pathname])

  const outputText = useMemo(() => {
    return isActive ? items[activeIndex]?.text || items[0]?.text || '' : text || items[0]?.text || ''
  }, [isActive, text, items, activeIndex])

  return (
    <div
      className={clsx(style.dropdown, {
        [style.text]: !isMobile,
        [style.textMobile]: isMobile,
        [style.activeText]: isActive && !isMobile,
        [style.activeTextMobile]: isActive && isMobile,
      })}
    >
      {outputText}
      {icon && <span className={style.iconWrap}>{icon}</span>}
      <span className={style.arrow}>
        <SVG.Arrows.ArrowDown />
      </span>
      <div className={style.dropdownInnerWrap}>
        <div className={style.dropdownInner}>
          {items.map((item, index) => {
            return (
              <Link
                className={clsx(style.link, style.dropdownLinkContainer)}
                to={item.href}
                data-testid={item.dataTestId}
                key={index}
              >
                <MenuItem
                  {...item}
                  isActive={activeIndex === index}
                  className={clsx(style.dropdownItem, {
                    [style.activeLink]: activeIndex === index,
                  })}
                />
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}

/**
 * One menu item in header
 */
export const HeaderMenuLink = memo<HeaderMenuLinkProps>(props => {
  const [isActive, setIsActive] = useState(false)
  const { pathname } = useLocation()
  const { exchangeType } = useExchageType()

  useEffect(() => {
    let isActiveSource = false
    if (props.item)
      isActiveSource = props.item.href === '/' ? pathname === props.item.href : pathname.startsWith(props.item.href)
    setIsActive(isActiveSource)
  }, [pathname, props.item, props.dropdown])

  if (exchangeType === EXCHANGES_TYPE.OKX_AFFILIATE) {
    return <></>
  }

  if (props.item) {
    const { href, dataTestId, external = false } = props.item

    if (external)
      return (
        <a className={style.link} href={href} target="_blank" rel="noreferrer" data-testid={dataTestId}>
          <MenuItem {...props.item} isActive={isActive} />
        </a>
      )

    return (
      <Link className={style.link} to={href} data-testid={dataTestId}>
        <MenuItem {...props.item} isActive={isActive} />
      </Link>
    )
  } else if (props.dropdown) {
    return (
      <span className={style.link}>
        <MenuDropdown {...props.dropdown} />
      </span>
    )
  }

  return null
})
