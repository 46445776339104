import BigNumber from 'bignumber.js'
import { TransferData } from '../TransferPage'

export const roundDown = (num: number, decimals: number) => {
  const factor = Math.pow(10, decimals)
  return Math.floor(num * factor) / factor
}

export const trimDecimals = (num: number, decimals: number): number => {
  const parts = num.toString().split('.')
  if (parts.length === 1 || decimals === 0) return Math.trunc(num)

  const integerPart = parts[0]
  const decimalPart = parts[1].slice(0, decimals)
  return parseFloat(`${integerPart}.${decimalPart}`)
}

export function getTransferableAssets(fromAccount: string, toAccount: string, config: TransferData['accountConfig']) {
  const fromCoins = config[fromAccount]?.coins || []
  const toCoinsSet = new Set(config[toAccount]?.coins?.map(c => c.asset))

  return fromCoins.filter(coin => toCoinsSet.has(coin.asset))
}

export const formatAmountTrimmed = (value: number | string, decimals: number): string => {
  const bn = new BigNumber(value)

  if (!bn.isFinite() || bn.isNaN()) return '0'

  // Обрезаем до нужного количества знаков без округления
  const fixed = bn.toFixed(decimals, BigNumber.ROUND_DOWN)

  // Убираем лишние нули в конце после точки, и точку если она осталась в конце
  return fixed.replace(/\.?0+$/, '')
}
