export const formatTransactionValue = (value: string, maxDecimal: number) => {
  // 🔧 Новый блок: если значение в научной нотации — сразу форматируем и возвращаем
  if (/e/i.test(value)) {
    const num = Number(value)
    if (!isFinite(num) || isNaN(num)) return '0'

    const fixed = num.toFixed(maxDecimal)
    const [integerPart, decimalPart] = fixed.split('.')
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger
  }
  // Убираем все нечисловые символы, кроме точки
  value = value.replace(/[^\d.]/g, '')

  // Ограничиваем до одной точки
  const parts = value.split('.')
  if (parts.length > 2) {
    value = `${parts[0]}.${parts.slice(1).join('')}`
  }

  // Убираем лидирующие нули, но оставляем `0.` если нужно
  if (value.startsWith('.')) {
    value = `0${value}`
  }

  let [integer, decimal] = value.split('.')

  // Убираем лишние ведущие нули (но оставляем '0' если только один ноль до точки)
  if (integer.length > 1 && integer.startsWith('0')) {
    integer = integer.replace(/^0+/, '')
    if (integer === '') {
      integer = '0'
    }
  }

  // Удаляем все запятые (если вдруг они уже есть)
  integer = integer.replace(/,/g, '')

  // Ограничение длины целой части (максимум 12 символов)
  if (integer.length > 12) {
    integer = integer.slice(0, 12)
  }

  // Ограничение длины дробной части
  if (decimal) {
    decimal = decimal.slice(0, maxDecimal)
  }

  // Добавляем разделители тысячных (ТОЛЬКО для отображения)
  const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  // Если число состоит ТОЛЬКО из точки, заменяем на "0."
  if (value === '.') {
    return '0.'
  }

  return decimal !== undefined ? `${formattedInteger}.${decimal}` : formattedInteger
}

export const removeTransactionFormat = (value: string, maxDecimal?: number) => {
  value = value.replace(/[^\d.]/g, '')

  if (!maxDecimal) return value

  const [integer, decimal] = value.split('.')
  if (decimal) {
    return `${integer}.${decimal.slice(0, maxDecimal)}`
  }

  return value
}

export const MAX_DECIMAL_WITHDRAW = 4
