import { ExchangeTypeConfig } from '../exchage.types'
import { urls } from '@/router/urls'
import { EAccountType } from '@tigertrade/binance-ts'

export const BinanceVipConfig: ExchangeTypeConfig = {
  name: 'Binance VIP',
  type: 'BINANCE_VIP',
  instruments: [EAccountType.SPOT, EAccountType.USDT_FUTURE, EAccountType.COIN_FUTURE],
  accounts: ['BINANCE_SPOT', 'BINANCE_FUTURE', 'BINANCE_FUTURE_COIN'],
  isAvailable: false,
  hasSubExchanges: false,
  pages: {
    profile: {
      bnbBurn: true,
      convertSmallBalance: true,
      assetMode: true,
      positionMode: true,
      additionalInfo: true,
    },
    api: {
      availableKeys: 19,
    },
    chooseExchange: {
      isAvailable: false,
      percent: 35,
    },
  },
  redirect: {
    redirectURL: urls.wallet,
    blockedPath: [],
  },
  blockedNavMenu: [],
  transactions: {
    transfer: {
      isAvailable: true,
    },
    deposit: {
      isAvailable: true,
      minDeposite: null,
    },
    withdraw: {
      isAvailable: true,
      minWithdraw: 10,
    },
  },
}
