import clsx from 'clsx'
import style from './style.module.scss'
import { FC, ReactNode } from 'react'
import { SVG } from '@/assets/svg'

type AlertMessage = {
  text: string | ReactNode
  className?: string
}

export const AlertMessage: FC<AlertMessage> = ({ className, text }) => {
  return (
    <div className={clsx(style.container, className)}>
      <div className={style.icon}>
        <SVG.OtherIcons.AlertRed />
      </div>
      <div className={style.text}>{text}</div>
    </div>
  )
}
