import { FC, memo } from 'react'
import { TitleH1 } from '@/ui/atoms/TitleH1'
import AccountInfo from '../AccountInfo'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { urls } from '@/router/urls'
import { CashbackWidget } from '@/ui/organisms/CashbackWidget'
import { useAppSelector } from '@/utils'
import { SkeletonWalletLeftBar } from '@/ui/kit/Skeletons/SkeletonWallet/SkeletonWallet'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { useTeamData } from '@/utils/hooks/useTeamData'
import { E_TEAM_USER_MODE } from '@/redux/team/team.types'

type LeftBarPropsType = {}

const WalletLeftBar: FC<LeftBarPropsType> = () => {
  const { statusBalances } = useAppSelector(state => state.overview)
  const { teamUserInfo, teamManagerInfo } = useAppSelector(state => state.team)
  const { exchangeType } = useExchageType()
  const { userData } = useTeamData()

  const logoConfig = {
    isShowLogo:
      userData.userMode === E_TEAM_USER_MODE.manager
        ? teamManagerInfo?.showLogoToReferral
        : teamUserInfo?.showLogoToReferral,
    logoUrl: userData.userMode === E_TEAM_USER_MODE.manager ? teamManagerInfo?.teamLogoUrl : teamUserInfo?.teamLogoUrl,
    teamTitle: userData.userMode === E_TEAM_USER_MODE.manager ? teamManagerInfo?.teamTitle : teamUserInfo?.teamTitle,
  }

  return (
    <div className={style.leftBar}>
      {logoConfig.isShowLogo ? (
        <div className={style.logoPartner}>
          <img src={logoConfig.logoUrl} className={style.img} alt="logo" />
          <TitleH1 label={logoConfig.teamTitle} />
        </div>
      ) : (
        <TitleH1 label={t({ id: 'mainPage.title', message: 'Your Tiger.Trade Broker account' })} />
      )}
      {statusBalances !== 'succeeded' && <SkeletonWalletLeftBar />}
      {statusBalances === 'succeeded' && (
        <>
          <AccountInfo />
          <CashbackWidget
            customOptions={
              exchangeType !== 'BINANCE_VIP'
                ? {
                    button: {
                      text: t({ id: 'tigerToken.cashbackDetails', message: 'Cashback details' }),
                      link: urls.cashback,
                    },
                  }
                : undefined
            }
          />
        </>
      )}
    </div>
  )
}

export default memo(WalletLeftBar)
