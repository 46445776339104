import nameof from 'ts-nameof.macro'
import { enqueueSnackbar } from 'notistack'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkError } from '@/types'
import { axiosMainService } from '@/services/axios'
import { ThunkCreator } from '@/utils/lib/ThunkCreator'
import { LeverageApi } from '@/backend/api'
import { leverageActions } from '@/redux/leverage/leverage.slice'
import {
  PositionRiskUsdMRequestDTO,
  PositionRiskCoinMRequestDTO,
  PositionRiskUsdMResponseDTO,
  PositionRiskCoinMResponseDTO,
  LeverageBracketRequestDTO,
  LeverageBracketResponseDTO,
  ExchangeInfoResponseDTO,
  TSetMarginResponse,
  TSetMarginPayloadUnion,
} from '@/backend/models/LeverageDTO'
import {
  GroupUpdatePayload,
  InitialState,
  TLeverageTicker,
  TMarketTickers,
  TSetLeveragePayloadUnion,
  TSetLeverageResponse,
  TTigerXTickerLeverage,
  TTigerXTickerPositionTier,
  TTigerXTickers,
} from '@/redux/leverage/leverage.types'
import {
  IOkxGetLeverageEstimatedInfoParams,
  IOkxGetLeverageParams,
  TGetOkxMarkPricePayload,
  TGetOkxMarkPriceResponse,
  TOkxLeverageEstimatedInfo,
  okxRestService,
} from '@/services/okx'
import { AccountConfiguration, AccountLeverage } from 'okx-api'
import { InternalApi } from '@/services/rest/tigerGateway'
import { UPDATE_AUTH_TOKEN_ACTION, UPDATE_TICKERS_ACTION } from '@/core/constants/leverage'
import { APIResponseV3WithTime, AccountInfoV5, CategoryCursorListV5, PositionV5, RiskLimitV5 } from 'bybit-api'
import { EBybitInstruments, IBybitParamsCategory, bybitRestService } from '@/services/bybit'
import { t } from '@lingui/macro'
import { tigerxRestService, TigerXTickersListParams } from '@/services/tigerx'

export const GetOkxLeverageForInstrumentTC = createAsyncThunk<AccountLeverage[][], IOkxGetLeverageParams[], ThunkError>(
  'GetOkxLeverage',
  async (payload, thunkAPI) => {
    return await ThunkCreator(
      {
        apiMethod: payload.map(() => {
          return async params => {
            return await okxRestService.getLeverage(params)
          }
        }),
        payload,
      },
      thunkAPI
    )
  }
)

export const GetOkxLeverageEstimatedInfoTC = createAsyncThunk<
  TOkxLeverageEstimatedInfo[][],
  IOkxGetLeverageEstimatedInfoParams[],
  ThunkError
>('GetOkxLeverageEstimatedInfo', async (payload, thunkAPI) => {
  return await ThunkCreator(
    {
      apiMethod: payload.map(() => {
        return async params => {
          return await okxRestService.getLeverageEstimatedInfo(params)
        }
      }),
      payload,
    },
    thunkAPI
  )
})

export const GetOkxAccountConfigurationTC = createAsyncThunk<AccountConfiguration[], undefined, ThunkError>(
  nameof(okxRestService.getAccountConfiguration),
  async (payload, thunkAPI) => {
    return await ThunkCreator(
      {
        apiMethod: async () => {
          return await okxRestService.getAccountConfiguration()
        },
        payload,
      },
      thunkAPI
    )
  }
)

export const SetLeverageTC = createAsyncThunk<TSetLeverageResponse, TSetLeveragePayloadUnion, ThunkError>(
  'SetLeverage',
  async (payload, thunkAPI) => {
    return await ThunkCreator(
      {
        apiMethod: async params => {
          return await InternalApi.methods.leverage.setLeverage(params)
        },
        payload,
      },
      thunkAPI
    )
  }
)

export const SetMarginTypeTC = createAsyncThunk<TSetMarginResponse, TSetMarginPayloadUnion, ThunkError>(
  'SetMarginType',
  async (payload, thunkAPI) => {
    return await ThunkCreator(
      {
        apiMethod: async params => {
          return await InternalApi.methods.leverage.setMargin(params)
        },
        payload,
      },
      thunkAPI
    )
  }
)

export const GetOkxMarkPrice = createAsyncThunk<TGetOkxMarkPriceResponse, TGetOkxMarkPricePayload, ThunkError>(
  'GetOkxMarkPrice',
  async (payload, thunkAPI) => {
    return await ThunkCreator(
      {
        apiMethod: okxRestService.getOkxMarkPrice,
        payload,
      },
      thunkAPI
    )
  }
)

export const GetBybitMarketTickers = createAsyncThunk<TMarketTickers, IBybitParamsCategory, ThunkError>(
  'GetBybitMarketTickers',
  async (payload, thunkAPI) => {
    return await ThunkCreator(
      {
        apiMethod: bybitRestService.getMarketTickers,
        payload,
      },
      thunkAPI
    )
  }
)

export const GetBybitAccountInfoTC = createAsyncThunk<APIResponseV3WithTime<AccountInfoV5>, void, ThunkError>(
  'GetBybitAccountInfoTC',
  async (payload, thunkAPI) => {
    return await ThunkCreator(
      {
        apiMethod: bybitRestService.getAccountInfo,
        payload,
      },
      thunkAPI
    )
  }
)

export const GetUsdMLeveragesTC = createAsyncThunk<
  [PositionRiskUsdMResponseDTO, LeverageBracketResponseDTO, ExchangeInfoResponseDTO],
  [PositionRiskUsdMRequestDTO?, LeverageBracketRequestDTO?, LeverageBracketRequestDTO?],
  ThunkError
>('GetUsdMLeveragesTC', async (payload = [], thunkAPI) => {
  return await ThunkCreator(
    {
      apiMethod: [LeverageApi.getUsdMPositionRisk, LeverageApi.getUsdMLeverageBracket, LeverageApi.getUsdMExchangeInfo],
      payload,
    },
    thunkAPI
  )
})

export const getBybitLeverageInfo = createAsyncThunk<
  [APIResponseV3WithTime<CategoryCursorListV5<PositionV5[]>>, RiskLimitV5[]],
  IBybitParamsCategory,
  ThunkError
>('getBybitLeverageInfo', async (payload, thunkAPI) => {
  return await ThunkCreator(
    {
      apiMethod: [bybitRestService.getPositions, bybitRestService.getRiskLimitBySymbol],
      payload: [payload, payload],
    },
    thunkAPI
  )
})

export const GetCoinMLeveragesTC = createAsyncThunk<
  [PositionRiskCoinMResponseDTO, LeverageBracketResponseDTO, ExchangeInfoResponseDTO],
  [PositionRiskCoinMRequestDTO?, LeverageBracketRequestDTO?, LeverageBracketRequestDTO?],
  ThunkError
>('GetCoinMLeveragesTC', async (payload = [], thunkAPI) => {
  return await ThunkCreator(
    {
      apiMethod: [
        LeverageApi.getCoinMPositionRisk,
        LeverageApi.getCoinMLeverageBracket,
        LeverageApi.getCoinMExchangeInfo,
      ],
      payload,
    },
    thunkAPI
  )
})

export const UpdateTickersTC = createAsyncThunk<void, GroupUpdatePayload, ThunkError>(
  'leverage/updateTickers',
  async (payload, { dispatch, getState }) => {
    const { leverage: state } = getState() as { leverage: InitialState }

    if (payload.isSelectMaxPosition) {
      dispatch(leverageActions.setPositionBusy(true))
    } else if (payload.isSelectMaxLeverage) {
      dispatch(leverageActions.setLeverageBusy(true))
    } else {
      dispatch(leverageActions.setBusy(true))
    }
    const tickers = Object.entries(state.tickers[state.leverageType]).reduce(
      (acc: TLeverageTicker[], [symbol, value]) => {
        if (state.groupEditing.active.includes(symbol)) {
          acc.push(value)
        }
        return acc
      },
      []
    )

    try {
      const worker = new Worker(new URL('./leverage.workers/binance.ts', import.meta.url))
      const token = axiosMainService.defaults.headers.Authorization
      const exchangeType = axiosMainService.defaults.headers['X-Exchange-Type']
      let errors: string[] = []
      let leverageChanged = 0
      let marginChanged = 0
      let leverage = payload.isSelectMaxLeverage
      let position = payload.isSelectMaxPosition
      worker.postMessage({
        action: UPDATE_AUTH_TOKEN_ACTION,
        payload: { token, exchangeType },
      })
      worker.postMessage({
        action: UPDATE_TICKERS_ACTION,
        payload: {
          data: payload,
          tickers,
          leverageType: state.leverageType,
          brackets: payload.brackets,
          balance: payload.balance,
        },
      })

      worker.onmessage = e => {
        const { action, status, payload } = e.data

        if (action !== UPDATE_TICKERS_ACTION) return

        if (status === 'success') {
          if (payload.leverage) {
            leverageChanged++
          } else {
            marginChanged++
          }
        }

        if (status === 'error') {
          errors.push(payload.symbol)
        }

        if (status === 'finish') {
          if (leverage) {
            dispatch(leverageActions.setLeverageBusy(false))
          } else if (position) {
            dispatch(leverageActions.setPositionBusy(false))
          } else {
            dispatch(leverageActions.setBusy(false))
          }

          dispatch(leverageActions.clearTickers())

          if (!leverageChanged && !marginChanged && !errors.length) {
            enqueueSnackbar(
              t({
                id: 'leverage.binance.nothingChanged',
                comment: 'Everything up-to-date',
              }), //`Все позиции в актуальном состоянии`
              { variant: 'success' }
            )
            return
          }

          if (leverageChanged || marginChanged) {
            state.leverageType === 'usdM'
              ? dispatch(leverageActions.GetUsdMLeveragesTC([]))
              : dispatch(leverageActions.GetCoinMLeveragesTC([]))
          }

          if (leverageChanged) {
            enqueueSnackbar(
              t({
                id: 'leverage.binance.leverageChanged',
                comment: 'Leverages was changed with {leverageChanged} positions',
                values: { leverageChanged },
              }), //`Кредитные плечи изменены у ${leverageChanged} позиций`: `Leverages was changed with ${leverageChanged} positions`,
              { variant: 'success' }
            )
          }

          if (marginChanged) {
            enqueueSnackbar(
              t({
                id: 'leverage.binance.marginChanged',
                comment: 'Margin types was changed with {marginChanged} positions',
                values: { marginChanged },
              }), // `Тип маржи изменен у ${marginChanged} позиций`                : `Margin types was changed with ${marginChanged} positions`,
              { variant: 'success' }
            )
          }

          if (errors.length) {
            dispatch(leverageActions.toggleTickers(errors))

            enqueueSnackbar(
              t({
                id: 'leverage.binance.errorsCount',
                comment: 'An error occured trying to change {errorsCount} positions',
                values: { errorsCount: errors.length },
              }), //  ? `Что-то пошло не так у ${errors.length} позиций`                : `Something went wrong with ${errors.length} positions`,
              { variant: 'error' }
            )
          }
        }
      }
    } catch (e) {
      // console.log(e)
    }
  }
)

export const GetTigerXMarketTickers = createAsyncThunk<TTigerXTickers, void, ThunkError>(
  'GetTigerXMarketTickers',
  async (_, thunkAPI) => {
    return await ThunkCreator(
      {
        apiMethod: tigerxRestService.getLeverageTickerList,
      },
      thunkAPI
    )
  }
)

export const GetTigerXMPositionsTickers = createAsyncThunk<any, void, ThunkError>(
  'GetTigerXMPositionsTickers',
  async (_, thunkAPI) => {
    return await ThunkCreator(
      {
        apiMethod: tigerxRestService.getLeveragePositionList,
      },
      thunkAPI
    )
  }
)

export const GetTigerXTickerLeverage = createAsyncThunk<TTigerXTickerLeverage, TigerXTickersListParams, ThunkError>(
  'GetTigerXTickerLeverage',
  async (payload, thunkAPI) => {
    return await ThunkCreator(
      {
        apiMethod: tigerxRestService.getLeverage,
        payload,
      },
      thunkAPI
    )
  }
)

export const GetTigerXTickerPositionTier = createAsyncThunk<
  TTigerXTickerPositionTier,
  TigerXTickersListParams,
  ThunkError
>('GetTigerXTickerPositionTier', async (payload, thunkAPI) => {
  return await ThunkCreator(
    {
      apiMethod: tigerxRestService.getPositionTier,
      payload,
    },
    thunkAPI
  )
})
