import { Fragment, memo, ReactNode, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TransactionsAPI, WithdrawPayload } from '@/backend/api'
import { getCompoundTransactionAmount, useAppSelector } from '@/utils'
import { formatDate } from '@/utils/lib/formatDate'
import { ButtonVariant, Checkbox, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import { GoogleAuth } from '../GoogleAuth'
import { GoogleCodeVerification } from '../GoogleCodeVerification'
import { t } from '@lingui/macro'
import { useActions, useGoogleAuthCode, useGoogleAuthSettings, useMobileSizeDetect } from '@/utils/hooks'
import { FieldErrorType } from '@/types'
import { getCryproErrors } from '@/backend/services/crypto'
import { v1 } from 'uuid'
import { urls } from '@/router/urls'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { Status } from '@/backend/types'
import { PinInput } from '../PinInput'
import { AlertMessage } from '../AlertMessage'
import styles from './style.module.scss'
import { tigerBackRest } from '@/exchanges/tigerBack/tigerBack.rest'
import { profileActions } from '@/redux/profile/profile.slice'

/** Heart of user screen switching logic! Number is used in the code, value is just the explanation */
const steps = {
  1: t({
    message: `initial state: user should confirm transaction with google auth`,
    id: 'withdrawConfirmation.step-1',
  }),
  2: t({ message: "user doesn't have google authenticator and should install it", id: 'withdrawConfirmation.step-2' }),
  3: t({
    message: 'user have google authenticator and should go to the enter code screen',
    id: 'withdrawConfirmation.step-3',
  }),
  4: t({ message: 'user is entering the code', id: 'withdrawConfirmation.step-4' }),
  5: t({ message: 'user sent transaction', id: 'withdrawConfirmation.step-5' }),
} as const

export type WithdrawData = {
  pinCodeCurrentAttempt: number
  pinCodeMaxAttempts: number
  canWithdraw: boolean
}

type WithdrawConfirmationProps = {
  /** withdrawal amount */
  amount: string

  /** symbol of the transferred token */
  tokenSymbol: string

  /** wallet address to receive coins */
  walletAddress: string

  /** network where withdrawal is processing */
  network: string

  /** walletId is address id */
  walletId?: string

  /** callback to fire for closing this component */
  closeModal: () => void

  // subExchanges
  subExchange?: string
  hasSubExchanges: boolean
}

/** Google Authenticator usage */
export const WithdrawConfirmation = memo<WithdrawConfirmationProps>(
  ({ amount, network, tokenSymbol, walletAddress, closeModal, walletId, subExchange, hasSubExchanges }) => {
    const [isMobile] = useMobileSizeDetect()
    const navigate = useNavigate()
    const { pin } = useAppSelector(state => state.profile)
    const { GetProfileTC } = useActions(profileActions)
    const [error, setError] = useState<string | null>(null)

    const [withdrawData, setWithdrawData] = useState<WithdrawData | null>(null)
    const [withdrawDataError, setWithdrawDataError] = useState(false)
    const [isCheked, setIsChecked] = useState(false)

    const [step, setStep] = useState<keyof typeof steps>(2)
    const [errorPinCode, setErrorPinCode] = useState<string | null>(null)
    const [pinCode, setPinCode] = useState('')
    const { fetchGoogleAuthSettings, isAccepted: isUserInstalledAuthenticator, settings } = useGoogleAuthSettings()
    const {
      isCorrectCodeLength,
      verify,
      cleanCode,
      isError: googleVerifyError,
      isSuccess,
      code,
      isSending,
      setCode,
    } = useGoogleAuthCode()
    const stringifiedCode = useMemo(() => {
      return code.join('')
    }, [code])

    const [errorResponseCode, setErrorResponseCode] = useState<string>()

    const errorMessage = useMemo(() => {
      if (googleVerifyError) {
        return t({ message: 'Wrong code', id: 'withdrawConfirmation.wrongCode' })
      }

      if (errorResponseCode === '40019') {
        const message = getCryproErrors()[errorResponseCode]
        setErrorPinCode(message)
        setError(null)
      }

      if (errorResponseCode) {
        const message = getCryproErrors()[errorResponseCode]
        return message || t({ message: 'Unknown error', id: 'withdrawConfirmation.unknownError' }) // fallback
      }

      return undefined
    }, [googleVerifyError, errorResponseCode])

    useEffect(() => {
      GetProfileTC()
      fetchWithdrawFallback()
    }, [])

    useEffect(() => {
      if (errorResponseCode === '40009' && !isCorrectCodeLength) return setErrorResponseCode(undefined)
    }, [isCorrectCodeLength, errorResponseCode])

    const successMessage = isSuccess ? t({ message: `Correct code`, id: 'withdrawConfirmation.correctCode' }) : void 0
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
    const [uid] = useState(v1())

    useEffect(() => {
      if (!isCorrectCodeLength || step !== 2) {
        return
      }

      verify()
    }, [isCorrectCodeLength, step])

    useEffect(() => {
      if (!isSuccess) return
      if (step === 2) {
        fetchGoogleAuthSettings()
        setStep(3)
        cleanCode()
      }
    }, [isSuccess, step])

    useEffect(() => {
      if (isUserInstalledAuthenticator) {
        setStep(3)
      }
    }, [isUserInstalledAuthenticator])

    const fetchWithdrawFallback = async () => {
      try {
        setWithdrawDataError(false)
        const withdrawResponse = await tigerBackRest.getWithdraw()

        const data: WithdrawData = withdrawResponse?.data

        if (!data) {
          setWithdrawDataError(true)
          console.warn('Not have data in getWithdraw')
          return
        }

        setWithdrawData(data)
      } catch (err) {
        setWithdrawDataError(true)
        console.error('Error fallback getWithdraw:', err)
      }
    }

    const sendWithdrawal = () => {
      setErrorPinCode(null)
      setError(null)

      if (googleVerifyError) {
        return
      }

      setIsSubmitDisabled(true)

      const withdrawAssetPayload: WithdrawPayload = {
        address: walletAddress,
        coin: tokenSymbol,
        amount: Number(amount),
        googleCode: stringifiedCode,
        network,
        type: 'CRYPTO',
        transactionId: uid,
        pinCode: pinCode ? pinCode : undefined,
        subExchange: hasSubExchanges ? subExchange : undefined,
      }

      TransactionsAPI.withdrawAsset(
        walletId
          ? {
              ...withdrawAssetPayload,
              walletId,
            }
          : withdrawAssetPayload
      )
        .then(() => setStep(5))
        .catch(res => {
          fetchWithdrawFallback()
          setIsSubmitDisabled(false)
          if ('details' in res?.response?.data) {
            ;(res.response.data.details as FieldErrorType[]).forEach(error => {
              if (error.code === '40019') {
                setErrorPinCode(error.message)
                return
              }
              if (error.code) {
                setErrorResponseCode(error.code + '')
                setError(error.message)
              }
            })
          }
        })
    }

    function handleCheck() {
      setIsChecked(!isCheked)
    }

    const isValidPinCode = pin.enabled ? pinCode?.length === 6 : true

    const isValidGoogleCode = useMemo(() => {
      if (!isMobile) return undefined
      if (errorMessage) return false
      if (successMessage) return true
      return undefined
    }, [successMessage, errorMessage, isMobile])

    if (withdrawDataError) {
      return (
        <InteractiveModal isOpen>
          <InteractiveModalParts.Icon icon="withdrawal" />
          <InteractiveModalParts.Header text={t({ id: 'withdraw.error.header', message: 'Withdrawal error' })} />
          <InteractiveModalParts.Explanation
            text={t({
              id: 'withdraw.error.message',
              message: 'Failed to load necessary data. Please try again.',
            })}
          />
          <InteractiveModalParts.Button
            text={t({ message: 'Try again', id: 'core.retry' })}
            variant={ButtonVariant.Accent}
            onClick={fetchWithdrawFallback}
          />
          <InteractiveModalParts.Button
            text={t({ message: 'Cancel', id: 'core.cancel' })}
            variant={ButtonVariant.White}
            onClick={closeModal}
          />
        </InteractiveModal>
      )
    }

    return (
      <>
        {step === 1 && (
          <CommonModal
            amount={amount}
            tokenSymbol={tokenSymbol}
            walletAddress={walletAddress}
            network={network}
            closeModal={closeModal}
          >
            <InteractiveModalParts.SubHeader
              text={t({ message: 'Confirm transaction ', id: 'withdrawConfirmation.confirmTransaction' })}
            />
            <InteractiveModalParts.SubExplanation
              text={t({
                message:
                  'Before you can complete the transaction, you’ll need to confirm your account with Google Authenticator app',
                id: 'withdrawConfirmation.beforeComplete',
              })}
            />
            <InteractiveModalParts.Button
              text={t({ message: 'Confirm with Google Authenticator', id: 'withdrawConfirmation.confirmGoogleAuth' })}
              variant={ButtonVariant.Accent}
              onClick={() => setStep(2)}
            />
            <InteractiveModalParts.Button
              text={t({ message: 'Cancel', id: 'core.cancel' })}
              variant={isMobile ? ButtonVariant.Clean : ButtonVariant.White}
              onClick={closeModal}
            />
          </CommonModal>
        )}

        {step === 2 && (
          <>
            <GoogleAuth
              isOpen={true}
              header={
                <Fragment>
                  {t({ message: 'Confirm your account', id: 'withdrawConfirmation.confirm-1' })} <wbr />
                  {t({ message: 'to make the transaction', id: 'withdrawConfirmation.confirm-2' })}
                </Fragment>
              }
              secret={settings.secret || ''}
              code={code}
              setCode={setCode}
              isDisabled={isSending || false}
              errorMessage={error ?? ''}
              successMessage={successMessage}
              onCloseClick={closeModal}
              isValid={isValidGoogleCode}
            />
            <InteractiveModalParts.Button
              text={t({ message: 'Cancel', id: 'core.cancel' })}
              variant={isMobile ? ButtonVariant.Clean : ButtonVariant.White}
              onClick={closeModal}
            />
          </>
        )}
        {step === 3 && (
          <CommonModal
            amount={amount}
            tokenSymbol={tokenSymbol}
            walletAddress={walletAddress}
            network={network}
            closeModal={closeModal}
          >
            <AlertMessage
              text={t({
                id: 'withdraw.alert.hackingWarning',
              })}
            />
            <br />
            <InteractiveModalParts.SubHeader
              text={t({ message: 'Confirm transaction ', id: 'withdrawConfirmation.confirmTransaction' })}
            />
            <InteractiveModalParts.SubExplanation
              text={t({
                message:
                  'Before you can complete the transaction, you’ll need to confirm your account with Google Authenticator app',
                id: 'withdrawConfirmation.beforeComplete',
              })}
            />
            <br />
            <Checkbox variant="square" checked={isCheked} className={styles.checkboxItem} onChange={handleCheck}>
              {t({
                id: 'profile.pinCode.setup.checkbox',
              })}
            </Checkbox>
            <InteractiveModalParts.Button
              text={t({ comment: 'Confirm', id: 'withdrawConfirmation.confirmTransaction' })}
              variant={ButtonVariant.Accent}
              onClick={() => setStep(4)}
              isDisabled={!isCheked}
              dataTestId={DataTestIds.ModalConfirmButton}
            />
            <InteractiveModalParts.Button
              text={t({ message: 'Cancel', id: 'core.cancel' })}
              variant={isMobile ? ButtonVariant.Clean : ButtonVariant.White}
              onClick={closeModal}
              dataTestId={DataTestIds.ModalCancelButton}
            />
          </CommonModal>
        )}

        {step === 4 && (
          <InteractiveModal isOpen={true}>
            <InteractiveModalParts.Icon icon="withdrawal" />
            <InteractiveModalParts.Header text={t({ message: 'Withdrawal', id: 'core.withdrawal' })} />
            <GoogleCodeVerification
              header={t({
                message: 'Enter Google Authenticator code',
                id: 'withdrawConfirmation.confirmGoogleAuth.enterCode',
              })}
              text={`${t({
                message: 'If you’re having issues, please contact support:',
                id: 'withdrawConfirmation.confirmGoogleAuth.contactSupport',
              })} ${t({
                id: 'core.supportEmail',
                message: 'broker.support@tiger.trade',
              })}`}
              code={code}
              setCode={setCode}
              isDisabled={isSending || false}
              errorMessage={error ?? ''}
              successMessage={successMessage}
              isValid={isValidGoogleCode}
            />
            <br />
            {pin.enabled && (
              <PinInput
                title={t({ id: 'profile.pinCode.enterPin.title' })}
                value={pinCode}
                onChange={setPinCode}
                errorMessage={errorPinCode}
                attemptsCounter={withdrawData?.pinCodeCurrentAttempt ?? 0}
                maxAttempts={withdrawData?.pinCodeMaxAttempts ?? 4}
                disabled={!withdrawData?.canWithdraw}
              />
            )}
            <InteractiveModalParts.Button
              text={`${t({ message: 'Send', id: 'core.send' })} ${getCompoundTransactionAmount(amount, tokenSymbol)}`}
              variant={ButtonVariant.Accent}
              onClick={sendWithdrawal}
              isDisabled={isSubmitDisabled || !withdrawData?.canWithdraw || !isCorrectCodeLength || !isValidPinCode}
              dataTestId={DataTestIds.ModalConfirmButton}
            />
            <InteractiveModalParts.Button
              text={t({ message: 'Cancel', id: 'core.cancel' })}
              variant={isMobile ? ButtonVariant.Clean : ButtonVariant.White}
              onClick={closeModal}
              dataTestId={DataTestIds.ModalCancelButton}
            />
          </InteractiveModal>
        )}

        {step === 5 && (
          <CommonModal
            amount={amount}
            tokenSymbol={tokenSymbol}
            walletAddress={walletAddress}
            network={network}
            status={'loading'}
            closeModal={closeModal}
            {...(isMobile && { date: formatDate(new Date()), status: 'loading' })}
          >
            <InteractiveModalParts.Button
              text={t({ message: 'Go back to main page', id: 'core.gobackMainPage' })}
              variant={ButtonVariant.White}
              onClick={() => navigate(urls.root)}
              dataTestId={DataTestIds.ModalReturnButton}
            />
          </CommonModal>
        )}
      </>
    )
  }
)

type CommonModalProps = {
  amount: string
  tokenSymbol: string
  walletAddress: string
  network: string
  closeModal: () => void
  children: ReactNode
  date?: string
  status?: Status
}

const CommonModal = memo<CommonModalProps>(
  ({ children, amount, tokenSymbol, walletAddress, network, closeModal, date, status }) => (
    <InteractiveModal isOpen={true}>
      <InteractiveModalParts.Icon icon="withdrawal" />
      <InteractiveModalParts.Header text={t({ message: 'Withdrawal', id: 'core.withdrawal' })} />
      <InteractiveModalParts.MainInfo text={getCompoundTransactionAmount(amount, tokenSymbol)} />

      {status && <InteractiveModalParts.Status status="loading" date={date ?? formatDate(new Date())} />}

      <InteractiveModalParts.Table
        left={t({ id: 'core.transaction.from', message: 'From' })}
        right="Spot"
        dataTestId={DataTestIds.HistoryModalFrom}
      />
      <InteractiveModalParts.TableCopy
        left={t({ id: 'core.transaction.to', message: 'To' })}
        right={walletAddress}
        isShortenView
        dataTestId={DataTestIds.HistoryModalAddrTo}
      />
      <InteractiveModalParts.Table
        left={t({ id: 'core.transaction.network', message: 'Network' })}
        right={network}
        dataTestId={DataTestIds.HistoryModalNetwork}
      />
      {children}
    </InteractiveModal>
  )
)
