import React, { FC, useCallback, useEffect } from 'react'
import { useActions, useAppSelector, useToggle } from '@/utils'
import { referralsActions } from '@/redux/referrals/referrals.slice'
import { Table } from '@/ui/molecules/Table'
import styles from './style.module.scss'
import { TitleH1 } from '@/ui/atoms/TitleH1'
import { t } from '@lingui/macro'
import { Loader } from '@/ui/kit/Loader'
import { EmptyList } from '@/ui/molecules'
import { Button, ButtonVariant, DatePicker, InteractiveModal } from '@/ui/kit'
import { useReferralEarnings } from './ReferralEarnings.hooks'
import { SVG } from '@/assets/svg'
import { DATE_FORMAT, formatDate } from '@/utils/lib/formatDate'
import { EARNING_PAGE_FROM_DEFAULT, EARNING_PAGE_TO_DEFAULT } from '@/core/constants/referrals'
import { InfiniteScrollCustom } from '@/ui/atoms/InfiniteScrollCustom'
import { Select } from '@/ui/kit'
import { ReferralsExchangeSectionPair } from '@/backend/models/ReferralsDTO'
import { OptionsEnum } from './ReferralEarnings.hooks/useReferralEarnings'
import { ExportMenu } from '@/ui/organisms'
import clsx from 'clsx'

export const ReferralEarnings: FC = () => {
  const { resetEarnings } = useActions(referralsActions)
  const {
    getReferralEarnings,
    earningsStatus,
    referralEarnings,
    earningsPage,
    earningReachedEnd,
    tableAccessors,
    earningUser,
    earningNoSearchResult,
    endDate,
    startDate,
    changeDates,
    sumColumnFormatter,
    searchValueError,
    searchValueInput,
    changeExchangeSection,
    getExchangeSections,
    selectedExchange,
    setSortParams,
    sort,
    options,
    optionsHandler,
    selectedOption,
    setSelectedOption,
    exportCsvHandler,
    referralsEarningExportStatus,
    referralsEarningExport,
  } = useReferralEarnings()

  const [showCalendarModal, toggleModal] = useToggle(false)
  const { language } = useAppSelector(state => state.profile)

  const handleChangeDateRange = (start: string, end: string) => {
    changeDates(start, end)
    getReferralEarnings(0, {
      from: start,
      to: end,
      userId: searchValueInput === '' ? undefined : searchValueInput,
    })
    toggleModal()
  }

  const handleResetDates = useCallback(() => {
    changeDates(
      formatDate(EARNING_PAGE_FROM_DEFAULT, DATE_FORMAT.FORMATTED),
      formatDate(EARNING_PAGE_TO_DEFAULT, DATE_FORMAT.FORMATTED)
    )
    setSortParams({ sortBy: 'earnings', sortDesc: true })

    setSelectedOption(undefined)
    changeExchangeSection('')
  }, [changeDates, getReferralEarnings])

  // TEMPRORARY OFF
  // const scrollCallback = useCallback<IntersectionObserverCallback>(
  //   ([entry]) => {
  //     console.log('--entry')
  //     console.log('data', earningsPage, earningReachedEnd, earningUser, startDate, endDate)
  //     if (earningReachedEnd) return
  //     if (earningsStatus !== 'loading' && earningsStatus !== 'failed' && entry.isIntersecting) {
  //       getReferralEarnings(earningsPage + 1, { userId: earningUser, from: startDate, to: endDate })
  //     }
  //   },
  //   [earningsPage, earningReachedEnd, earningUser, startDate, endDate]
  // )

  function scrollCallback() {
    if (earningReachedEnd) return
    if (earningsStatus !== 'loading' && earningsStatus !== 'failed') {
      getReferralEarnings(earningsPage + 1, {
        userId: earningUser,
        from: startDate,
        to: endDate,
        exchange: selectedExchange as ReferralsExchangeSectionPair,
      })
    }
  }

  useEffect(() => {
    if (referralEarnings.length === 0 && earningsStatus === 'idle') {
      // initial fetch if list is empty
      getReferralEarnings(earningsPage, {
        from: startDate,
        to: endDate,
        sortParamsValues: {
          sortBy: sort.sortBy,
          sortDesc: sort.sortDesc,
        },
      })
    }
  }, [referralEarnings, earningsStatus, earningsPage])

  useEffect(() => {
    // reset status on unload
    return () => {
      resetEarnings()
    }
  }, [])

  useEffect(() => {
    if (referralsEarningExportStatus === 'succeeded') {
      console.log(referralsEarningExportStatus, 'referralsEarningExportStatus')
      handleDownload(referralsEarningExport?.url ?? '')
    }
  }, [referralsEarningExportStatus])

  const handleDownload = (fileUrl: string) => {
    console.log(fileUrl, 'fileUrl')
    const link = document.createElement('a')
    link.href = fileUrl
    link.setAttribute('download', 'report.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <TitleH1
          label={t({
            id: 'referralEarningsPage.title',
            message: 'Referral earnings',
          })}
          className={styles.title}
        />

        <div className={styles.dateRange}>
          <div>
            <span onClick={handleResetDates} className={styles.resetButton}>
              {t({ message: 'Reset', id: 'historyTrade.reset' })}
            </span>
          </div>
          <Select
            onChange={changeExchangeSection}
            value={selectedExchange}
            placeholder={t({
              id: 'referralEarningsPage.marketType',
              message: 'Market Type',
            })}
            options={getExchangeSections() ?? []}
            variant={Select.Variant.Raw}
            className={styles.select}
          />

          <Select
            onChange={optionsHandler}
            value={selectedOption as OptionsEnum}
            options={options}
            variant={Select.Variant.Raw}
            className={styles.select}
          />

          <div className={styles.datesContainer}>
            <span>{formatDate(startDate, DATE_FORMAT.DAY_AND_YEAR_SEMI)}</span>
            <span>-</span>
            <span>{formatDate(endDate, DATE_FORMAT.DAY_AND_YEAR_SEMI)}</span>
            <Button
              variant={ButtonVariant.AccentDark}
              className={styles.calendarButton}
              onClick={() => toggleModal()}
              leftIcon={<SVG.Arrows.ArrowDown />}
            />
          </div>
          <ExportMenu onClickHandler={exportCsvHandler} className={styles.export} />
        </div>
      </div>

      {(earningsStatus === 'idle' || (earningsStatus === 'loading' && referralEarnings.length === 0)) && (
        <Loader className={styles.loader} />
      )}

      {earningsStatus === 'succeeded' && referralEarnings.length === 0 && (
        <>
          {earningNoSearchResult && (
            <EmptyList
              label={t({
                id: 'referralEarningsPage.noSearchResult',
                message: 'There are no referral users with the UID you provided',
              })}
            />
          )}
          {!earningNoSearchResult && (
            <EmptyList
              label={t({
                id: 'referralEarningsPage.empty',
                message: 'Your referral earnings will display here',
              })}
            />
          )}
        </>
      )}

      {earningsStatus !== 'idle' && referralEarnings.length > 0 && (
        <InfiniteScrollCustom className={styles.tableContainer} scrollCallback={scrollCallback}>
          <Table
            data={referralEarnings}
            accessors={tableAccessors}
            sumColumns={sumColumnFormatter}
            showFooter
            theadClassName={clsx(styles.tableHead, {
              [styles.tableHeadBig]: language === 'ru',
              [styles.tableHeadEn]: language === 'en',
            })}
            tfootClassName={styles.tfoot}
          />
          {earningsStatus === 'loading' && <Loader className={styles.loader} />}
        </InfiniteScrollCustom>
      )}

      <InteractiveModal isOpen={showCalendarModal} onClose={toggleModal} className={styles.modal}>
        <DatePicker
          startDate={new Date(startDate)}
          endDate={new Date(endDate)}
          onClose={toggleModal}
          onApply={handleChangeDateRange}
        />
      </InteractiveModal>
    </div>
  )
}
