import { useAuth0 } from '@auth0/auth0-react'
import { FC, useEffect, useRef } from 'react'
import { useWalletPageService } from './services/useWalletPageService'
import { urls } from '@/router/urls'
import { useLocation } from 'react-router-dom'
import { useOrdersPageService } from './services/useOrdersPageService'
import { useActions, useAppSelector } from '@/utils'
import { ETigerXInstruments } from '@/exchanges/tigerx/tigerx.config'
import { tigerXActions } from '@/redux/tigerX/tigerX.slice'
import { overviewActions } from '@/redux/overview/overview.slice'
import { setAuthToken } from '@/exchanges/exchangeAuthToken'

type IBinanceProviderProps = {
  children: React.ReactNode
}

export const TigerXProvider: FC<IBinanceProviderProps> = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0()
  const location = useLocation()
  const currentPathRef = useRef(location.pathname)
  const { symInfoStatus, isTokenLoaded } = useAppSelector(state => state.tigerX)
  const { setIsTokenLoaded } = useActions(tigerXActions)
  // Загрузка токена из Auth0
  useEffect(() => {
    const loadToken = async () => {
      try {
        const token = await getAccessTokenSilently()
        setAuthToken(token) // Устанавливаем токен для Axios клиента
        setIsTokenLoaded(true) // Устанавливаем статус токена как загруженный
      } catch (error) {
        console.error('Auth0 error TigerX:', error)
      }
    }

    loadToken()
  }, [getAccessTokenSilently])

  const walletPollingUrls = [urls.wallet, urls.assets, urls.orders]
  const ordersPoolingUrls = [urls.wallet, urls.orders]
  const isWalletPollingUrl = walletPollingUrls.includes(location.pathname)
  const isOrdersPollingUrl = ordersPoolingUrls.includes(location.pathname)

  const walletPageService = useWalletPageService()
  const { instrumentType } = useAppSelector(state => state.overview)
  const ordersPageService = useOrdersPageService(instrumentType as ETigerXInstruments)
  const { setInstrumentType } = useActions(overviewActions)
  const { GetSym } = useActions(tigerXActions)

  useEffect(() => {
    if (!isTokenLoaded) return
    if (location.pathname === urls.assets || isOrdersPollingUrl) {
      GetSym()
    }
  }, [isTokenLoaded, isOrdersPollingUrl, location.pathname])

  useEffect(() => {
    setInstrumentType(ETigerXInstruments.BINANCE_X)
  }, [])

  useEffect(() => {
    if (!isTokenLoaded) return

    if (!isWalletPollingUrl) {
      walletPageService.stopDataPolling()
    }

    if (!isOrdersPollingUrl) {
      ordersPageService.stopDataPolling()
    }

    const ordersDataFetcher = () => {
      if (symInfoStatus !== 'idle' && symInfoStatus !== 'loading') {
        ordersPageService.stopDataPolling()
        ordersPageService.startDataPolling()
      }
    }
    currentPathRef.current = location.pathname
    switch (location.pathname) {
      case urls.wallet:
        ordersDataFetcher()
        walletPageService.startDataPolling()
        break
      case urls.assets:
        walletPageService.startDataPolling()
        break
      case urls.orders:
        ordersDataFetcher()
        walletPageService.startDataPolling()
        break
      case urls.historyTrade:
        // useApiPageService()
        break
      case urls.api:
        // useApiPageService()
        break
      case urls.leverage:
        // useCommissionPageService()
        break
      case urls.cashback:
        // useLeveragePageService()
        break
      default:
      // console.error('Неизвестная страница:')
    }
  }, [isTokenLoaded, location.pathname, instrumentType, symInfoStatus])

  return <>{children}</>
}
