import { t } from '@lingui/macro'
import { TwoFA } from './ProfileRightBar.components/TwoFA'
import { BNBBurn } from './ProfileRightBar.components/BNBBurn'
import { BNBConvert } from './ProfileRightBar.components/BNBConvert'
import { DefaultPage } from './ProfileRightBar.components/DefaultPage'
import { MultiAssetMode } from './ProfileRightBar.components/MultiAssetMode'
import { ExchangeTypeSettings } from './ProfileRightBar.components/ExchangeType'
import { EXCHANGE_CONFIG as EXCHANGE_FLAGS } from '@/core/config/exchange'
import style from './style.module.scss'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { SetPositionMode } from './ProfileRightBar.components/SetPositionMode'
import { Address } from './ProfileRightBar.components/Address'
import { WhitelistManage } from './ProfileRightBar.components/WhitelistManage'
import { AdditionalInfo } from './ProfileRightBar.components/AdditionalInfo'
import { FC, useMemo } from 'react'
import { useExchangeBlockedOverlay } from '@/utils/hooks'
import { useExchangesDisabled } from '@/utils/hooks/useExchangesDisabled'
import { ApiKeyRepair } from './ProfileRightBar.components/ApiKeyRepair'
import { EXCHANGES_TYPE } from '@/core/constants'
import { Nickname } from './ProfileRightBar.components/Nickname'
import { useTeamData } from '@/utils/hooks/useTeamData'
import { E_TEAM_USER_MODE } from '@/redux/team/team.types'
import { PinCode } from './ProfileRightBar.components/PinCode'

const ProfileRightBar: FC = () => {
  const { exchangeType } = useExchageType()
  const { isExchangeBlockedByOverlay } = useExchangeBlockedOverlay(exchangeType)
  const { isAccountSettingsEnabled } = useExchangesDisabled()
  const { userData } = useTeamData()

  const EXCHANGE_CONFIG = useMemo(() => {
    /** Disabled all flags in case of overlay */
    if (exchangeType && isExchangeBlockedByOverlay) {
      return {
        ...EXCHANGE_FLAGS,
        [exchangeType]: {
          ...EXCHANGE_FLAGS[exchangeType],
          profile: Object.keys(EXCHANGE_FLAGS[exchangeType].profile).reduce((acc, key) => {
            return { ...acc, [key]: false }
          }, {} as Record<string, boolean>),
        },
      }
    }

    return EXCHANGE_FLAGS
  }, [exchangeType, isExchangeBlockedByOverlay])

  if (!exchangeType) return null

  return (
    <div className={style.root}>
      <h2 className={style.heading}>{t({ id: 'profile.title', message: `Settings` })}</h2>

      {userData.userMode !== E_TEAM_USER_MODE.noTeam && (
        <section className={style.section}>
          <Nickname />
        </section>
      )}

      <ExchangeTypeSettings />
      {/* Temprorary disabled for okx affiliate */}
      {exchangeType !== EXCHANGES_TYPE.OKX_AFFILIATE && (
        <>
          <section className={style.section}>
            <h6 className={style.sectionHeading}>{t({ id: 'profile.address.heading', message: `Withdrawal` })}</h6>
            <Address />
            <WhitelistManage />
          </section>
          {exchangeType !== EXCHANGES_TYPE.TIGER_X && (
            <section className={style.section}>
              <h6 className={style.sectionHeading}>
                {t({ id: 'profile.apiRepair.heading', comment: 'repair api heading' })}
              </h6>
              <ApiKeyRepair />
            </section>
          )}
        </>
      )}

      <TwoFA />

      {EXCHANGE_CONFIG[exchangeType].profile.assetMode && <MultiAssetMode disabled={!isAccountSettingsEnabled} />}

      {/* Temprorary disabled for okx affiliate */}
      {exchangeType !== EXCHANGES_TYPE.OKX_AFFILIATE && (
        <section className={style.section}>
          <h6 className={style.sectionHeading}>{t({ id: 'profile.settings.other', message: `Other settings` })}</h6>
          <PinCode />
          <DefaultPage />
          {EXCHANGE_CONFIG[exchangeType].profile.additionalInfo && <AdditionalInfo />}

          {EXCHANGE_CONFIG[exchangeType].profile.positionMode && (
            <SetPositionMode disabled={!isAccountSettingsEnabled} />
          )}

          {EXCHANGE_CONFIG[exchangeType].profile.bnbBurn && <BNBBurn disabled={!isAccountSettingsEnabled} />}

          {EXCHANGE_CONFIG[exchangeType].profile.convertSmallBalance && (
            <BNBConvert disabled={!isAccountSettingsEnabled} />
          )}
        </section>
      )}
    </div>
  )
}

export { ProfileRightBar }
