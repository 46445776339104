export function groupTransfersByExchange(data: any[]): Record<string, any[]> {
  return data.reduce((acc, item) => {
    if (!acc[item.subExchangeType]) {
      acc[item.subExchangeType] = [] // Если ещё нет ключа, создаем массив
    }

    // Добавляем в массив transfers с добавленным полем asset
    const transfersWithAsset = item.transfers.map((transfer: any) => ({
      ...transfer,
      asset: item.coin, // Добавляем asset из coin
    }))

    // Добавляем в массив все transfers из текущего объекта
    acc[item.subExchangeType].push(...transfersWithAsset)

    return acc
  }, {} as Record<string, any[]>)
}

export function addBalancesToTransfers(
  transfersData: Record<string, any[]>,
  balancesData: any[]
): Record<string, any[]> {
  const balanceMap = new Map()

  // 1. Создаём мапу балансов, где ключ - "asset_subExchange"
  balancesData.forEach(balance => {
    const key = `${balance.asset}_${balance.subExchange}`
    balanceMap.set(key, balance.balance)
  })

  // 2. Добавляем баланс в transfersData
  Object.keys(transfersData).forEach(exchangeType => {
    transfersData[exchangeType] = transfersData[exchangeType].map(transfer => {
      const balanceKey = `${transfer.coin}_${exchangeType}`
      return {
        ...transfer,
        balance: balanceMap.get(balanceKey) || 0, // Если нет баланса, ставим 0
      }
    })
  })

  return transfersData
}
