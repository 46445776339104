import {
  BNBBurnStatusResponseDTO,
  DustStatusResponseDTO,
  LocaleRequestDTO,
  LoginWithTwoFaRequestDTO,
  MetadataRequestDTO,
  MultiAssetMarginResponseDTO,
  ProfileResponseDTO,
  SetPinDTO,
  UpdateBNBBurnRequestDTO,
  UpdateDustStatusRequestDTO,
  UpdateMultiAssetMarginRequestDTO,
  VerifyTwoFaRequestDTO,
} from '@/backend/models/ProfileDTO'
import { axiosMainService } from '@/services/axios'
import { binanceRestClients } from '@/services/axios/instances/binance_clients'
import { appError } from '@/services/error'
import { ErrorCode } from '../models/ErrorCodesDTO'
import { enqueueSnackbar } from 'notistack'

export const ProfileApi = {
  async getProfile() {
    try {
      return await axiosMainService.get<ProfileResponseDTO>('/two-fa/protected/api/v2/profile')
    } catch (err: any) {
      throw appError(err)
    }
  },

  async resetTwoFA() {
    try {
      return await axiosMainService.post('/two-fa/protected/api/v2/profile/2fa-reset')
    } catch (err: any) {
      throw appError(err)
    }
  },

  async updateLocale(payload: LocaleRequestDTO) {
    try {
      return await axiosMainService.post('/two-fa/protected/api/v2/profile/locale', payload)
    } catch (err: any) {
      throw appError(err)
    }
  },

  async setPin(payload: SetPinDTO) {
    try {
      return await axiosMainService.post('/two-fa/protected/api/v2/profile/pin', payload)
    } catch (e: any) {
      const { code }: { code: ErrorCode } = e.response.data
      if (code) {
        enqueueSnackbar('Error', { variant: 'error' })
        throw e
      } else {
        throw e
      }
    }
  },

  async resetPin() {
    try {
      return await axiosMainService.post('/two-fa/protected/api/v2/profile/pin-reset')
    } catch (e: any) {
      const { code }: { code: ErrorCode } = e.response.data
      if (code) {
        enqueueSnackbar('Error', { variant: 'error' })
        throw e
      } else {
        throw e
      }
    }
  },

  async verifyTwoFa(payload: VerifyTwoFaRequestDTO) {
    try {
      return axiosMainService.post('/two-fa/protected/api/v2/profile/verifyTwoFa', payload)
    } catch (err: any) {
      throw appError(err)
    }
  },

  async loginWith2fa(payload: LoginWithTwoFaRequestDTO) {
    try {
      return axiosMainService.post('/two-fa/protected/api/v2/profile/loginTwoFa', payload)
    } catch (err: any) {
      throw appError(err)
    }
  },

  async updateMetadata(metadata: MetadataRequestDTO) {
    try {
      return await axiosMainService.patch('/two-fa/protected/api/v2/profile/metadata', { metadata })
    } catch (err: any) {
      throw appError(err)
    }
  },

  /* Get BNB burn status */
  async getBNBBurnStatus() {
    try {
      return await axiosMainService.get<BNBBurnStatusResponseDTO>('/overview/protected/api/v1/spot/bnbBurn')
    } catch (err: any) {
      throw appError(err)
    }
  },

  /* Update BNB burn status */
  async updateBNBBurn(payload: UpdateBNBBurnRequestDTO) {
    try {
      return await axiosMainService.post<UpdateBNBBurnRequestDTO>('/overview/protected/api/v1/spot/bnbBurn', payload)
    } catch (err: any) {
      throw appError(err)
    }
  },

  /* Get Dust status */
  async getDustStatus() {
    try {
      return await axiosMainService.get<DustStatusResponseDTO>('/overview/protected/api/v1/spot/dust')
    } catch (err: any) {
      throw appError(err)
    }
  },

  /* Update dust status */
  async updateDust(payload: UpdateDustStatusRequestDTO) {
    try {
      return await axiosMainService.post<DustStatusResponseDTO>('/overview/protected/api/v1/spot/dust', payload)
    } catch (err: any) {
      throw appError(err)
    }
  },

  /* Get multi asset margin */
  async getMultiAssetMargin() {
    try {
      return await binanceRestClients.usdm?.getMultiAssetMargin()
    } catch (err: any) {
      throw appError(err)
    }
  },

  /* Update multi asset margin */
  async updateMultiAssetMargin(payload: UpdateMultiAssetMarginRequestDTO) {
    try {
      return await axiosMainService.post<MultiAssetMarginResponseDTO>(
        '/overview/protected/api/v1/usdm/multiAssetsMargin',
        payload
      )
    } catch (err: any) {
      throw appError(err)
    }
  },

  async getUsdmPositionMode() {
    return await binanceRestClients.usdm?.getCurrentPositionMode()
  },

  async getCoinmPositionMode() {
    return await binanceRestClients.coinm?.getCurrentPositionMode()
  },
}
