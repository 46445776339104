import { t } from '@lingui/macro'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { transactionsAsyncActions } from '@/redux/transactions/transactions.actions'
import { transactionsActions } from '@/redux/transactions/transactions.slice'
import { useActions, useAppDispatch, useAppSelector } from '@/utils'
import { Select, SelectOption, SelectProps } from '@/ui/kit'
import { DepositWalletAddress, Prompt } from '@/ui/molecules'
import style from './style.module.scss'
import { useMobileSizeDetect } from '@/utils/hooks'
import { NetworkSelectOption } from '@/ui/atoms/NetworkSelectOption'
import { CoinSelectOption } from '@/ui/atoms/CoinSelectOption'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import clsx from 'clsx'
import { Loader } from '@/ui/kit/Loader'
import { CoinWithNetworks, NetworkInfo } from './types'

export type PropsType = {
  depositData: {
    options: CoinWithNetworks[]
  }
  minDeposit: number | null
}

export type NetworkOption = SelectOption & { name: string }

export const DepositForm = memo<PropsType>(({ depositData, minDeposit }) => {
  const dispatch = useAppDispatch()
  const { GetDepositWalletAddressTC } = useActions(transactionsAsyncActions)
  const { depositWalletAddress } = useAppSelector(state => state.transactions)

  const [isMobile] = useMobileSizeDetect()
  const [selectedCoin, setSelectedCoin] = useState<string | null>(null)
  const [networks, setNetworks] = useState<NetworkInfo[]>([])
  const [networkName, setNetworkName] = useState<string | null>(null)
  const [selectedNetwork, setSelectedNetwork] = useState<{ name: string; value: string } | null>(null)

  const coins = useMemo(
    () =>
      depositData.options.map(option => {
        const exchange = option?.subExchangeType ? `X ${option?.subExchangeType}` : ''

        return {
          value: option.coin,
          search: option.coin,
          label: <CoinSelectOption asset={option.coin} suffix={exchange} />,
          selected: option.coin,
        }
      }),
    [depositData.options]
  )

  const networkOptions = useMemo(
    () =>
      networks.map(network => {
        const desc = `${network.name}${network.tokenStandard ? ` (${network.tokenStandard})` : ''}`

        return {
          value: network.network,
          name: desc,
          label: <NetworkSelectOption symbol={network.name} name={network.alias} />,
          selected: <NetworkSelectOption symbol={network.name} name={network.alias} large />,
        }
      }),
    [networks]
  )

  const findOptionByCoin = (coin: string) => {
    return depositData.options.find(item => item.coin === coin)
  }

  const findNetworkByValue = (value: string) => {
    return networks.find(item => item.network === value)
  }

  const onCoinChange = (value: string) => {
    const currentOption = findOptionByCoin(value)
    if (currentOption) {
      setNetworks(currentOption?.networks)
    }

    setSelectedCoin(value)
    setNetworkName(null)
    setSelectedNetwork(null)
    cleanWalletAddress()
  }

  const onNetworkChange: SelectProps<string>['onChange'] = value => {
    setNetworkName(value)
    cleanWalletAddress()

    const network = findNetworkByValue(value)

    if (network && selectedCoin) {
      setSelectedNetwork({
        name: network.name,
        value: network.network,
      })
      GetDepositWalletAddressTC({ coin: selectedCoin, network: network?.network })
    }
  }

  const cleanWalletAddress = useCallback(() => {
    dispatch(transactionsActions.resetWalletAddress())
  }, [dispatch])

  useEffect(() => cleanWalletAddress, [cleanWalletAddress])

  const address = depositWalletAddress?.address?.address
  const status = depositWalletAddress?.status
  const errorText =
    status === 'failed'
      ? t({
          message: "Can't generate address for selected coin and network",
          id: 'deposittransaction.cantgenerateAddress',
        })
      : ''
  const depositWalletAddressText = address || errorText

  return (
    <div className={clsx(isMobile && style.mobile, style.deposit)}>
      <div className={style.top}>
        <Select
          options={coins}
          value={selectedCoin}
          size={isMobile ? Select.Size.Medium : Select.Size.Large}
          onChange={onCoinChange}
          label={t({ message: 'Coin', id: 'deposittransaction.coin' })}
          placeholder={t({ message: 'Select coin', id: 'deposittransaction.selectCoin' })}
          className={style.coinSelect}
          dataTestId={DataTestIds.CoinSelector}
          withSearch
        />
        <Select
          options={networkOptions}
          value={networkName}
          size={isMobile ? Select.Size.Medium : Select.Size.Large}
          onChange={onNetworkChange}
          label={t({ message: 'Network', id: 'deposittransaction.network' })}
          placeholder={t({ message: 'Select Network', id: 'deposittransaction.selectNetwork' })}
          disabled={!selectedCoin}
          className={style.networkSelect}
          dataTestId={DataTestIds.NetworkSelector}
        />
      </div>

      <div className={style.promptWrap} data-testid={DataTestIds.DepositAlertDescription}>
        <Prompt
          promptType={'transaction'}
          coin={selectedCoin}
          networkSymbol={selectedNetwork?.value}
          networkType={selectedNetwork?.name}
        />
      </div>

      <div className={style.description} data-testid={DataTestIds.DepositInfoDescription}>
        {minDeposit ? (
          <>{t({ comment: "There's no minimum deposit requirement.", id: 'deposittransaction.minamount.tigerX' })}</>
        ) : (
          <>{t({ message: "There's no minimum deposit requirement.", id: 'deposittransaction.description-1' })}</>
        )}
        <br />
        {t({
          message: 'Deposit any amount, including the blockchain commission.',
          id: 'deposittransaction.description-2',
        })}
      </div>
      {depositWalletAddress.status === 'loading' && (
        <div className={style.loader}>
          <Loader />
        </div>
      )}
      {depositWalletAddressText && <DepositWalletAddress address={depositWalletAddressText} />}
    </div>
  )
})
