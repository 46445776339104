import { useState, useRef, FC } from 'react'
import styles from './styles.module.scss'
import { useOutsideClick } from '@/utils'
import { SVG } from '@/assets/svg'
import { t } from '@lingui/macro'
import clsx from 'clsx'

type ExportMenuProps = {
  onClickHandler: () => void
  className?: string
}

const ExportMenu: FC<ExportMenuProps> = ({ onClickHandler, className }) => {
  const [isOpen, setIsOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)

  const toggleMenu = (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsOpen(prev => !prev)
  }
  const ref = useOutsideClick<HTMLDivElement>(() => setIsOpen(false))

  return (
    <div className={clsx(styles.container, className)} ref={ref}>
      <div className={styles.iconButton} onClick={toggleMenu}>
        <SVG.OtherIcons.Export />
      </div>
      {isOpen && (
        <div
          ref={menuRef}
          className={styles.dropdown}
          onClick={() => {
            onClickHandler()
            setIsOpen(false)
          }}
        >
          <div className={styles.menuItem}>
            <SVG.OtherIcons.Csv className={styles.icon} />
            <span>
              {t({
                id: 'exportMenu.title',
              })}
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export { ExportMenu }
