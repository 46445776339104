import { TInstrumentType } from '@/core/types/overview'
import { ETigerXInstruments } from '@/exchanges/tigerx/tigerx.config'
import { AccountInfo } from '@/services/tigerx'

export const formatUniMMR = (input: string): string => {
  const isAllNines = /^9{6,}$/.test(input)

  if (isAllNines) {
    return '--'
  }

  const numberValue = parseFloat(input)

  if (isNaN(numberValue)) {
    return '0'
  }

  return numberValue.toFixed(2).replace('.', ',')
}

export const formatUpnlValue = (value: number | undefined | string) => {
  const formattedValue = Number(value ?? 0).toFixed(2)
  return formattedValue === '-0.00' ? '0.00' : formattedValue
}

export const getFilteredAccountData = (account: AccountInfo[] | null, instrumentType?: TInstrumentType) => {
  const accountInstrumentType = instrumentType === ETigerXInstruments.BINANCE_X ? 'BINANCE' : 'OKX'
  return account?.find(accountData => accountData.exchangeType === accountInstrumentType)
}
