import { FC, ReactNode } from 'react'
import style from './style.module.scss'
import { SVG } from '@/assets/svg'
import clsx from 'clsx'

type InfoListItemProps = {
  text: string | ReactNode
  className?: string
}

export const InfoListItem: FC<InfoListItemProps> = ({ text, className }) => {
  return (
    <div className={clsx(style.container, className)}>
      <div className={style.arrow}>
        <SVG.OtherIcons.ArrowRight />
      </div>
      <div className={style.text}>{text}</div>
    </div>
  )
}
