import { ECashbackCalculationRule } from '@/backend/api/cashbackAPI'
import { Status } from '@/backend/types'
import { FieldErrorType, ThunkErrorValue } from '@/types'
import { TRiskManagerTradingStatus } from '../riskManager/riskManager.types'

export enum E_TEAM_PERMISSIONS {
  controlCashbackPercent = 'controlCashbackPercent',
  tradingDataAccess = 'tradingDataAccess',
}

export enum E_RISK_MANAGER_PERMISSIONS {
  closeOrdersPositions = 'closeOrdersPositions',
  controlApiKeyPermissions = 'controlApiKeyPermissions',
  controlApiKeyCreation = 'controlApiKeyCreation',
}

export enum E_TEAM_USER_MODE {
  user = 'user',
  manager = 'manager',
  noTeam = 'noTeam',
}

export enum E_TEAM_USER_STATUS {
  none = 'none',
  submitted = 'submitted',
  approved = 'approved',
  rejected = 'rejected',
}

export enum E_RISK_MANAGER_DRAWDOWN_TYPE {
  percent = 'percent',
  usdt = 'usdt',
}

export enum E_RISK_MANAGER_TYPE {
  external = 'external',
  internal = 'internal',
}

export type TTeamInfo = {
  teamCode: string
  teamTitle: string
  permissions: E_TEAM_PERMISSIONS[]
}

export type TTeamUserInfo = {
  applicationStatus: E_TEAM_USER_STATUS
  teamTitle: string | null
  teamCode: string
  cashbackPercent: number | null
  riskManagerEnabled: boolean | null
  riskManagerAvailable: boolean | null
  statisticId: number | null
  showLogoToReferral?: boolean
  teamLogoUrl?: string
}

export type TTeamManagerInfo = Partial<TManagerCashback> & {
  teamTitle: string
  teamLogoUrl: string
  teamCode: string
  referralCode: string
  numberOfMembers: number
  gainByMonth: number
  statisticId: number | null
  showLogoToReferral?: boolean
}

export type TUserListEntity = {
  userId: string
  lastTradeAt: string
  cashbackPercent: number
  drawdownType: E_RISK_MANAGER_DRAWDOWN_TYPE
  drawDownValue: number
  riskManagerEnabled: boolean
  joinedAt: string
}

export type TUserRequestEntity = {
  requestId: string
  userId: string
  requestedAt: string
}

export type TManagerRequestEntity = TUserRequestEntity & {
  emailId: string
}

export type TManagerCashback = {
  binanceCashbackPercent: number
  bybitCashbackPercent: number
  okxCashbackPercent: number
}

export type TManagerMemberListEntity = TManagerCashback & {
  userId: string
  lastTradeAt: string
  riskManagerEnabled: boolean
  joinedAt: string
  userEmail: string
  drawdownPercent: number
  drawdownValue: number
  label: string
  drawdownType: E_RISK_MANAGER_DRAWDOWN_TYPE
  tradingEnabled: boolean
}

export type TRiskManagerSettings = {
  botHandle: string // telegram bot address
  enabled: boolean
  userHandle: string // user telegram nickname
  drawdownType: E_RISK_MANAGER_DRAWDOWN_TYPE
  drawdownValue: number
  permissions: E_RISK_MANAGER_PERMISSIONS[]
  type: E_RISK_MANAGER_TYPE
}

export type TeamMembersCashbackEditPayload = TManagerCashback & {
  userId: string
  label: string
}

export type TeamManagerOwnCashback = TManagerCashback & {
  binanceCalculationRule: ECashbackCalculationRule
  bybitCalculationRule: ECashbackCalculationRule
  okxCalculationRule: ECashbackCalculationRule
}

export type InitialState = {
  teamRequested: TTeamInfo | undefined
  teamRequestedErrors: ThunkErrorValue
  teamRequestedRequestStatus: Status
  userMode: E_TEAM_USER_MODE | undefined
  teamUserInfo: TTeamUserInfo | undefined
  teamManagerInfo: TTeamManagerInfo | undefined
  teamInfoRequestInitialStatus: Status // track first load
  teamInfoRequestStatus: Status
  teamJoinRequestStatus: Status
  changeCashbackRequestStatus: Status
  teamJoinErrors: ThunkErrorValue
  teamCancelJoinRequestStatus: Status
  teamLeaveRequestStatus: Status
  riskManagerSettings: TRiskManagerSettings | undefined
  rmGetRequestStatus: Status
  rmSetRequestStatus: Status
  changeManagerDefaultCashbackStatus: Status
  rmUpdateRequestStatus: Status
  rmDeleteRequestStatus: Status
  tradingStatus: TRiskManagerTradingStatus | undefined
  tradingStatusRequestStatus: Status
  deleteMemberStatus: Status
  teamManagerOwnCashback: TeamManagerOwnCashback
  teamManagerOwnCashbackStatus: Status
  usersList: {
    users: TUserListEntity[]
    page: number
    size: number
    total: number
  }
  managerMembersList: {
    members: TManagerMemberListEntity[]
    page: number
    size: number
    total: number
    managerDataReachedEnd: boolean
  }
  managerMembersListSearch: string
  managerMembersListRequestStatus: Status
  managerRequestsList: {
    requests: TManagerRequestEntity[]
    page: number
    size: number
    total: number
    managerDataReachedEnd: boolean
  }
  managerRequestsListRequestStatus: Status
  managerRequestStatus: Status
  usersListRequestStatus: Status
  userRequestsList: {
    requests: TUserRequestEntity[]
    page: number
    size: number
    total: number
  }
  userRequestsListRequestStatus: Status
  errors: FieldErrorType[]
}
