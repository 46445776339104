import { InitialState, TypePeriod } from './leaderboard.types'

export const initialState: InitialState = {
  spotLeaders: null,
  pnlLeaders: null,
  futureLeaders: null,
  period: TypePeriod.WEEK,
  typeResult: 'percent',
  isLoading: false,
  nickname: null,
  isLoadingNickname: false,
  errors: [],
}

export default initialState
