import { t } from '@lingui/macro'
import style from './style.module.scss'
import { Button, ButtonSize, ButtonVariant } from '@/ui/kit'
import { Trans } from '@lingui/react'
import { FC, useEffect } from 'react'
import { useActions, useAppSelector } from '@/utils'
import { profileActions } from '@/redux/profile/profile.slice'
import { PinScreenShow, PinScreenShowType } from '../../PinCodeModal'

type PinResetProps = PinScreenShowType & { onClose: React.Dispatch<React.SetStateAction<boolean>> }

export const PinReset: FC<PinResetProps> = ({ onClose, setPinScreenShow }) => {
  const { ResetPin, GetProfileTC } = useActions(profileActions)
  const { resetPinStatus } = useAppSelector(state => state.profile)
  const isDisable = resetPinStatus === 'loading'

  const resetPinHandler = () => {
    ResetPin()
  }

  useEffect(() => {
    if (resetPinStatus === 'succeeded') {
      GetProfileTC()
      onClose(false)
      setPinScreenShow(PinScreenShow.RESET)
    }
  }, [resetPinStatus])

  return (
    <div className={style.container}>
      <div className={style.desctiption}>
        {t({
          id: 'profile.pinCode.resetPin.description',
        })}
      </div>
      <div className={style.btns}>
        <Button variant={ButtonVariant.Accent} size={ButtonSize.Medium} onClick={resetPinHandler} disabled={isDisable}>
          {t({
            id: 'profile.pinCode.resetPin.send',
          })}
        </Button>
        <Button
          variant={ButtonVariant.Primary}
          size={ButtonSize.Medium}
          onClick={() => onClose(false)}
          disabled={isDisable}
        >
          {t({
            id: 'profile.pinCode.close',
          })}
        </Button>
      </div>
      <div className={style.support}>
        <Trans
          id="profile.pinCode.enterPin.support"
          components={{
            link: (
              <a
                className={style.link}
                href={`mailto:${t({ id: 'core.supportEmail', message: 'broker.support@tiger.trade' })}`}
              />
            ),
          }}
        />
      </div>
    </div>
  )
}
