import { FC } from 'react'

type CsvIconProps = {
  className?: string
}

export const Csv: FC<CsvIconProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={20} fill="none" className={className}>
    <g clipPath="url(#a)">
      <path
        fill="#FAFAFA"
        d="M9.25 5.312V0H1.437A.935.935 0 0 0 .5.937v18.125c0 .52.418.938.938.938h13.124c.52 0 .938-.418.938-.938V6.25h-5.313a.94.94 0 0 1-.937-.938ZM5.5 10.937c0 .173-.14.313-.313.313h-.312a.625.625 0 0 0-.625.625v1.25c0 .345.28.625.625.625h.313c.172 0 .312.14.312.312v.625c0 .173-.14.313-.313.313h-.312A1.875 1.875 0 0 1 3 13.125v-1.25C3 10.839 3.84 10 4.875 10h.313c.172 0 .312.14.312.312v.625ZM7.23 15h-.48a.312.312 0 0 1-.313-.313v-.625c0-.172.14-.312.313-.312h.48c.232 0 .406-.137.406-.259 0-.05-.03-.104-.083-.15l-.855-.733a1.45 1.45 0 0 1-.52-1.1c0-.831.742-1.508 1.656-1.508h.479c.172 0 .312.14.312.313v.625c0 .172-.14.312-.313.312h-.479c-.232 0-.406.137-.406.259 0 .05.029.104.082.15l.855.733c.331.282.521.683.521 1.1 0 .83-.742 1.508-1.656 1.508Zm3.27-4.688v.813c0 .792.223 1.569.625 2.222.402-.653.625-1.43.625-2.222v-.813c0-.172.14-.312.313-.312h.624c.173 0 .313.14.313.312v.813c0 1.386-.503 2.69-1.417 3.675a.625.625 0 0 1-.916 0c-.914-.984-1.417-2.29-1.417-3.675v-.813c0-.172.14-.312.313-.312h.624c.173 0 .313.14.313.312Zm4.727-6.21L11.402.272A.937.937 0 0 0 10.738 0H10.5v5h5V4.76a.935.935 0 0 0-.273-.66Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.5 0h15v20H.5z" />
      </clipPath>
    </defs>
  </svg>
)
