export const checkIsAddressCorrect = (address: string, addressRegex?: string): boolean => {
  if (!addressRegex && address.length > 0) return true

  if (!address) {
    return false
  }

  if (addressRegex) {
    try {
      const regex = new RegExp(addressRegex)
      return regex.test(address)
    } catch (e) {
      console.error('[checkIsAddressCorrect] Invalid addressRegex:', addressRegex, e)

      // Fallback: если regex некорректен — лучше не блокировать
      return true
    }
  }

  return true
}
