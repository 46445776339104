import { FC, memo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { t } from '@lingui/macro'
import { SVG } from '@/assets/svg'
import { Button } from '@/ui/kit'
import style from './style.module.scss'
import { useAppSelector } from '@/utils'
import { isExchangeBinance } from '@/utils/lib/exchange'
import { urls } from '@/router/urls'
import { useActiveExchage } from '@/utils/hooks/useActiveExchange'

const MainTabButtons: FC = () => {
  const { userKycLinkStatusRequestStatus, userKycLinkState, userKYC } = useAppSelector(state => state.kyc)
  const { activeExchange } = useActiveExchage()
  const [isTransactionsEnabled, setIsTransactionsEnabled] = useState(true)

  //delete when refactor P2-588
  function isDepositBlockedForExchange(type: string): boolean {
    if (isExchangeBinance(type)) {
      if (userKycLinkStatusRequestStatus !== 'succeeded') return false

      return (
        userKycLinkState.status === 'FINAL_REJECT' ||
        userKycLinkState.status === 'REJECT' ||
        userKycLinkState.status === 'INIT'
      )
    }

    return false
  }

  //delete when refactor P2-588
  useEffect(() => {
    if (userKYC.kycTier === 'UNVERIFIED' || userKYC.kycTier === 'BASIC') {
      setIsTransactionsEnabled(false)
    }
  }, [userKYC.kycTier])

  const isDepositBlocked = isDepositBlockedForExchange(activeExchange.type)
  const navigate = useNavigate()

  return (
    <>
      <div className={style.mainTabButtons}>
        {activeExchange.transactions.transfer.isAvailable && (
          <Button.Primary
            label={t({ message: `Transfer`, id: 'core.transfer' })}
            rightIcon={<SVG.Actions.Transfer />}
            onClick={() => {
              navigate(urls.transfer)
            }}
            disabled={!isTransactionsEnabled}
          />
        )}
        {activeExchange.transactions.deposit.isAvailable && (
          <Button.Primary
            label={t({ message: `Deposit`, id: 'core.deposit' })}
            rightIcon={<SVG.Actions.Deposit />}
            onClick={() => {
              navigate(urls.deposite)
            }}
            disabled={isDepositBlocked || !isTransactionsEnabled}
          />
        )}
        {activeExchange.transactions.withdraw.isAvailable && (
          <Button.Primary
            label={t({ message: `Withdraw`, id: 'core.withdraw' })}
            rightIcon={<SVG.Actions.Withdraw />}
            onClick={() => {
              navigate(urls.withdraw)
            }}
            disabled={!isTransactionsEnabled}
          />
        )}
      </div>
    </>
  )
}

export default memo(MainTabButtons)
