import { FC } from 'react'

type AlertRedProps = {
  className?: string
}
export const AlertRed: FC<AlertRedProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={25} fill="none" className={className}>
    <path
      stroke="#F97052"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M20 13.5c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 21 4 18.5 4 13.5v-7a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 4.31 17 5.5 19 5.5a1 1 0 0 1 1 1v7ZM12 8.5v4M12 16.5h.01"
    />
  </svg>
)
