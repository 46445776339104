import {
  CreateReferralCodeRequestDTO,
  ReferralOverviewResponseDTO,
  ApplyReferralCodeRequestDTO,
  CreateReferralCodeResponseDTO,
  TGetReferralEarningsPayload,
  TGetReferralEarningsDTO,
  TGetBenefitsDTO,
  UpdateReferralCodeRequestDTO,
  UpdateReferralCustomCodeRequestDTO,
  GetReferralCodeInfoDTO,
  TGetReferralExchangeSectionsDTO,
} from '@/backend/models/ReferralsDTO'
import { axiosMainService } from '@/services/axios'
import { appError } from '@/services/error'
import { TExchangesAvailable } from '@/core/constants'

export const ReferralsApi = {
  /* Get referrals state */
  async getReferralOverview() {
    try {
      return await axiosMainService.get<ReferralOverviewResponseDTO>('/cashback/protected/api/v1/referral')
    } catch (err: any) {
      throw appError(err)
    }
  },

  async getReferralCodeInfo(code: string) {
    try {
      return await axiosMainService.get<GetReferralCodeInfoDTO>(`/cashback/protected/api/v1/referral/code/${code}`)
    } catch (err: any) {
      throw appError(err)
    }
  },

  /* Apply referral code */
  async applyReferralCode(payload: ApplyReferralCodeRequestDTO) {
    try {
      return await axiosMainService.post('/cashback/protected/api/v1/referral', payload)
    } catch (err: any) {
      throw appError(err)
    }
  },

  /* Update referral code */
  async updateReferralCode(payload: UpdateReferralCodeRequestDTO) {
    try {
      return await axiosMainService.patch('/cashback/protected/api/v1/referral', payload)
    } catch (err: any) {
      throw appError(err)
    }
  },

  async updateCustomReferralCode(payload: UpdateReferralCustomCodeRequestDTO) {
    try {
      return await axiosMainService.patch(`/cashback/protected/api/v1/referral/custom/${payload.oldCode}`, {
        code: payload.newCode,
      })
    } catch (err: any) {
      throw appError(err)
    }
  },

  /* Create custom referral code */
  async createReferralCode(payload: CreateReferralCodeRequestDTO) {
    try {
      return await axiosMainService.post<CreateReferralCodeResponseDTO>(
        '/cashback/protected/api/v1/referral/custom',
        payload
      )
    } catch (err: any) {
      throw appError(err)
    }
  },

  /* Delete referral code */
  async deleteReferralCode(code: string) {
    try {
      return await axiosMainService.delete(`/cashback/protected/api/v1/referral/custom/${code}`)
    } catch (err: any) {
      throw appError(err)
    }
  },

  async getReferralEarnings(payload: TGetReferralEarningsPayload) {
    return await axiosMainService.post<TGetReferralEarningsDTO>(
      '/cashback-reporting/protected/api/v1/referral/earnings',
      {},
      { params: payload }
    )
  },

  async getReferralExchangeSectionList() {
    return await axiosMainService.get<TGetReferralExchangeSectionsDTO>(
      '/cashback-reporting/protected/api/v1/referral/exchangeSectionList'
    )
  },

  async referallEarningsExport(payload: TGetReferralEarningsPayload) {
    return await axiosMainService.post('/cashback-reporting/protected/api/v1/referral/export', {}, { params: payload })
  },

  async getBenefits(payload: TExchangesAvailable) {
    return await axiosMainService.get<TGetBenefitsDTO>(
      `/cashback/protected/api/v1/referral/benefits?exchangeType=${payload}`
    )
  },
}
