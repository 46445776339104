export const formatPrice = (price: string): string => {
  const num = parseFloat(price)
  if (isNaN(num)) return price

  const decimalPart = price.split('.')[1] || ''
  const zeroMatch = decimalPart.match(/^0+/)
  const zeroCount = zeroMatch ? zeroMatch[0].length : 0

  if (decimalPart.length <= 8) {
    return price
  }

  if (zeroCount <= 3) {
    return num.toFixed(8)
  }

  const significantPart = decimalPart.slice(zeroCount)
  if (significantPart.length >= 5) {
    const rounded = Math.round(parseFloat(`0.${significantPart}`) * 10000)
      .toString()
      .padStart(4, '0')
    return `0.0{${zeroCount}}${rounded.slice(0, 4)}`
  }

  return `0.0{${zeroCount}}${significantPart.padEnd(4, '0').slice(0, 4)}`
}
