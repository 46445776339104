import { InitialState } from '@/redux/referrals/referrals.types'

export const initialState: InitialState = {
  status: 'idle',
  statusApply: 'idle',
  isLoading: false,
  isLocked: null,
  overview: {
    userCode: '',
    referrerCode: '',
    referrerLocked: false,
    invitedTotal: 0,
    invitedMonth: 0,
    referralVolumeMonth: 0,
    referralVolumeTotal: 0,
    profitMonth: 0,
    profitTotal: 0,
    customCodes: [],
  },
  earnings: {
    referralEarnings: [],
    earningsStatus: 'idle',
    earningsPage: 0,
    earningReachedEnd: false,
    earningUser: '',
    earningNoSearchResult: false,
  },
  benefits: {
    benefits: undefined,
    benefitsStatus: 'idle',
  },
  exchangeSectionList: undefined,
  referralsEarningExport: undefined,
  referralsEarningExportStatus: 'idle',
}
