import { InitialState } from './transactions.types'
import { AccountAssets } from '../../backend/api'
import { getFormattedDate } from './transactions.utils'

const account: AccountAssets = { assets: [], balance: 0, symbol: '' }

export const initialState: InitialState = {
  assetsForTransactions: {
    assets: {
      coinAccountBalance: { ...account },
      errors: [],
      futuresAccountBalance: { ...account },
      spotAccountBalance: { ...account },
    },
    status: 'idle',
  },
  depositWalletAddress: {
    address: null,
    status: 'idle',
  },
  withdrawAddresses: {
    status: 'idle',
    addresses: [],
  },
  transactionSendResult: {},
  assetsForWithdrawAndDeposit: { assets: {}, assetsStatus: 'idle' },
  transferData: [],
  coinBalance: {
    balanceAssets: [],
    status: 'idle',
  },
  withdrawAssetResult: { status: 'idle' },
  historyConvert: {
    isLoading: false,
    list: [],
    moreData: false,
    filters: {
      startTime: (Date.now() - 7 * 24 * 60 * 60 * 1000).toString(), // 7 дней
      endTime: Date.now().toString(), // Текущее время
      limit: 500,
      coin: null,
    },
  },
  exchangeHistory: {
    history: [],
    historyStatus: 'idle',
    historyCurrentLast: '',
    historyReachedEnd: false,
    bybitNextCursor: '',
    startDate: getFormattedDate(7),
    endDate: getFormattedDate(0),
    startTime: '',
    endTime: '',
    incomeType: '',
    coin: '',
    exchangeTigerXType: 'BINANCE',
  },
}
