import { t } from '@lingui/macro'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { FieldErrorType } from '@/types'
import { Button, ButtonSize } from '@/ui/kit'
import { useAppSelector, useGoogleAuthCode } from '@/utils/hooks'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import style from './style.module.scss'
import { GoogleCodeVerification } from '../GoogleCodeVerification'
import { errorTranslations } from '@/core/constants/errors/errorsTranslations'
import { SVG } from '@/assets/svg'
import { EnableWhiteList } from './components/EnableWhiteList/EnableWhiteList'
import { DisableWhiteList } from './components/DisableWhiteList/DisableWhiteList'

interface WhitelistSwitchProps {
  onSubmit?: (googleCode: string) => void
  onCancel?: () => void
  isSubmitDisabled?: boolean
  errors: FieldErrorType[]
  setErrors: (errors: FieldErrorType[]) => void
}

export const WhitelistSwitch: FC<WhitelistSwitchProps> = props => {
  const { onSubmit, onCancel, isSubmitDisabled, setErrors, errors } = props
  const googleCode = useGoogleAuthCode()
  const errorsTrans = errorTranslations()

  const { whitelistEnabled } = useAppSelector(state => state.address)
  const [step, setStep] = useState(1)
  const allNotEmpty = googleCode.code.every(item => item !== '')

  const whiteListTitle = whitelistEnabled
    ? t({
        id: 'profile.whitelist.disable.title',
      })
    : t({
        id: 'profile.whitelist.enable.title',
      })

  const errorsMapped = useMemo<Record<string, string>>(() => {
    return errors.reduce((acc: any, error: any) => {
      return {
        ...acc,
        [error.field]: error.message,
      }
    }, {})
  }, [errors])

  const submitHandler = useCallback(() => {
    const errors: FieldErrorType[] = []

    if (!googleCode.isCorrectCodeLength) {
      errors.push({
        code: 'googleCode',
        field: 'googleCode',
        message: t({
          id: 'services.gAuth.enter.title',
          message: 'Enter the 6-digit code below',
        }),
      })
    }

    if (errors.length > 0) {
      setErrors(errors)
      return
    }
    setErrors([])

    onSubmit!(googleCode.code.join(''))
  }, [googleCode.isCorrectCodeLength, googleCode.code, setErrors, onSubmit])

  useEffect(() => {
    return () => {
      setErrors([])
    }
  }, [])

  return (
    <div className={style.component}>
      <div className={style.titleBlock}>
        <div className={style.headerTitle}>{whiteListTitle}</div>
        <div className={style.closeBtn} onClick={onCancel}>
          <SVG.Additional.Close width="10px" height="10px" />
        </div>
      </div>

      {!whitelistEnabled && step === 1 && <EnableWhiteList setStep={setStep} onClose={onCancel} />}
      {whitelistEnabled && step === 1 && <DisableWhiteList setStep={setStep} onClose={onCancel} />}
      {step === 2 && (
        <>
          <div className={style.label}>
            <GoogleCodeVerification
              header={t({
                id: 'services.gAuth.enter.title',
                message: `Enter the 6-digit code below`,
              })}
              text={t({
                id: 'services.gAuth.enter.subtitle',
                message: `Input code from Google Authenticator`,
              })}
              code={googleCode.code}
              setCode={googleCode.setCode}
              isDisabled={isSubmitDisabled}
              errorMessage={errorsMapped.googleCode ? errorsTrans.googleCode : undefined}
              successMessage={''}
            />
          </div>
          <div className={style.buttonsWrapper}>
            <Button.White
              size={ButtonSize.Medium}
              label={t({
                id: 'core.return',
                message: `Return`,
              })}
              className={style.button}
              onClick={onCancel}
              dataTestId={DataTestIds.ApiKeyRemoveCancel}
            />
            <Button.Primary
              size={ButtonSize.Medium}
              label={t({
                id: 'profile.whitelist.next',
                message: `next`,
              })}
              className={style.button}
              onClick={submitHandler}
              disabled={!allNotEmpty || isSubmitDisabled}
              dataTestId={DataTestIds.ApiKeyRemoveSubmit}
            />
          </div>
        </>
      )}
    </div>
  )
}
