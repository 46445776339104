import { FC, memo, useMemo } from 'react'
import { t } from '@lingui/macro'
import { HeaderMenuLink } from '@/ui/atoms/HeaderMenuLink'
import { urls } from '@/router/urls'
import style from './style.module.scss'
import { HeaderMenuLinkProps, HeaderMenuLinkPropsSource } from '@/ui/atoms/HeaderMenuLink/HeaderMenuLink'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { SVG } from '@/assets/svg'
import { useAppSelector } from '@/utils'
import { useActiveExchage } from '@/utils/hooks/useActiveExchange'

export interface HeaderLinksProps {
  isVipAccess?: boolean
}

const HeaderLinks: FC<HeaderLinksProps> = ({ isVipAccess }) => {
  const { availableUsersTrading } = useAppSelector(state => state.trades)
  const { activeExchange } = useActiveExchage()

  const LINKS = useMemo<HeaderMenuLinkProps[]>(() => {
    const historyLinks: Record<string, HeaderMenuLinkPropsSource> = {
      summary: {
        href: urls.summary,
        text: t({ id: 'header.summary', comment: 'Summary' }),
        dataTestId: DataTestIds.HeaderSummary,
      },
      tradingJournal: {
        href: urls.trades,
        text: t({ id: 'header.trades', comment: 'My trades' }),
        dataTestId: DataTestIds.HeaderTrades,
      },
      dashboard: {
        href: urls.dashboard,
        text: t({ id: 'header.dashboard', comment: 'Dashboard' }),
        dataTestId: DataTestIds.HeaderDashboard,
      },
    }

    let links: HeaderMenuLinkProps[] = [
      {
        url: urls.wallet,
        item: {
          href: urls.wallet,
          text: t({ id: 'header.wallet', message: `Wallet` }),
          dataTestId: DataTestIds.HeaderWallet,
        },
      },
      {
        url: urls.orders,
        item: {
          href: urls.orders,
          text: t({ id: 'header.orders', message: `Orders & positions` }),
          dataTestId: DataTestIds.HeaderOrdersAndPositions,
        },
      },
      {
        url: urls.cashback,
        item: {
          href: urls.cashback,
          text: t({ id: 'header.commission', message: `Saved commission` }),
          dataTestId: DataTestIds.HeaderCashback,
        },
      },
      {
        url: urls.leverage,
        item: {
          href: urls.leverage,
          text: t({ id: 'header.leverage', message: `Leverage` }),
          dataTestId: DataTestIds.HeaderLeverage,
        },
      },
      {
        url: urls.api,
        item: {
          href: urls.api,
          text: t({ id: 'header.api', message: `API` }),
          dataTestId: DataTestIds.HeaderApi,
        },
      },
      {
        url: urls.historyTrade,
        item: {
          href: urls.historyTrade,
          text: t({ id: 'header.history', message: `History` }),
          dataTestId: DataTestIds.HeaderHistory,
        },
      },
      {
        url: urls.journal,
        dropdown: {
          icon: <SVG.OtherIcons.New />,
          text: t({ id: 'header.tradingJournal', comment: 'Trading Journal' }),
          items: Object.values(historyLinks),
        },
      },
    ]

    return isVipAccess
      ? links.filter(link => !(link.item?.href === urls.api || link.item?.href === urls.cashback))
      : links
  }, [availableUsersTrading, isVipAccess])

  // Фильтруем доступные ссылки
  const availableLinks = LINKS.filter(link => !link.url || !activeExchange.blockedNavMenu.includes(link.url))

  return (
    <div className={style.links}>
      {availableLinks.map((link, index) => (
        <HeaderMenuLink key={index + (link.item?.text ?? '')} {...link} />
      ))}
    </div>
  )
}

export default memo(HeaderLinks)
