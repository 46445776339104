import { TUsdmIncomeHistory } from '@tigertrade/binance-ts'
import { HistoryTransactionsPayload } from '@/backend/api'
import { ByBitTransactionHistory } from '@/services/bybit'
import { AccountBill } from '@/services/okx'
import { Statement } from '@/services/tigerx/types'

export const HISTORY_OKX_DEFAULT_LIMIT: string = '20'
export const HISTORY_BYBIT_DEFAULT_LIMIT: string = '20'
export const HISTORY_BINANCE_DEFAULT_LIMIT: number = 20
export const HISTORY_TIGERX_DEFAULT_LIMIT: string = '20'
export const HISTORY_OKX_COLUMNS_TO_OMIT: Array<keyof AccountBill> = ['from', 'to']
export const HISTORY_BYBIT_COLUMNS_TO_OMIT: Array<keyof ByBitTransactionHistory> = []
export const HISTORY_TIGERX_COLUMNS_TO_OMIT: Array<keyof Statement> = []

export const HISTORY_BINANCE_COLUMNS_TO_OMIT: Array<keyof TUsdmIncomeHistory> = []
export const HISTORY_OKX_COLUMNS_TO_SHOW: Array<keyof AccountBill> = [
  'ts',
  'type',
  'subType',
  'instId',
  'ccy',
  'balChg',
  'bal',
]
export const HISTORY_BYBIT_COLUMNS_TO_SHOW: Array<keyof ByBitTransactionHistory> = [
  'transactionTime',
  'type',
  'symbol',
  'currency',
  'change',
  'cashBalance',
]
export const HISTORY_BINANCE_COLUMNS_TO_SHOW: Array<keyof TUsdmIncomeHistory> = [
  'time',
  'incomeType',
  'asset',
  'symbol',
  'income',
  'info',
  'tranId',
  'tradeId',
]
export const HISTORY_TIGERX_COLUMNS_TO_SHOW: Array<keyof Statement> = [
  'createAt',
  'businessType',
  'exchangeType',
  'tradingFeeCoin',
  'sym',
  'rpnl',
  'side',
]
export const HISTORY_DECIMALS_OUTPUT = 6

export const HISTORY_TRANSACTIONS_SIZE = 20
export const HISTORY_TRANSACTIONS_SORTING: HistoryTransactionsPayload['sortParams'] = {
  direction: 'desc',
  sortBy: 'time',
}
