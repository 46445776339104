import { SVG } from '@/assets/svg'
import { TExchangesAvailable } from '@/core/constants'
import { Dropdown } from '@/ui/kit'
import { DropDownItem } from '@/ui/kit/Dropdown/Dropdown'
import { FC, Fragment, useCallback, useState } from 'react'
import style from './style.module.scss'
import { useActions } from '@/utils'
import { appActions } from '@/redux/app/app.slice'
import { useExchangesSource } from '@/utils/hooks/useExchangesSource'
import { exchangeFactory } from '@/exchanges/exchangeFactory'
import { useActiveExchage } from '@/utils/hooks/useActiveExchange'

type TOptionExchange = DropDownItem<TExchangesAvailable>

export const HeaderExchange: FC = () => {
  const { activeExchange } = useActiveExchage()

  const { setExchangeType, setActiveExchange } = useActions(appActions)

  const options = useExchangesSource()

  const [selectedOption, setSelectedOption] = useState<TOptionExchange | null>(
    options.find(option => {
      return option.value === activeExchange.type
    }) || null
  )
  const handleActiveExchange = useCallback((value: TOptionExchange) => {
    setSelectedOption(value)
    setExchangeType(value.value)
    setActiveExchange(exchangeFactory[value.value])
  }, [])

  return (
    <Fragment>
      <SVG.Additional.Union className={style.union} />
      <Dropdown
        options={options}
        selectedOption={selectedOption}
        setSelectedOption={handleActiveExchange}
        position="center"
        showSelectedItemIcon
      />
    </Fragment>
  )
}
