import { SVG } from '@/assets/svg'
import { IconFactory } from '@/assets/svg/cryptoIcons/IconFactory'
import { AccountOverviewAssets } from '@/backend/models/OverviewDTO'
import { overviewActions } from '@/redux/overview/overview.slice'
import { urls } from '@/router/urls'
import { ButtonVariant, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import { formatNumber, useActions, useAppSelector, useMobileSizeDetect } from '@/utils'
import { formatDate } from '@/utils/lib/formatDate'
import { t } from '@lingui/macro'
import clsx from 'clsx'
import { FC, memo, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ASSET_ITEM_MODAL_VIEWS, SoldCoin } from './AssetItem.types'

import style from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'

type ContentPropsType = {
  asset: AccountOverviewAssets
  open: boolean
  onClose: () => void
  symbol: string
  notional: number
  equivalentValue: number
  maximumSellingValue: number
  isNotEnoughCoin: boolean
  quantity: number
  minCoin?: number
}

const AssetItemSaleModal: FC<ContentPropsType> = ({
  asset,
  open,
  onClose,
  symbol,
  notional,
  equivalentValue,
  maximumSellingValue,
  isNotEnoughCoin,
  quantity,
  minCoin,
}) => {
  const { sellAssetErrorCode, statusSellAsset, accountType } = useAppSelector(state => state.overview)
  const [isMobile] = useMobileSizeDetect()
  const { SellAsset, setSellAssetStatusReset } = useActions(overviewActions)
  const { symData } = useAppSelector(state => state.tigerX)
  const [modalState, setModalState] = useState<ASSET_ITEM_MODAL_VIEWS>(ASSET_ITEM_MODAL_VIEWS.NOT_ENOUGH)
  const [soldCoin, setSoldCoin] = useState<SoldCoin>({ date: formatDate(new Date()), value: 0 })
  const handleSellAsset = () => {
    if (accountType) {
      setSoldCoin({ date: formatDate(new Date()), value: maximumSellingValue })
      if (accountType === 'TIGER_X_OKX' || accountType === 'TIGER_X_BINANCE') {
        const symbols = symData && Object.values(symData as any)
        const accountTypeSymbol = symbols?.filter((sym: any) =>
          sym.sym.includes(accountType === 'TIGER_X_OKX' ? 'OKX' : 'BINANCE')
        )
        const findTigerSymbol: any = accountTypeSymbol
          ?.filter((sym: any) => sym.originalSymbol === symbol)
          .find((sym: any) => sym.sym.includes('SPOT'))
        SellAsset({
          accountType,
          symbol: findTigerSymbol?.sym ?? '',
          quantity,
        })
      } else {
        SellAsset({
          accountType,
          symbol,
          quantity,
        })
      }
    }
  }

  const handleClose = () => {
    setSellAssetStatusReset()
    onClose()
  }

  useEffect(() => {
    if (statusSellAsset === 'idle') {
      setModalState(ASSET_ITEM_MODAL_VIEWS.DEFAULT)
    }
    if (isNotEnoughCoin && statusSellAsset !== 'succeeded') {
      setModalState(ASSET_ITEM_MODAL_VIEWS.NOT_ENOUGH)
    }
    if (statusSellAsset === 'succeeded') {
      setModalState(ASSET_ITEM_MODAL_VIEWS.SUCCEEDED)
    }
    if (statusSellAsset === 'failed') {
      setModalState(ASSET_ITEM_MODAL_VIEWS.ERROR)
    }
  }, [statusSellAsset, isNotEnoughCoin])

  useEffect(() => {
    return () => {
      setSellAssetStatusReset()
    }
  }, [])

  return (
    <InteractiveModal className={style.modal} isOpen={open} onClose={handleClose}>
      <InteractiveModalParts.HeaderIcon>
        {modalState === ASSET_ITEM_MODAL_VIEWS.SUCCEEDED || modalState === ASSET_ITEM_MODAL_VIEWS.ERROR ? (
          <SVG.OtherIcons.Sell />
        ) : (
          <IconFactory type={asset.asset} />
        )}
      </InteractiveModalParts.HeaderIcon>

      {modalState === ASSET_ITEM_MODAL_VIEWS.NOT_ENOUGH && (
        <>
          <InteractiveModalParts.MainInfo
            text={`
              ${t({
                id: 'asset.notEnoughCoins',
                comment: 'Недостаточно монет',
              })} ${asset.asset}
            `}
          />

          <InteractiveModalParts.Explanation
            text={
              <>
                <span className={style.gradientText}>
                  {t({
                    id: 'assets.minSum',
                    comment: 'Минимальная сумма',
                  })}{' '}
                </span>
                {accountType === 'TIGER_X_OKX' && (
                  <span className={style.gradientText}>
                    {' '}
                    {formatNumber(minCoin)} {asset.asset}
                  </span>
                )}{' '}
                {accountType !== 'TIGER_X_OKX' && (
                  <span className={style.explanationText}>
                    {t({
                      id: 'assets.usdtEq',
                      comment: 'в USDT эквиваленте',
                    })}
                  </span>
                )}
                {accountType !== 'TIGER_X_OKX' && (
                  <span className={style.gradientText}> {formatNumber(notional)} USDT</span>
                )}
              </>
            }
          />

          {accountType !== 'OKX_TRADING' &&
            accountType !== 'BYBIT_UNIFIED' &&
            accountType !== 'TIGER_X_BINANCE' &&
            accountType !== 'TIGER_X_OKX' && (
              <InteractiveModalParts.Explanation
                text={
                  <>
                    <span className={style.explanationText}>
                      {t({
                        id: 'assets.smallBalanceDescription',
                        comment: 'В настройках Вы можете включить',
                      })}
                    </span>{' '}
                    {isMobile ? (
                      <p className={style.gradientText}>
                        {t({
                          id: 'assets.smallBalanceConvertLink',
                          comment: 'конвертацию маленького баланса в',
                        })}{' '}
                        BNB
                      </p>
                    ) : (
                      <Link to={urls.profile} className={style.linkToProfile}>
                        <p className={style.gradientText}>
                          {t({
                            id: 'assets.smallBalanceConvertLink',
                            comment: 'конвертацию маленького баланса в',
                          })}{' '}
                          BNB
                        </p>
                      </Link>
                    )}
                  </>
                }
              />
            )}
        </>
      )}

      {modalState === ASSET_ITEM_MODAL_VIEWS.SUCCEEDED && (
        <>
          <InteractiveModalParts.SubHeader
            text={<span className={style.explanationText}>{t({ id: 'core.sell', comment: 'Sell' })}</span>}
          />
          <InteractiveModalParts.MainInfo text={`${soldCoin.value} ${asset.asset}`} />
          <InteractiveModalParts.Status status={'succeeded'} date={soldCoin.date} />
          <InteractiveModalParts.Table
            left={t({ id: 'core.transaction.from', comment: 'From' })}
            right={t({ id: 'core.spot', comment: 'Spot' })}
          />
        </>
      )}

      {modalState === ASSET_ITEM_MODAL_VIEWS.ERROR && (
        <>
          <InteractiveModalParts.SubHeader
            text={<span className={style.explanationText}>{t({ id: 'core.sell', comment: 'Sell' })}</span>}
          />
          <InteractiveModalParts.MainInfo text={`${soldCoin.value} ${asset.asset}`} />
          <InteractiveModalParts.Status status={'failed'} date={soldCoin.date} />
          <InteractiveModalParts.Explanation
            className={style.explanationText}
            text={
              <span className={style.explanationText}>
                {t({ id: 'assets.sellAssetFailed', comment: 'Not meet specification.' })} {sellAssetErrorCode}
              </span>
            }
          />
        </>
      )}

      {modalState === ASSET_ITEM_MODAL_VIEWS.DEFAULT && (
        <>
          <InteractiveModalParts.MainInfo
            text={`${t({ id: 'assets.areYouSureYouWantToSell', comment: 'Вы точно хотите продать' })} ${asset.asset}?`}
          />
          <InteractiveModalParts.Explanation
            text={
              <>
                <span className={style.gradientText}>
                  {formatNumber(maximumSellingValue)} {asset.asset}{' '}
                </span>
                <span className={style.explanationText}>
                  {t({
                    id: 'assets.sellDescription',
                    comment: 'будет продано рыночной заявкой после чего вы получите',
                  })}
                </span>
                <span className={style.gradientText}> ~ {formatNumber(equivalentValue)} USDT</span>
              </>
            }
          />

          <InteractiveModalParts.Button
            text={t({ id: 'core.sell', comment: 'Sell' })}
            variant={ButtonVariant.White}
            isDisabled={statusSellAsset === 'loading'}
            onClick={handleSellAsset}
            dataTestId={DataTestIds.SellAssetPopupSell}
          />
        </>
      )}

      <InteractiveModalParts.Button
        text={
          modalState === ASSET_ITEM_MODAL_VIEWS.SUCCEEDED
            ? t({
                id: 'core.continue',
                comment: 'Continue',
              })
            : t({ comment: 'Cancel', id: 'core.cancel' })
        }
        variant={modalState === ASSET_ITEM_MODAL_VIEWS.SUCCEEDED ? ButtonVariant.White : ButtonVariant.Clean}
        onClick={handleClose}
        className={clsx(modalState !== ASSET_ITEM_MODAL_VIEWS.SUCCEEDED && style.cancelButton)}
        dataTestId={DataTestIds.SellAssetPopupContinueCancel}
      />
    </InteractiveModal>
  )
}

export default memo(AssetItemSaleModal)
