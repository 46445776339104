export const normalizeBalance = (value: number | string, decimals = 8): string => {
  if (value === null || value === undefined) return '0'

  const num = typeof value === 'string' ? Number(value) : value
  if (isNaN(num) || !isFinite(num)) return '0'

  const factor = Math.pow(10, decimals)
  const truncated = Math.floor(num * factor) / factor

  return truncated.toFixed(decimals)
}
