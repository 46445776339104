import { useExchangeApi } from '@/exchanges/hooks/useExchangeApi'
import { useEffect, useState } from 'react'
import { TransferForm } from './TransferForm/TransferForm'
import { TransactionWrapper } from '../TransactionWrapper/TransactionWrapper'
import { AccountConfigItem, OptionType } from './TransferForm/types'
import { t } from '@lingui/macro'
import { useActiveExchage } from '@/utils/hooks/useActiveExchange'
import { useNavigate } from 'react-router-dom'
import { urls } from '@/router/urls'
import { useAppSelector } from '@/utils'

export type TransferData = {
  options: OptionType[]
  accountConfig: Record<string, AccountConfigItem>
  accountFrom: string
  accountTo: string
  maxDecimal: number
}

export function TransferPage() {
  const navigate = useNavigate()
  const exchangeApi = useExchangeApi()
  const { activeExchange } = useActiveExchage()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [errorCode, setErrorCode] = useState<number | null>(null)
  const [transferData, setTransferData] = useState<TransferData | null>(null)

  const { userKYC } = useAppSelector(state => state.kyc)

  const fetchTransferData = async () => {
    if (!exchangeApi?.getTransferData) {
      setError('Front API is not available')
      setErrorCode(590) // Фронтовая ошибка API
      return
    }

    setLoading(true)
    setError(null)
    setErrorCode(null)

    try {
      const data = await exchangeApi.getTransferData()

      if (!data) {
        throw new Error('No transfer data has been received')
      }

      if (data.error) {
        setErrorCode(data.error.status) // Сохраняем код ошибки
        throw new Error(`${data.error.source}: ${data.error.message} (code: ${data.error.status})`)
      }

      setTransferData(data)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Unknown error')
    } finally {
      setLoading(false)
    }
  }

  //delete when refactor P2-588
  useEffect(() => {
    if (userKYC.kycTier === 'UNVERIFIED' || userKYC.kycTier === 'BASIC') {
      navigate(urls.wallet)
    }
  }, [userKYC.kycTier])

  useEffect(() => {
    if (!transferData) {
      fetchTransferData()
    }
  }, [transferData])

  useEffect(() => {
    if (!activeExchange.transactions.transfer.isAvailable) {
      navigate(urls.wallet)
    }
  }, [activeExchange.transactions.transfer.isAvailable])

  return (
    <TransactionWrapper
      title={t({ message: `Transfer`, id: 'core.transfer' })}
      loading={loading}
      error={error}
      errorCode={errorCode}
      onRetry={fetchTransferData}
    >
      {transferData && <TransferForm transferData={transferData} />}
    </TransactionWrapper>
  )
}
