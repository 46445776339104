import { FC, memo } from 'react'
import { floorNumber, useAppSelector } from '@/utils'
import { TitleH2 } from '@/ui/atoms/TitleH2'
import { useOverview } from '@/utils/hooks/useOverview'
import s from './style.module.scss'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { ETigerXInstruments } from '@/exchanges/tigerx/tigerx.config'
import { isExchangeTigerX } from '@/utils/lib/exchange'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { formatUpnlValue, getFilteredAccountData } from '@/ui/organisms/OrdersPageWidgetTigerX/utils'

const MainTabBalance: FC = () => {
  const {
    balanceByInstrument: { accountBalance, symbol },
  } = useOverview()
  const { instrumentType } = useAppSelector(state => state.overview)
  const { account } = useAppSelector(state => state.tigerX)
  const { exchangeType } = useExchageType()
  const filteredData = getFilteredAccountData(account, instrumentType)
  const balanceUsdt = Number(filteredData?.equity ?? 0) - Number(filteredData?.upnl ?? 0)

  return (
    <div className={s.root} data-testid={DataTestIds.WalletEstimatedBalance}>
      <TitleH2
        label={isExchangeTigerX(exchangeType) ? formatUpnlValue(balanceUsdt.toFixed(2)) : floorNumber(accountBalance)}
      />
      <div className={s.currency}>{symbol}</div>
    </div>
  )
}

export default memo(MainTabBalance)
