import { useState, useEffect, useRef, ReactNode } from 'react'
import styles from './style.module.scss'
import { SVG } from '@/assets/svg'
import { Trans } from '@lingui/react'
import { t } from '@lingui/macro'
import clsx from 'clsx'
import { isInvalidPattern } from './utils'
import { TitleH4 } from '@/ui/atoms/TitleH4'
import { Hint, usePopperRef } from '@/ui/kit'

interface PinInputProps {
  value: string
  onChange: (pin: string) => void
  setHasError?: React.Dispatch<React.SetStateAction<boolean>>
  helpText?: string
  title?: string
  attemptsCounter?: number
  maxAttempts?: number
  errorMessage?: string | null
  disabled?: boolean
  checkPatternPincode?: boolean
}

export const PinInput: React.FC<PinInputProps> = ({
  title,
  value,
  onChange,
  setHasError,
  helpText,
  attemptsCounter,
  errorMessage,
  maxAttempts,
  disabled,
  checkPatternPincode,
}) => {
  const [pin, setPin] = useState<string[]>(Array(6).fill(''))
  const [error, setError] = useState<ReactNode | string>('')
  const inputRefs = useRef<(HTMLInputElement | null)[]>([])
  const [lenghtError, setLengtError] = useState(false)
  const [hintRef, setHintRef] = usePopperRef()

  useEffect(() => {
    if (value !== pin.join('')) {
      const newPin = value.padEnd(6, '').split('').slice(0, 6)
      setPin(newPin)
    }
  }, [value])

  const handleChange = (index: number, digit: string) => {
    if (!/^\d$/.test(digit)) return // ⛔️ игнорим всё, что не цифра

    const newPin = [...pin]

    const firstEmptyIndex = newPin.findIndex(d => d === '')

    if (firstEmptyIndex !== -1 && firstEmptyIndex !== index) {
      index = firstEmptyIndex
    }

    newPin[index] = digit

    setPin(newPin)
    onChange(newPin.join(''))

    const nextEmptyIndex = newPin.findIndex(d => d === '')

    if (nextEmptyIndex !== -1) {
      inputRefs.current[nextEmptyIndex]?.focus()
    }

    validatePin(newPin.join(''))
  }

  const validatePin = (pinString: string) => {
    // if (!/^\d*$/.test(pinString)) {
    //   setError(
    //     t({
    //       id: 'core.pinInput.error.onlyNumbers',
    //     })
    //   )
    //   setLengtError(false)
    //   setHasError?.(true)
    //   return false
    // }

    // if (pinString.length !== 6) {
    //   setLengtError(false)
    //   setError(
    //     t({
    //       id: 'core.pinInput.error.length',
    //     })
    //   )
    //   setHasError?.(true)
    //   return false
    // }

    if (checkPatternPincode && isInvalidPattern(pinString) && pinString.length === 6) {
      setLengtError(true)
      setError(<Trans id="core.pinInput.error.subsequence" components={{ br: <br /> }} />)
      setHasError?.(true)
      return false
    }

    setError('')
    setLengtError(false)
    setHasError?.(false)
    return true
  }

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      e.preventDefault()

      const newPin = [...pin]

      const lastFilledIndex = newPin
        .map((d, i) => (d !== '' ? i : -1))
        .lastIndexOf(Math.max(...newPin.map((d, i) => (d !== '' ? i : -1))))

      if (lastFilledIndex !== -1) {
        newPin[lastFilledIndex] = ''
        setPin(newPin)
        onChange(newPin.join(''))

        const allEmpty = newPin.every(d => d === '')

        if (!allEmpty) {
          const newLastFilledIndex = newPin
            .map((d, i) => (d !== '' ? i : -1))
            .lastIndexOf(Math.max(...newPin.map((d, i) => (d !== '' ? i : -1))))

          inputRefs.current[newLastFilledIndex !== -1 ? newLastFilledIndex + 1 : 0]?.focus()
        } else {
          inputRefs.current[0]?.focus()
        }
      }
    }
  }

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    const pasteData = e.clipboardData.getData('text').replace(/\D/g, '').slice(0, 6)
    if (!pasteData) return

    const newPin = [...pin]
    let pasteIndex = newPin.findIndex(digit => digit === '')

    if (pasteIndex === -1) return

    for (let i = 0; i < pasteData.length && pasteIndex < 6; i++, pasteIndex++) {
      newPin[pasteIndex] = pasteData[i]
    }

    setPin(newPin)
    onChange(newPin.join(''))

    const nextEmptyIndex = newPin.findIndex(digit => digit === '')

    if (nextEmptyIndex !== -1) {
      inputRefs.current[nextEmptyIndex]?.focus()
    } else {
      inputRefs.current.forEach(input => input?.blur())
    }
  }

  return (
    <div className={styles.container}>
      {title && <TitleH4 label={title} />}
      {disabled && <div className={styles.limitPincode}>{t({ id: 'profile.pinCode.limithasBeenReached' })}</div>}
      {attemptsCounter !== undefined && !disabled && (
        <div className={styles.limitPincode}>
          {t({ id: 'profile.pinCode.limitAvailable' })} <span className={styles.counter}> {attemptsCounter}</span>/
          {maxAttempts}
        </div>
      )}
      {errorMessage && <Hint placement="right" text={errorMessage} variant="error" targetRef={hintRef} />}
      <div
        className={clsx(styles.inputContainer, {
          [styles.disabled]: disabled,
        })}
        ref={setHintRef}
      >
        {pin.map((digit, index) => (
          <input
            key={index}
            type="text"
            value={digit ? '•' : ''}
            maxLength={1}
            onChange={e => handleChange(index, e.target.value)}
            onKeyDown={e => handleKeyDown(index, e)}
            onPaste={handlePaste}
            disabled={disabled}
            ref={el => (inputRefs.current[index] = el)}
            className={clsx(
              styles.inputBox,
              error && styles.inputError,
              index === 3 && styles.extraSpacing //  стиль только для 4 элемента
            )}
          />
        ))}
      </div>
      {error && (
        <div
          className={clsx(styles.errorText, {
            [styles.errorLengthText]: lenghtError,
          })}
        >
          <SVG.Status.Failed />
          <div>{error}</div>
        </div>
      )}
      {helpText && !error && <div className={styles.infoText}>{helpText}</div>}
    </div>
  )
}
