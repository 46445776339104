import { FC } from 'react'

type SuccessProps = {
  className?: string
}
export const Success: FC<SuccessProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" className={className}>
    <path
      fill="#1ACC81"
      d="M13.492 1.667H6.508c-3.033 0-4.841 1.808-4.841 4.841v6.975c0 3.042 1.808 4.85 4.841 4.85h6.975c3.034 0 4.842-1.808 4.842-4.841V6.508c.008-3.033-1.8-4.841-4.833-4.841Zm.491 6.416-4.725 4.725a.624.624 0 0 1-.883 0L6.017 10.45a.629.629 0 0 1 0-.883.629.629 0 0 1 .883 0l1.917 1.916L13.1 7.2a.629.629 0 0 1 .883 0 .629.629 0 0 1 0 .883Z"
    />
  </svg>
)
