import { FC } from 'react'

type ExportType = {
  className?: string
}

export const Export: FC<ExportType> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" className={className}>
    <rect width={40} height={40} fill="#1c1a24" rx={10} />
    <path
      stroke="#FAFAFA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M23.2 11a2 2 0 0 1 1.4.6l3.8 3.8a2 2 0 0 1 .6 1.4V27a2 2 0 0 1-2 2H13a2 2 0 0 1-2-2V13a2 2 0 0 1 2-2h10.2Z"
    />
    <path
      stroke="#FAFAFA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M25 29v-7a1 1 0 0 0-1-1h-8a1 1 0 0 0-1 1v7M15 11v4a1 1 0 0 0 1 1h7"
    />
  </svg>
)
