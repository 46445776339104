import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { v1 } from 'uuid'
import { SVG } from '@/assets/svg'
import { transactionsAsyncActions } from '@/redux/transactions/transactions.actions'
import { transactionsActions } from '@/redux/transactions/transactions.slice'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import {
  checkIsDecimalsQuantityCorrect,
  getCompoundTransactionAmount,
  useActions,
  useAppDispatch,
  useAppSelector,
} from '@/utils'
import { Button, ButtonSize, ButtonVariant, ErrorView, Input, InputSize, InteractiveModalParts, Select } from '@/ui/kit'
import { NoCoins } from '@/ui/molecules'
import clsx from 'clsx'
import styles from './styles.module.scss'
import { t } from '@lingui/macro'
import { NetworkSelectOption } from '@/ui/atoms/NetworkSelectOption'
import { CoinSelectOption } from '@/ui/atoms/CoinSelectOption'
import { useScrollToError } from '@/utils/hooks'
import { formatAmountTrimmed, getTransferableAssets, trimDecimals } from './helpers'
import { TransferOption } from './types'
import { useActiveExchage } from '@/utils/hooks/useActiveExchange'
import { TransferData } from '../TransferPage'
import { urls } from '@/router/urls'
import { useNavigate } from 'react-router-dom'
import { formatTransactionValue, removeTransactionFormat } from '../../helpers'

type Props = {
  transferData: TransferData
}

export const TransferForm = memo(({ transferData }: Props) => {
  const dispatch = useAppDispatch()
  const navigator = useNavigate()
  const { activeExchange } = useActiveExchage()

  const { TransferAssetCryptoTC, TransferAssetTC } = useActions(transactionsAsyncActions)
  const { assetsForTransactions, transactionSendResult } = useAppSelector(state => state.transactions)

  const [accountFrom, setAccountFrom] = useState(transferData.accountFrom)
  const [accountTo, setAccountTo] = useState(transferData.accountTo)
  const [selectedAsset, setSelectedAsset] = useState<null | any>(null)
  const [selectedAccount, setSelectedAccount] = useState(transferData.accountConfig[accountFrom])
  const [amount, setAmount] = useState<string>('')
  const [amountFormatted, setAmountFormatted] = useState<string>('')
  const [transferStep, setTransferStep] = useState(1)

  //// step 1 ////
  const options = useMemo<TransferOption[]>(() => {
    return transferData.options.map((item: any) => {
      const amount = formatAmountTrimmed(item.amount, transferData.maxDecimal)
      const label = `${amount} ${item.symbol ? item.symbol : ''}`

      return {
        value: item.value,
        label: <NetworkSelectOption symbol={label} name={item.name} />,
        selected: item.name,
        type: item.type,
      }
    })
  }, [transferData.options])

  const optionsForAccountTo = useMemo(() => {
    const selectedFrom = options.find(o => o.value === accountFrom)

    return options.filter(opt => {
      if (selectedFrom?.type === 'FUTURES') {
        return opt.type === 'SPOT' // Если `accountFrom` = FUTURES, показываем только SPOT
      }
      return true // Если `accountFrom` = SPOT, показываем всё
    })
  }, [accountFrom, accountTo, options])

  const MIN_BALANCE = 1 / Math.pow(10, transferData.maxDecimal)

  const allowCoins = useMemo(() => {
    // проверяем что переводим на спот
    const isTransferToSpot = accountTo.includes('SPOT')

    // если перевод на фьюч то сравниваем ассеты которые можно перевести
    const transferableAssets = isTransferToSpot
      ? transferData.accountConfig[accountFrom]?.coins || []
      : getTransferableAssets(accountFrom, accountTo, transferData.accountConfig)

    return transferableAssets
      .filter(item => {
        const balanceNum = Number(item?.balance)
        return !isNaN(balanceNum) && balanceNum > MIN_BALANCE
      })
      .map(item => {
        const balanceNum = Number(item.balance)
        const balance = trimDecimals(balanceNum, transferData.maxDecimal)
        const balanceFormatted = balance.toFixed(transferData.maxDecimal)

        return {
          ...item,
          value: item.asset,
          label: <CoinSelectOption asset={item.asset} balance={balanceFormatted} />,
          selected: <CoinSelectOption asset={item.asset} large />,
        }
      })
  }, [accountFrom, accountTo, transferData.accountConfig, transferData.maxDecimal])

  useEffect(() => {
    setSelectedAccount(transferData.accountConfig[accountFrom])
  }, [accountFrom])

  const handleChangeAccountFrom = (newAccountFrom: string) => {
    setAccountFrom(prev => {
      if (newAccountFrom === accountTo) {
        setAccountTo(prev)
        setSelectedAsset(null)
        return accountTo
      }
      const selectedNewFrom = options.find(o => o.value === newAccountFrom)
      const selectedTo = options.find(o => o.value === accountTo)

      // Если `accountFrom` стал `FUTURES`, но `accountTo` тоже `FUTURES`, меняем `accountTo` на `SPOT`
      if (selectedNewFrom?.type === 'FUTURES' && selectedTo?.type === 'FUTURES') {
        const spotAccount = options.find(o => o.type === 'SPOT')
        if (spotAccount) {
          setAccountTo(spotAccount.value)
        }
      }

      return newAccountFrom
    })

    setSelectedAsset(null)
    setAmount('')
    setAmountFormatted('')
  }

  const handleChangeAccountTo = (newAccountTo: string) => {
    setAccountTo(prev => {
      if (newAccountTo === accountFrom) {
        setAccountFrom(prev)
        return accountFrom
      }

      return newAccountTo
    })

    setSelectedAsset(null)
    setAmount('')
    setAmountFormatted('')
  }

  const toggleAccounts = useCallback(() => {
    setAccountFrom(accountTo)
    setAccountTo(accountFrom)
    setAmount('')
    setAmountFormatted('')
    setSelectedAsset(null)
  }, [accountFrom, accountTo])

  function changeSelectedAsset(value: string) {
    const asset = selectedAccount.coins.find(item => item.asset === value)

    if (asset) {
      setSelectedAsset(asset)
      setAmount('')
      setAmountFormatted('')
    }
  }

  function handleSetMax() {
    if (!selectedAsset) return
    const maxFormattedAmount = formatTransactionValue(selectedAsset.balance.toString(), transferData.maxDecimal)

    setAmountFormatted(maxFormattedAmount)
    setAmount(removeTransactionFormat(maxFormattedAmount))
  }

  const isDisabledTransfer =
    selectedAsset === null ||
    selectedAsset.transferFee === null ||
    selectedAsset.transferMin === null ||
    amount < selectedAsset.transferMin ||
    isNaN(Number(amount)) ||
    Number(amount) <= 0 ||
    Number(amount) > selectedAsset.balance

  const handleChange = (value: string) => {
    const clearValue = removeTransactionFormat(value, transferData.maxDecimal) // Очищенное значение без запятых
    const formattedValue = formatTransactionValue(clearValue, transferData.maxDecimal) // Форматируем для UI

    setAmount(clearValue)
    setAmountFormatted(formattedValue)
  }

  const scrollToError = useScrollToError()

  const [transactionId, setTransactionId] = useState('')
  const [isTransferAttemptDetected, setIsTransferAttemptDetected] = useState(false)

  const getErrorMessage = () => {
    if (accountFrom === accountTo) {
      return t({ message: `Source and destination accounts are equal`, id: 'transferTransaction.messages.source' })
    }

    if (!selectedAsset?.asset) {
      return t({ message: `No coin selected`, id: 'transferTransaction.messages.noCoinsSelected' })
    }

    if (amount === '') {
      return t({ message: 'Enter amount', id: 'transferTransaction.messages.noCoinsSelect' })
    }

    if (Number(amount) <= 0) {
      return t({ message: `Incorrect amount`, id: 'transferTransaction.messages.inccorectAmount' })
    }

    if (Number(amount) > selectedAsset.balance) {
      return t({ message: 'Insufficient funds in the account', id: 'transferTransaction.messages.insufFunds' })
    }

    if (!checkIsDecimalsQuantityCorrect(amount, transferData.maxDecimal)) {
      return `${t({
        message: 'Only',
        id: 'transferTransaction.messages.onlyDec-1',
      })} ${transferData.maxDecimal} ${t({
        message: 'decimal places are allowed',
        id: 'transferTransaction.messages.onlyDec-2',
      })}`
    }

    return ''
  }

  const errorMessage = getErrorMessage()
  const isTransferAvailable = errorMessage === ''

  const transferAsset = useCallback(() => {
    setIsTransferAttemptDetected(true)

    if (!isTransferAvailable || selectedAsset === null) {
      scrollToError()
      return
    }

    const transactionId = v1()
    dispatch(transactionsActions.initTransaction(transactionId))
    setTransactionId(transactionId)

    // Обрезаем amount до N знаков после запятой
    const formattedAmount = trimDecimals(Number(amount), transferData.maxDecimal)

    switch (activeExchange.type) {
      case 'BINANCE_BROKER_FUTURE':
        TransferAssetTC({
          accountFrom,
          accountTo,
          amount: formattedAmount,
          asset: selectedAsset?.asset,
          transactionId,
        })
        break

      case 'TIGER_X':
        TransferAssetCryptoTC({
          fromSubExchange: transferData.accountConfig[accountFrom].account,
          toSubExchange: transferData.accountConfig[accountTo].account,
          amount: formattedAmount,
          coin: selectedAsset?.asset,
          transactionId,
        })
        break

      default:
        console.warn(`Неизвестный exchangeType: ${activeExchange.type}`)
        return
    }

    setTransferStep(2)
    scrollToError()
  }, [
    TransferAssetCryptoTC,
    accountFrom,
    accountTo,
    amount,
    dispatch,
    isTransferAvailable,
    selectedAsset,
    scrollToError,
  ])

  const fetchingError = assetsForTransactions.assets.errors?.[0]?.code
  const { status = 'loading', date } = transactionSendResult?.[transactionId] || {}

  if (fetchingError) {
    return <ErrorView code={fetchingError} />
  }

  return (
    <>
      {/* Step 1 */}
      {transferStep === 1 && (
        <div className={clsx(styles.transfer)}>
          <div className={styles.top}>
            <Select
              size={Select.Size.Large}
              options={options}
              label={t({ message: 'From', id: 'core.transaction.from' })}
              value={accountFrom}
              onChange={handleChangeAccountFrom}
              dataTestId={DataTestIds.TransferAccountTypeSectionFrom}
            />

            <Button
              variant={Button.Variant.Clean}
              onClick={toggleAccounts}
              className={styles.buttonWrap}
              leftIcon={<SVG.Actions.Transfer />}
              dataTestId={DataTestIds.TransferSwitchButton}
            />
            <Select
              size={Select.Size.Large}
              options={optionsForAccountTo}
              label={t({ message: 'To', id: 'core.transaction.to' })}
              value={accountTo}
              onChange={handleChangeAccountTo}
              dataTestId={DataTestIds.TransferAccountTypeSectionTo}
            />
          </div>

          <div className={clsx(styles.coins)}>
            <Input
              size={InputSize.Large}
              setValue={handleChange}
              value={amountFormatted}
              placeholder="0"
              dataTestId={DataTestIds.AmountInput}
              errorMessage={isTransferAttemptDetected ? errorMessage : ''}
              inputAttrs={{ type: 'text' }}
              containerClassName={styles.input}
            >
              {allowCoins && allowCoins.length ? (
                <div className={styles.inputAside}>
                  {selectedAsset && (
                    <Button.Primary
                      label="Max"
                      className={styles.maxBtn}
                      onClick={handleSetMax}
                      dataTestId={DataTestIds.AmountMaxButton}
                    />
                  )}
                  <Select
                    size={Select.Size.Medium}
                    onChange={changeSelectedAsset}
                    value={selectedAsset?.asset}
                    className={styles.coinSelect}
                    options={allowCoins as any}
                    variant={Select.Variant.Raw}
                    dataTestId={DataTestIds.CoinSelector}
                  />
                </div>
              ) : (
                <NoCoins />
              )}
            </Input>
          </div>
          {selectedAsset && (
            <div className={styles.assetWrap}>
              {selectedAsset?.balance != null && (
                <div className={styles.helpText}>
                  {t({ message: 'Available', id: 'core.transaction.available' })}
                  <span className={styles.available} data-testid={DataTestIds.AmountAvailableField}>
                    {formatTransactionValue(selectedAsset?.balance.toString(), transferData.maxDecimal)}{' '}
                    {selectedAsset.asset}
                  </span>
                </div>
              )}
              {selectedAsset?.transferMin != null && (
                <div className={styles.helpText}>
                  {t({ message: 'Transfer Min', id: 'core.transaction.transferMin' })}
                  <span className={styles.available} data-testid={DataTestIds.AmountAvailableField}>
                    {selectedAsset?.transferMin} {selectedAsset.asset}
                  </span>
                </div>
              )}
              <div className={styles.helpText}>
                {t({ message: 'Including network fee:', id: 'core.transaction.includingFee' })}
                <span className={styles.available} data-testid={DataTestIds.AmountAvailableField}>
                  {selectedAsset?.transferFee
                    ? `${selectedAsset?.transferFee} ${selectedAsset.asset}`
                    : t({ id: 'core.transaction.withoutCommission', message: `Without commission` })}
                </span>
              </div>
            </div>
          )}
          <Button.Accent
            size={ButtonSize.Large}
            onClick={transferAsset}
            label={t({ message: 'Transfer', id: 'core.transfer' })}
            disabled={isDisabledTransfer}
            dataTestId={DataTestIds.TransferConfirmButton}
            className={styles.actionButton}
          />
        </div>
      )}
      {/* Step 2 */}
      {transferStep === 2 && (
        <div className={styles.results}>
          <InteractiveModalParts.MainInfo
            text={getCompoundTransactionAmount(amount, selectedAsset?.asset)}
            className={styles.mainInfo}
          />
          {selectedAsset?.transferFee >= 0 && (
            <InteractiveModalParts.Explanation
              text={
                t({ id: 'core.transaction.includingFee', comment: 'Including network fee: ' }) +
                ` ${selectedAsset.transferFee}`
              }
              className={styles.explanation}
            />
          )}
          <InteractiveModalParts.Status
            status={status === 'loading' || status === 'succeeded' ? 'loading' : 'failed'}
            date={date}
            className={styles.statusAndDate}
          />
          <InteractiveModalParts.Table
            left={t({ message: 'From', id: 'core.transaction.from' })}
            right={transferData.accountConfig[accountFrom].name}
            dataTestId={DataTestIds.HistoryModalFrom}
            className={styles.tableItem}
          />
          <InteractiveModalParts.Table
            left={t({ message: 'To', id: 'core.transaction.to' })}
            right={transferData.accountConfig[accountTo].name}
            dataTestId={DataTestIds.HistoryModalAddrTo}
            className={styles.tableItem}
          />
          <br />
          <InteractiveModalParts.Button
            text={t({ message: 'Go back to main page', id: 'core.gobackMainPage' })}
            variant={ButtonVariant.White}
            onClick={() => navigator(urls.root)}
            dataTestId={DataTestIds.ModalReturnButton}
          />
        </div>
      )}
    </>
  )
})
