import { useCallback, useMemo } from 'react'
import { formatNumber, useAppSelector } from '@/utils'
import { AccountOverviewAssets } from '@/backend/models/OverviewDTO'
import { getMaximalSellingValue } from '@/utils/lib/getMaximalSellingValue'
import { OVERVIEW_ACCOUNT_SYMBOLS_MAP } from '@/redux/overview/overview.defaults'
import { truncateNumber } from '@/utils/lib/truncateNumber'
import BigNumber from 'bignumber.js'
import { useExchangesDisabled } from '@/utils/hooks/useExchangesDisabled'

export const useSellAsset = (asset: AccountOverviewAssets) => {
  const { accountTypeForInstrument, byBitInstruments, exchangeType, okxInstruments, accountType, exchangeInfo } =
    useAppSelector(state => state.overview)

  const { isSellAssetEnabled } = useExchangesDisabled()

  const assetFilters = exchangeInfo?.symbols.find(
    assetFilter => assetFilter.baseAsset === asset.asset && assetFilter.quoteAsset === 'USDT'
  )

  const assetFiltersInverse = exchangeInfo?.symbols.find(
    assetFilter => assetFilter.quoteAsset === asset.asset && assetFilter.baseAsset === 'USDT'
  )

  const stepSizeFilter =
    assetFilters?.filters.find(filter => filter.filterType === 'LOT_SIZE') ||
    assetFiltersInverse?.filters.find(filter => filter.filterType === 'LOT_SIZE')

  const notionalFilter =
    assetFilters?.filters.find(filter => filter.filterType === 'NOTIONAL') ||
    assetFiltersInverse?.filters.find(filter => filter.filterType === 'NOTIONAL')

  const stepSizeBinance = Number(stepSizeFilter?.filterType === 'LOT_SIZE' ? stepSizeFilter.stepSize : 0)
  const notionalBinance = Number(notionalFilter?.filterType === 'NOTIONAL' ? notionalFilter.minNotional : 0)

  const assetOKXFilter = okxInstruments?.find(
    instrument => instrument.baseCcy === asset.asset && instrument.quoteCcy === 'USDT'
  )
  const assetOKXFilterInverse = okxInstruments?.find(
    instrument => instrument.baseCcy === 'USDT' && instrument.quoteCcy === asset.asset
  )

  const assetByBitFilter = byBitInstruments?.find(
    instrument => instrument.baseCoin === asset.asset && instrument.quoteCoin === 'USDT'
  )
  const assetByBitFilterInverse = byBitInstruments?.find(
    instrument => instrument.baseCoin === 'USDT' && instrument.quoteCoin === asset.asset
  )

  const stepSizeOKX = Number(assetOKXFilter?.lotSz || assetOKXFilterInverse?.lotSz) || 0
  const notionalOKX = Number(assetOKXFilter?.minSz || assetOKXFilterInverse?.minSz) || 0

  const stepSizeByBit =
    Number(assetByBitFilter?.lotSizeFilter.basePrecision || assetByBitFilterInverse?.lotSizeFilter.basePrecision) || 0

  const notionalByBit =
    Number(assetByBitFilter?.lotSizeFilter.minOrderQty || assetByBitFilterInverse?.lotSizeFilter.minOrderAmt) || 0

  const getAssetStepSizeAndNotional = useCallback(
    (currency: number) => {
      switch (accountType) {
        case 'BINANCE_SPOT':
          return { stepSize: stepSizeBinance, notionalUSDT: notionalBinance, notionalCoin: '' }
        case 'BYBIT_UNIFIED':
          const notionalByBitUsdt = new BigNumber(notionalByBit).multipliedBy(currency).toNumber()
          return { stepSize: stepSizeByBit, notionalUSDT: notionalByBitUsdt, notionalCoin: notionalByBit }
        default:
          const notionalOKXUsdt = new BigNumber(notionalOKX).multipliedBy(currency).toNumber()
          return { stepSize: stepSizeOKX, notionalUSDT: notionalOKXUsdt, notionalCoin: notionalOKX }
      }
    },
    [accountType, notionalBinance, notionalByBit, notionalOKX, stepSizeBinance, stepSizeByBit, stepSizeOKX]
  )

  const canSellAsset = useMemo(() => {
    if (!isSellAssetEnabled) return false
    switch (accountType) {
      case 'BINANCE_SPOT':
        return (
          (assetFilters && assetFilters.status === 'TRADING') ||
          (!assetFilters && assetFiltersInverse && assetFiltersInverse.status === 'TRADING')
        )
      case 'BYBIT_UNIFIED':
        return (
          (assetByBitFilter && assetByBitFilter.status === 'Trading') ||
          (assetByBitFilterInverse && assetByBitFilterInverse.status === 'Trading')
        )
      case 'OKX_TRADING':
        return (
          (assetOKXFilter && assetOKXFilter.state === 'live') ||
          (assetOKXFilterInverse && assetOKXFilterInverse.state === 'live')
        )
    }
  }, [
    accountType,
    assetByBitFilter,
    assetByBitFilterInverse,
    assetFilters,
    assetFiltersInverse,
    assetOKXFilter,
    assetOKXFilterInverse,
    isSellAssetEnabled,
  ])

  const getAssetSymbol = useCallback(() => {
    switch (accountType) {
      case 'BINANCE_SPOT':
        return assetFilters?.symbol || assetFiltersInverse?.symbol || ''
      case 'BYBIT_UNIFIED':
        return assetByBitFilter?.symbol || assetByBitFilterInverse?.symbol || ''
      default:
        return assetOKXFilter?.instId || assetByBitFilterInverse?.symbol || ''
    }
  }, [accountType, assetByBitFilter, assetByBitFilterInverse, assetFilters, assetFiltersInverse, assetOKXFilter])

  const {
    equivalentValue,
    maximumSellingValue,
    assetPrice,
    stepSize,
    notional,
    isNotEnoughCoin,
    balance,
    balanceInAccountCoin,
    stableCoinSelector,
  } = useMemo(() => {
    if (accountTypeForInstrument === undefined)
      return {
        equivalentValue: 0,
        maximumSellingValue: 0,
        assetPrice: 0,
        notional: 0,
        stepSize: 0,
        isNotEnoughCoin: true,
        balance: '',
        balanceInAccountCoin: 0,
      }

    const balance = Number(asset.balance || 0)
    const balanceInAccountCoinNumber =
      asset.quoteBalance[OVERVIEW_ACCOUNT_SYMBOLS_MAP[accountTypeForInstrument].selectorOutput] || 0
    const stableCoinSelector = OVERVIEW_ACCOUNT_SYMBOLS_MAP[accountTypeForInstrument].selectorOutput
    const assetPrice = new BigNumber(balanceInAccountCoinNumber).dividedBy(balance).toNumber()

    const { stepSize, notionalUSDT, notionalCoin } = getAssetStepSizeAndNotional(assetPrice)

    const maximumSellingValue = getMaximalSellingValue(Number(asset.balance), stepSize)

    const isInverseCoin = assetFiltersInverse || assetOKXFilterInverse || assetByBitFilterInverse

    const equivalentValue = isInverseCoin
      ? getMaximalSellingValue(new BigNumber(maximumSellingValue).multipliedBy(assetPrice).toNumber(), stepSize)
      : truncateNumber(new BigNumber(maximumSellingValue * assetPrice).toNumber())

    const isNotEnoughCoin =
      accountType === 'OKX_TRADING' || accountType === 'BYBIT_UNIFIED'
        ? balance < notionalCoin
        : balanceInAccountCoinNumber < notionalUSDT

    return {
      equivalentValue,
      maximumSellingValue,
      assetPrice,
      notional: notionalUSDT,
      stepSize,
      isNotEnoughCoin,
      balance: formatNumber(balance),
      balanceInAccountCoin: new BigNumber(balanceInAccountCoinNumber).toString(),
      stableCoinSelector,
    }
  }, [accountTypeForInstrument, asset.balance, stepSizeBinance, assetFiltersInverse])

  const quantity = assetFiltersInverse
    ? getMaximalSellingValue(new BigNumber(maximumSellingValue).multipliedBy(assetPrice).toNumber(), stepSize)
    : maximumSellingValue

  const symbol = getAssetSymbol()

  return {
    equivalentValue,
    maximumSellingValue,
    notional,
    isNotEnoughCoin,
    quantity,
    symbol,
    canSellAsset,
    balance,
    balanceInAccountCoin,
    stableCoinSelector,
    minCoin: 0,
  }
}
