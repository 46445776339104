import { axiosMainService } from '@/services/axios'

// TODO: rename to service namge two-fa
const googleAuthAPIURL = '/two-fa/protected/api'

export const googleAuthAPI = {
  async verifyCode(payload: CodeVerification) {
    return await axiosMainService.post(`${googleAuthAPIURL}/v2/profile/google`, payload)
  },
  async activateTigerX() {
    return await axiosMainService.post(`${googleAuthAPIURL}/v1/tigerx/whitelist`)
  },
}

export type CodeVerification = {
  codes: {
    google: string
    sms?: string
  }
  enabled: boolean
}
