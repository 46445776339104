import { APIResponseV3WithTime, WalletBalanceV5 } from 'bybit-api'
import { tigerxRestService } from './rest'
import { Order } from './types'

// fallback for the time while account is not inited
export const getWalletBalanceWrap = async (params: {
  accountType: string
}): Promise<{ response: APIResponseV3WithTime<{ list?: WalletBalanceV5[] }>; status: number }> => {
  try {
    const response = await tigerxRestService.getWalletBalance(params)
    return { response: response.data, status: response.status }
  } catch {
    return {
      response: {
        result: {
          list: [],
        },
        retCode: 0,
        retMsg: '',
        time: 0,
      },
      status: 404,
    }
  }
}

export const getAlgoOrderPrice = (order: Order) => {
  //will be removed maybe
  if (!order) return

  return order.conditionalTriggerPrice ?? '0'
}
