import { Popup } from '@/ui/molecules'
import style from './style.module.scss'
import { FC, useState } from 'react'
import { SVG } from '@/assets/svg'
import { PinCodeInfo } from './components/PinCodeInfo/PinCodeInfo'
import { PinSet } from './components/PinSet/PinSet'
import clsx from 'clsx'
import { getPinHeaderTitle } from '../../utils'
import { PinCodeSuccess } from './components/PinCodeSuccess/PinCodeSuccess'
import { PinReset } from './components/PinReset/PinReset'

type PinCodeModal = {
  isOpen: boolean
  isPinEnable: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export enum PinScreenShow {
  SETUP = 'setup',
  ENTER_PIN = 'enter pin',
  SUCCESS = 'success',
  RESET = 'reset',
}

export type PinScreenShowType = {
  setPinScreenShow: React.Dispatch<React.SetStateAction<PinScreenShow>>
}

export const PinCodeModal: FC<PinCodeModal> = ({ isOpen, setIsOpen, isPinEnable }) => {
  const [pinScreenShow, setPinScreenShow] = useState<PinScreenShow>(
    isPinEnable ? PinScreenShow.RESET : PinScreenShow.SETUP
  )

  return (
    <Popup
      isOpen={isOpen}
      className={clsx(style.modal, {
        [style.modalMedium]: pinScreenShow !== PinScreenShow.SETUP,
      })}
    >
      <div className={style.container}>
        <div
          className={clsx(style.titleBlock, {
            [style.iconHeader]: pinScreenShow === PinScreenShow.SUCCESS,
            [style.titleReset]: pinScreenShow === PinScreenShow.RESET,
            [style.titleSetPin]: pinScreenShow === PinScreenShow.ENTER_PIN,
          })}
        >
          {pinScreenShow === PinScreenShow.SUCCESS ? (
            <SVG.Status.Completed className={style.iconSuccess} />
          ) : (
            <div className={style.headerTitle}>{getPinHeaderTitle(pinScreenShow)}</div>
          )}
          {pinScreenShow === PinScreenShow.SETUP && (
            <div className={style.closeBtn} onClick={() => setIsOpen(false)}>
              <SVG.Additional.Close width="10px" height="10px" />
            </div>
          )}
        </div>
        {pinScreenShow === PinScreenShow.SETUP && (
          <PinCodeInfo setPinScreenShow={setPinScreenShow} onClose={setIsOpen} />
        )}
        {pinScreenShow === PinScreenShow.ENTER_PIN && <PinSet setPinScreenShow={setPinScreenShow} />}
        {pinScreenShow === PinScreenShow.SUCCESS && (
          <PinCodeSuccess onClose={setIsOpen} setPinScreenShow={setPinScreenShow} />
        )}
        {pinScreenShow === PinScreenShow.RESET && <PinReset onClose={setIsOpen} setPinScreenShow={setPinScreenShow} />}
      </div>
    </Popup>
  )
}
