import styles from './style.module.scss'
import { ReactNode, memo } from 'react'
import { TitleH2 } from '@/ui/atoms/TitleH2'
import { Loader } from '@/ui/kit/Loader'
import { SVG } from '@/assets/svg'
import { Button, ButtonVariant } from '@/ui/kit'
import { t } from '@lingui/macro'

type Props = {
  children: ReactNode
  title: string
  loading?: boolean
  error?: string | null
  errorCode?: number | null
  onRetry?: () => void
}

export function TransactionWrapper(props: Props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.transactionWrap}>
        <div className={styles.header}>
          <TitleH2 label={props.title} />
        </div>
        <div className={styles.content}>
          {props.loading && (
            <div className={styles.loader}>
              <Loader />
            </div>
          )}
          {props.error && (
            <div className={styles.errorContainer}>
              <div className={styles.errorWrap}>
                <div>
                  <SVG.OtherIcons.Warning />
                </div>
                <span className={styles.errorTitle}> Error</span>
              </div>
              <p className={styles.error}>{props.error}</p>
              {typeof props.errorCode === 'number' && props.errorCode >= 500 && props.onRetry && (
                <Button
                  onClick={props.onRetry}
                  label={t({ id: 'core.retry', message: 'Retry' })}
                  variant={ButtonVariant.White}
                />
              )}
            </div>
          )}
          {!props.loading && !props.error && props.children}
        </div>
      </div>
    </div>
  )
}

export const Transfer = memo(TransactionWrapper)
