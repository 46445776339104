import { FC } from 'react'
import clsx from 'clsx'
import s from './style.module.scss'
import { useMobileSizeDetect } from '@/utils'

interface INetworkSelectOptionProps {
  symbol: string
  name?: string
  fee?: string
  large?: boolean
  className?: string
  disabled?: boolean
  dangerText?: string
}

const NetworkSelectOption: FC<INetworkSelectOptionProps> = props => {
  const { symbol, name, fee, large, className, disabled, dangerText } = props

  const [isMobile] = useMobileSizeDetect()

  return (
    <div className={clsx(s.root, { [s.large]: large })}>
      <div className={s.top}>
        <div className={clsx(s.symbol, { [s.disabled]: disabled }, className)}>{symbol}</div>
        {name && <div className={clsx(s.name)}>{name}</div>}
      </div>

      {fee && <div className={clsx(s.fee, { [s.disabled]: disabled && isMobile })}>{fee}</div>}
      {dangerText && <div className={clsx(s.dangerText, { [s.disabled]: disabled && isMobile })}>{dangerText}</div>}
    </div>
  )
}

export { NetworkSelectOption }
