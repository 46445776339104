const TigerXsmall = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none">
    <path
      fill="#FAFAFA"
      d="m11.575 6.441-6.598-4.47h14.18l-6.599 4.47a.876.876 0 0 1-.983 0ZM23.07 12.912v-3.86l-2.97 2.011c-.485.33-.776.88-.776 1.468v3.861l2.97-2.012c.485-.33.776-.88.776-1.468ZM12.692 17.787l4.218 4.242H7.23l4.218-4.242a.877.877 0 0 1 1.244 0ZM4.808 16.387v-3.86c0-.589-.29-1.14-.776-1.468L1.07 9.052v3.86c0 .589.291 1.14.777 1.468l2.961 2.007Z"
    />
    <path
      fill="#FAFAFA"
      d="M23.07 5.832v-3.86l-8.639 5.854a2.88 2.88 0 0 0-1.261 2.385v3.783c0 .47.185.92.515 1.252l3.445 3.464v-.49h-.002v-6.247c0-.734.365-1.421.973-1.833l4.193-2.84c.485-.329.776-.88.776-1.468ZM10.97 13.995c0 .47-.186.92-.516 1.251L7.01 18.71v-4.914l-.006-.004v-.719l.006.004v-1.103c0-.735-.364-1.42-.972-1.833l-4.191-2.84a1.773 1.773 0 0 1-.777-1.468V1.972l8.638 5.854a2.88 2.88 0 0 1 1.262 2.385v3.784Z"
    />
  </svg>
)
export { TigerXsmall }
