import { tigerxRest } from './tegerx.rest'
import { ETigerXAccount } from './tigerx.config'
import { tigerBackRest } from '../tigerBack/tigerBack.rest'
import { addBalancesToTransfers, groupTransfersByExchange } from './tigerx.mappers'
export interface ApiError {
  source: string
  status: number
  message: string
}

export const TigerXApi = {
  getTransferData: async () => {
    try {
      // Запускаем все запросы параллельно
      const [accountDataRes, assetsRes, balanceRes] = await Promise.all([
        tigerxRest.getAccountData().catch(err => {
          throw { source: 'accountData', status: err.response?.status || 500, message: err.message }
        }),
        tigerBackRest.getCoins().catch(err => {
          throw { source: 'assets', status: err.response?.status || 500, message: err.message }
        }),
        tigerBackRest.getCoinsBalance().catch(err => {
          throw { source: 'balance', status: err.response?.status || 500, message: err.message }
        }),
      ])

      const balances = accountDataRes.data.data.reduce((acc: Record<string, any>, item: any) => {
        acc[item.exchangeType] = {
          equity: item.equity,
        }
        return acc
      }, {})

      const groupedTransfers = groupTransfersByExchange(assetsRes.data)
      const balanceData = addBalancesToTransfers(groupedTransfers, balanceRes.data)

      const options = [
        {
          value: ETigerXAccount.TIGER_X_BINANCE,
          amount: balances['BINANCE'].equity,
          name: 'X Binance',
          coins: balanceData['BINANCE'],
        },
        {
          value: ETigerXAccount.TIGER_X_OKX,
          amount: balances['OKX'].equity,
          name: 'X OKX',
          coins: balanceData['OKX'],
        },
      ]

      const accountConfig = {
        TIGER_X_BINANCE: {
          name: 'X Binance',
          coins: balanceData['BINANCE'],
          account: 'BINANCE',
          type: 'SPOT',
        },
        TIGER_X_OKX: {
          name: 'X OKX',
          coins: balanceData['OKX'],
          account: 'OKX',
          type: 'SPOT',
        },
      }

      const transfer_data = {
        options: options,
        accountConfig: accountConfig,
        accountFrom: ETigerXAccount.TIGER_X_BINANCE,
        accountTo: ETigerXAccount.TIGER_X_OKX,
        maxDecimal: 6,
      }

      return transfer_data
    } catch (error: unknown) {
      const apiError = error as ApiError
      console.error('Error transfer data:', apiError)

      return {
        error: {
          source: apiError.source,
          status: apiError.status,
          message: apiError.message,
        },
      }
    }
  },
}
