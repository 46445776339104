import { TGetReferralEarnings } from '@/backend/models/ReferralsDTO'

export const referralEarningsMapper = (earnings: TGetReferralEarnings[]): TGetReferralEarnings[] => {
  return earnings?.map(
    ({
      partnerEarnings,
      tradingVolume,
      userId,
      userJoined,
      tradingSection,
      tradingCommission,
      tradingDay,
      awardClaimed,
    }) => ({
      userId: userId || '',
      tradingSection: tradingSection,
      partnerEarnings: partnerEarnings || 0,
      tradingDay: tradingDay || '',
      tradingCommission: tradingCommission || 0,
      userJoined: userJoined || '',
      tradingVolume: tradingVolume || 0,
      awardClaimed: awardClaimed || false,
    })
  )
}
