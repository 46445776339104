import { FC, useEffect, useState } from 'react'
import { t } from '@lingui/macro'
import { Button, ButtonVariant, Input, InputSize, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import style from '../../style.module.scss'
import { SVG } from '@/assets/svg'
import { useActions, useAppSelector, useToggle } from '@/utils'
import { leaderboardActions } from '@/redux/leaderboard/leaderboard.slice'

export const Nickname: FC = () => {
  const { GetTeamMemberTC, PutUpdateNicknameTC } = useActions(leaderboardActions)
  const { nickname, errors, isLoadingNickname } = useAppSelector(state => state.leaderboard)
  const [isOpenPopup, togglePopup] = useToggle(false)
  const [name, setName] = useState(nickname ? nickname : '')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  function handleChange(value: string) {
    setName(value)
    setErrorMessage(null)
  }

  function validateNickname(nickname: string): boolean {
    const nicknameRegex = /^[a-zA-Z0-9.]{1,100}$/
    return nicknameRegex.test(nickname)
  }

  function handlePopup() {
    setErrorMessage(null)
    togglePopup()
  }

  async function handleSubmit() {
    if (!name || !validateNickname(name)) {
      setErrorMessage(t({ id: 'profile.nickname.notValidName', comment: 'title' }))
      return
    }

    await PutUpdateNicknameTC({ newUsername: name })
  }

  useEffect(() => {
    // почему не работает .unwrap() у методов, нужен рефакторинг
    if (!isLoadingNickname) {
      if (errors) {
        const errorMessage = errors[0]?.message || 'Server error: Unable to update nickname. Please try again later.'

        setErrorMessage(errorMessage)
      } else if (!errors && isOpenPopup) {
        // Если ошибок нет и попап открыт, можно закрыть
        GetTeamMemberTC()
        handlePopup()
      }
    }
  }, [isLoadingNickname, errors])

  useEffect(() => {
    GetTeamMemberTC()
  }, [])

  useEffect(() => {
    if (nickname) {
      setName(nickname)
    }
  }, [nickname])

  const isDisabled = !name || name.trim() === '' || nickname === name

  return (
    <>
      <div className={style.setting}>
        <div className={style.sectionIcon}>
          <SVG.Profile.User />
        </div>
        <div>
          <div className={style.settingTitle}>
            <span>{t({ id: 'profile.nickname.title', comment: 'title' }) + ':'}</span>
            <span className={style.nickname}>{` ${nickname ?? '-'}`}</span>
          </div>
          <div className={style.settingStatus}>
            {t({
              id: 'profile.nickname.description',
              comment: 'description',
            })}
          </div>
        </div>
        <div className={style.settingActions}>
          <Button.Primary
            label={t({ comment: 'change', id: 'profile.nickname.change' })}
            onClick={handlePopup}
            disabled={!nickname}
          />
        </div>
      </div>
      {isOpenPopup && (
        <InteractiveModal isOpen={isOpenPopup}>
          <InteractiveModalParts.MainInfo text={t({ id: 'popup.nickname.title', comment: 'title' })} />
          <InteractiveModalParts.Explanation text={t({ id: 'popup.nickname.description', comment: 'description' })} />
          <br />
          <Input
            label={t({ id: 'popup.nickname.input', comment: 'input' })}
            size={InputSize.Medium}
            value={name}
            setValue={handleChange}
            containerClassName={style.input}
            errorMessage={errorMessage ?? undefined}
          />
          <br />
          <InteractiveModalParts.Button
            text={t({ comment: 'setNickname', id: 'popup.nickname.setNickname' })}
            variant={ButtonVariant.Accent}
            onClick={handleSubmit}
            isDisabled={isDisabled}
          />
          <br />
          <InteractiveModalParts.Button
            text={t({ comment: 'Cancel', id: 'core.cancel' })}
            variant={ButtonVariant.Clean}
            onClick={handlePopup}
          />
        </InteractiveModal>
      )}
    </>
  )
}
