import { ALLOW_LANGUAGES_CODES, TExchangesAvailable } from '@/core/constants'
import { FieldErrorType } from '@/types'
import { storage } from '@/utils/lib/storage'
import { t } from '@lingui/macro'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { enqueueSnackbar } from 'notistack'
import { profileAsyncActions } from './profile.actions'
import { initialState } from './profile.state'
import { filterAsyncMetadataKeys, normalizeMetadata } from '@/utils/lib/metadata/mapper'

export const slice = createSlice({
  name: `[Tiger Trade Account profile]`,
  initialState,
  reducers: {
    resetEmailStatus(state) {
      state.resetMailStatus.isResetLoaded = false
      state.resetMailStatus.isResetSuccess = false
    },
    setEmail(state, action: PayloadAction<string>) {
      state.userEmail = action.payload
    },
    setTigerTradeId(state, action: PayloadAction<string>) {
      state.tigerTradeId = action.payload
    },
    setExchange(state, action: PayloadAction<TExchangesAvailable>) {
      state.metadata = {
        ...state.metadata,
        ...normalizeMetadata({
          exchangeType: action.payload,
        }),
      }
    },
    setErrors(state, action: PayloadAction<FieldErrorType[]>) {
      state.errors = action.payload
    },
    clearLoginTwoFaToggleStatus(state) {
      state.loginTwoFaToggleStatus = 'idle'
    },
    clearVerifiedWithTwoFaStatus(state) {
      state.verifiedWithTwoFaStatus = 'idle'
    },
  },
  extraReducers: builder => {
    builder
      .addCase(profileAsyncActions.GetProfileTC.pending, (state, action) => {
        if (state.initialStatus === 'idle') {
          state.initialStatus = 'loading'
        }
        state.status = 'loading'
      })
      .addCase(profileAsyncActions.GetProfileTC.rejected, (state, action) => {
        if (state.initialStatus === 'loading') {
          state.initialStatus = 'failed'
        }
        state.status = 'failed'
      })
      .addCase(profileAsyncActions.GetProfileTC.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const { locale, metadata = {}, google, loginTwoFa, vipAccess, twoFa, tigerXAccess, pin } = action.payload

        state.resetStateTwoFa = twoFa.resetState
        state.pin = pin

        state.tigerXAccess = tigerXAccess

        if (locale && ALLOW_LANGUAGES_CODES.includes(locale)) {
          state.language = locale
          state._metaProfileLocale = locale
          storage.setLocale(locale)
        } else {
          state._metaProfileLocale = null
        }

        const normalizedMetadata = normalizeMetadata(metadata)

        state.google = google
        state.loginTwoFa = loginTwoFa
        state.vipAccess = vipAccess
        state.pin = pin
        if (state.initialStatus === 'succeeded') {
          delete normalizedMetadata.isFirstSessionTracked
        }

        state.metadata = {
          ...state.metadata,
          ...normalizedMetadata,
        }

        state.initialStatus = 'succeeded'
      })
      .addCase(profileAsyncActions.UpdateLocale.pending, (state, action) => {
        const { locale } = action.meta.arg

        if (locale) {
          storage.setLocale(locale)
        }
      })
      .addCase(profileAsyncActions.UpdateLocale.fulfilled, (state, action) => {
        const { locale } = action.meta.arg

        if (locale && ALLOW_LANGUAGES_CODES.includes(locale)) {
          state.language = locale
          state._metaProfileLocale = locale
        }
      })
      .addCase(profileAsyncActions.UpdateMetadata.pending, (state, action) => {
        const normalizedMetadata = filterAsyncMetadataKeys(
          normalizeMetadata({
            ...action.meta.arg,
          })
        )

        state.metadata = {
          ...state.metadata,
          ...normalizedMetadata,
        }
      })
      .addCase(profileAsyncActions.GetBNBBurnStatusTC.fulfilled, (state, action) => {
        state.bnbBurnStatus = 'succeeded'
        state.spotBNBBurn = action.payload.spotBNBBurn
      })
      .addCase(profileAsyncActions.UpdateBNBBurnStatusTC.fulfilled, (state, action) => {
        state.spotBNBBurn = action.payload.spotBNBBurn
      })
      .addCase(profileAsyncActions.GetMultiAssetMarginTC.fulfilled, (state, action) => {
        state.multiAssetsMarginStatus = 'succeeded'
        state.multiAssetsMargin = action.payload.multiAssetsMargin
      })
      .addCase(profileAsyncActions.UpdateMultiAssetMarginTC.fulfilled, (state, action) => {
        state.multiAssetsMargin = action.payload.multiAssetsMargin
      })
      .addCase(profileAsyncActions.GetBNBDustTC.fulfilled, (state, action) => {
        state.bnbDustStatus = 'succeeded'
        state.bnbDustEnabled = action.payload.enabled
      })
      .addCase(profileAsyncActions.UpdateBNBDustTC.fulfilled, (state, action) => {
        state.bnbDustEnabled = action.payload.enabled
      })
      .addCase(profileAsyncActions.GetBinancePositionModeTC.fulfilled, (state, action) => {
        const [usdmPositionMode, coinmPositionMode] = action.payload
        state.binanceHedge.BINANCE_FUTURE = usdmPositionMode
        state.binanceHedge.BINANCE_COIN = coinmPositionMode
      })
      .addCase(profileAsyncActions.VerifyTwoFA.rejected, (state, action) => {
        const errorMessage = t({
          id: 'TwoFAErrors.googleCode',
          comment: 'Google code invalid',
        })

        state.verifiedWithTwoFaStatus = 'failed'
        state.errors = [{ field: 'googleCode', message: errorMessage, code: 0 }]
      })
      .addCase(profileAsyncActions.VerifyTwoFA.pending, (state, action) => {
        state.verifiedWithTwoFaStatus = 'loading'
      })
      .addCase(profileAsyncActions.VerifyTwoFA.fulfilled, (state, action) => {
        state.verifiedWithTwoFaStatus = 'succeeded'
        storage.setVerifiedWithTwoFa('true')
      })
      .addCase(profileAsyncActions.LoginWith2fa.pending, (state, action) => {
        state.loginTwoFaToggleStatus = 'loading'
      })

      .addCase(profileAsyncActions.SetPin.pending, (state, action) => {
        state.setPinStatus = 'loading'
      })
      .addCase(profileAsyncActions.SetPin.fulfilled, (state, action) => {
        state.setPinStatus = 'succeeded'
      })
      .addCase(profileAsyncActions.SetPin.rejected, (state, action) => {
        state.setPinStatus = 'failed'
      })
      .addCase(profileAsyncActions.ResetPin.pending, (state, action) => {
        state.resetPinStatus = 'loading'
      })
      .addCase(profileAsyncActions.ResetPin.fulfilled, (state, action) => {
        state.resetPinStatus = 'succeeded'
      })
      .addCase(profileAsyncActions.ResetPin.rejected, (state, action) => {
        state.resetPinStatus = 'failed'
      })
      .addCase(profileAsyncActions.LoginWith2fa.rejected, (state, action) => {
        const errorMessage = t({
          id: 'TwoFAErrors.googleCode',
          comment: 'Google code invalid',
        })

        state.loginTwoFaToggleStatus = 'failed'
        state.errors = [
          {
            field: 'googleCode',
            message: errorMessage,
            code: 0,
          },
        ]
      })
      .addCase(profileAsyncActions.LoginWith2fa.fulfilled, (state, action) => {
        state.loginTwoFa = !state.loginTwoFa
        state.loginTwoFaToggleStatus = 'succeeded'
        storage.setVerifiedWithTwoFa('true')

        const enableText = t({
          id: 'twoFa.enabled',
          comment: 'Two-factor authentication enabled',
        })

        const disableText = t({
          id: 'twoFa.disabled',
          comment: 'Two-factor authentication disabled',
        })

        enqueueSnackbar(`${!state.loginTwoFa ? disableText : enableText}`, {
          variant: 'success',
        })
      })
      .addCase(profileAsyncActions.resetTwoFATC.pending, (state, action) => {})
      .addCase(profileAsyncActions.resetTwoFATC.fulfilled, (state, action) => {
        state.resetMailStatus.isResetLoaded = true
        state.resetMailStatus.isResetSuccess = true
      })
      .addCase(profileAsyncActions.resetTwoFATC.rejected, (state, action) => {
        state.resetMailStatus.isResetLoaded = true
        state.resetMailStatus.isResetSuccess = false
      })
  },
})

export const profileActions = {
  ...slice.actions,
  ...profileAsyncActions,
}

export const profileReducer = slice.reducer
