import { CategoryListV5, CategoryV5 } from 'bybit-api'

export enum ETigerXInstruments {
  'spot' = 'spot',
  'linear' = 'linear',
}

export interface ITigerXParamsCategory {
  category: ETigerXInstruments
  symbol?: string
}

export type ByBitTransactionHistory = {
  side: string
  funding: string
  orderLinkId: string
  orderId: string
  fee: string
  change: string
  cashFlow: string
  transactionTime: string
  type: string
  feeRate: string
  bonusChange: string
  size: string
  qty: string
  cashBalance: string
  currency: string
  id: string
  category: string
  tradePrice: string
  tradeId: string
  symbol: string
}

export type Position = {
  avgPrice: string
  createAt: string
  fee: string
  fundingFee: string
  leverage: string
  liqPrice: string
  markPrice: string
  maxLeverage: string
  portfolioId: string
  positionId: string
  positionMM: string
  positionMargin: string
  positionQty: string
  positionSide: 'NONE' | 'BUY' | 'SEL'
  positionValue: string
  riskLevel: string
  sym: string
  tpslOrder: []
  unrealizedPNL: string
  unrealizedPNLRate: string
  updateAt: string
  exchangeType: string
}

export type Order = {
  portfolioId: string
  portfolioName: string
  orderId: string
  clientOrderId: string
  orderState: string
  sym: string
  side: 'BUY' | 'SELL'
  exchangeOrderType: 'LIMIT' | 'MARKET'
  exchangeType: 'BINANCE' | 'OKX'
  businessType: string
  orderQty: string
  quoteOrderQty: string
  limitPrice: string
  timeInForce: string
  reason: string
  executedQty: string
  executedAvgPrice: string
  executedAmount: string
  reduceOnly: string
  lastExecutedQty: string
  lastExecutedPrice: string
  lastExecutedAmount: string
  fee: string
  leverage: number
  borrowAmount: string
  borrowAsset: string
  positionSide: 'NONE' | 'LONG' | 'SHORT'
  createAt: string
  updateAt: string
  algoOrderType?: string
  algoOrderId?: string
  conditionalTriggerPrice: string
  tpTriggerPrice: string
  slTriggerPrice: string
}

export type HistoryLogPayload = {
  result: {
    list: ByBitTransactionHistory[]
  }
}

export type CategoryListV5Internal<T extends unknown[], TCategory extends CategoryV5> = CategoryListV5<T, TCategory> & {
  nextPageCursor: string
}

// ________________

export interface ConnexWalletBalance {
  accountType: string
  balances: {
    currency: string
    available: string
    total: string
  }[]
}

export interface ConnexOrder {
  orderId: string
  symbol: string
  side: string // "BUY" | "SELL"
  quantity: string
  price: string
  status: string
  createdAt: string
}

export interface ConnexPosition {
  symbol: string
  side: string // "LONG" | "SHORT"
  size: string
  entryPrice: string
  unrealizedPnl: string
}

export type AccountInfo = {
  portfolioId: string // Portfolio ID
  exchangeType: string // Exchange type (e.g., Binance, OKX)
  equity: string // Estimated total value in USDT
  maintainMargin: string // Maintenance margin
  positionValue: string // Position value
  uniMMR: string // uniMMR (default 99999999)
  accountStatus: 'NORMAL' | 'MARGIN_CALL' | 'CANCEL_OPEN' | 'CANCEL_ALL' | 'LIQUIDATING' | 'TAKEOVER' // Account status
  marginValue: string // Total margin value
  frozenMargin: string // Frozen margin (Initial margin, including perpMargin and debtMargin)
  perpMargin: string // Perpetual contract margin
  debtMargin: string // Debt margin
  openLossMargin: string // Losses due to margin losses, fees, etc.
  validMargin: string // Valid margin
  availableMargin: string // Available margin
  upnl: string // Unrealized Profit and Loss (UPNL)
  positionMode: 'NET' | 'BOTH' // Position mode (NET/BOTH)
}

export type AssetInfo = {
  coin: string // Название токена, например, BTC или ETH
  exchangeType: string // Тип биржи (BINANCE, OKX)
  available: string // Доступное количество
  frozen: string // Замороженное количество (SPOT & MARGIN)
  equity: string // Чистое количество монеты
  balance: string // Переводимая сумма
  borrow: string // Занятое количество
  overdraw: string // Перерасход
  debt: string // Долг
  marginValue: string // Значение маржи
  indexPrice: string // Индексная цена
  virtualBorrow: string // Виртуальный займ
  debtMargin: string // Маржинальный долг
  perpMargin: string // Маржинальная стоимость по бессрочным контрактам
  createAt: string // Время создания
  updateAt: string // Время обновления
  maxTransferable: string // Максимально переводимая сумма
  upnl: string // Нереализованная прибыль/убыток
  equityValue: string
}

export type Statement = {
  transactionId: string
  portfolioId: string
  orderId: string
  exchangeType: string
  businessType: string
  sym: string
  side: string
  quantity: string
  price: string
  tradingFee: string
  tradingFeeCoin: string
  execType: string
  rpnl: string
  createAt: string
  clientOrderId: string
  algoOrderId: string
}

export type QueryStatementParams = {
  sym?: string // Валюта (например, "BTC")
  originalSymbol?: string
  coin?: string
  statementType?: string // Тип отчёта (например, "FUNDING_FEE")
  exchange?: string // Биржа (например, "BINANCE")
  startTime?: string // Начало временного диапазона
  endTime?: string // Конец временного диапазона
  page?: string // Номер страницы
  pageSize?: string // Размер страницы
  businessType?: string
}

export interface TigerXTicker {
  leverage: string
  maxLeverage: string
  maxNotionalValue: string
  state: 'live' | 'suspend'
  defaultLeverage: string
  loading: boolean
  originalSymbol: string
}

export interface TigerXTickersListParams {
  sym: string
}
