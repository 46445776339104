import { TAccountType } from '@/core/types/overview'

export const errorAsyncCreator = (error: Error) => {
  // console.error(error.message)
  return Promise.reject(error)
}

export const voidFunction = () => {}
export const voidPFunction = (payload: any) => payload

export const mapTigerXAccountName = (accountName: TAccountType) => {
  if (accountName === 'TIGER_X_OKX' || accountName === 'TIGER_X_BINANCE') return 'TIGER_X'

  return 'TIGER_X'
}
