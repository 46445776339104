import { EAccountType } from '@tigertrade/binance-ts'
import { ApiError } from '../tigerx/tegerx.api'
import { tigerBackRest } from '../tigerBack/tigerBack.rest'
import { normalizeBalance } from './binance.mappers'

export const BinanceApi = {
  getTransferData: async () => {
    try {
      const [assetsRes] = await Promise.all([
        tigerBackRest.getAssetsForTransactions().catch(err => {
          throw { source: 'assetsForTransactions', status: err.response?.status || 500, message: err.message }
        }),
      ])

      const { data } = assetsRes

      if (!data) {
        throw { source: 'assetsForTransactions', status: 500, message: 'No transfer data received' }
      }

      const options = [
        {
          value: EAccountType.COIN_FUTURE,
          amount: normalizeBalance(data.coinAccountBalance.balance, 8),
          symbol: data.coinAccountBalance.symbol,
          name: 'Futures COIN-M',
          type: 'FUTURES',
          coins: data.coinAccountBalance.assets,
        },
        {
          value: EAccountType.SPOT,
          amount: normalizeBalance(data.spotAccountBalance.balance, 8),
          symbol: data.spotAccountBalance.symbol,
          name: 'Spot',
          type: 'SPOT',
          coins: data.spotAccountBalance.assets,
        },
        {
          value: EAccountType.USDT_FUTURE,
          amount: normalizeBalance(data.futuresAccountBalance.balance, 8),
          symbol: data.futuresAccountBalance.symbol,
          name: 'Futures USDⓈ-M',
          type: 'FUTURES',
          coins: data.futuresAccountBalance.assets,
        },
      ]

      const accountConfig = {
        COIN_FUTURE: {
          name: 'Futures COIN-M',
          coins: data.coinAccountBalance.assets,
          account: 'COIN_FUTURE',
        },
        SPOT: {
          name: 'Spot',
          coins: data.spotAccountBalance.assets,
          account: 'SPOT',
        },
        USDT_FUTURE: {
          name: 'Futures USDⓈ-M',
          coins: data.futuresAccountBalance.assets,
          account: 'COIN_FUTURE',
        },
      }

      const transfer_data = {
        options: options,
        accountConfig: accountConfig,
        accountFrom: EAccountType.SPOT,
        accountTo: EAccountType.USDT_FUTURE,
        maxDecimal: 8,
      }

      return transfer_data
    } catch (error: unknown) {
      const apiError = error as ApiError
      console.error('Ошибка при получении transfer data:', apiError)

      return {
        error: {
          source: apiError.source,
          status: apiError.status,
          message: apiError.message,
        },
      }
    }
  },
}
