import { ReferralsExchangeSectionPair, TGetReferralEarnings } from '@/backend/models/ReferralsDTO'
import {
  EARNINGS_PAGE_SIZE_DEFAULT,
  EARNING_PAGE_FROM_DEFAULT,
  EARNING_PAGE_TO_DEFAULT,
} from '@/core/constants/referrals'
import { BN_ZERO } from '@/core/constants/common'
import { referralsAsyncActions } from '@/redux/referrals/referrals.actions'
import { useActions, useAppSelector } from '@/utils'
import { DATE_FORMAT, formatDate } from '@/utils/lib/formatDate'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { t } from '@lingui/macro'
import { TTableAccessor } from '@/ui/molecules/Table/Table'
import { formatNumber } from '@/utils'
import BigNumber from 'bignumber.js'
import styles from '../style.module.scss'
import { SVG } from '@/assets/svg'
import { Input, InputSize } from '@/ui/kit'
import { useSearchInput } from './useSearchInput'
import { SelectOption } from '@/ui/kit'
import clsx from 'clsx'

type ColumnType = {
  translation: string
  sortBy?: string
}

export enum OptionsEnum {
  AWARD_CLAIMED = 'awardClaimed',
  ONLY_TEAM_MEMBERS = 'onlyTeamMembers',
}

export const useReferralEarnings = (
  dateFrom: string = formatDate(EARNING_PAGE_FROM_DEFAULT, DATE_FORMAT.FORMATTED),
  dateTo: string = formatDate(EARNING_PAGE_TO_DEFAULT, DATE_FORMAT.FORMATTED),
  pageSize: number = EARNINGS_PAGE_SIZE_DEFAULT
) => {
  const isWthoutFilterRender = useRef(true)

  const [sortParams, setSortParams] = useState<{ sortBy?: string; sortDesc: boolean }>({
    sortBy: 'earnings',
    sortDesc: true,
  })
  const inputRef = useRef<HTMLInputElement>(null)

  const { GetReferralEarningsTC, GetReferralExchangeSectionListTC, ReferralEarningsExportTC } =
    useActions(referralsAsyncActions)
  const {
    earnings: { earningsStatus, referralEarnings, earningsPage, earningReachedEnd, earningUser, earningNoSearchResult },
    exchangeSectionList,
    referralsEarningExportStatus,
    referralsEarningExport,
  } = useAppSelector(state => state.referrals)

  const [selectedExchange, setSelectedExchange] = useState('')
  const [selectedOption, setSelectedOption] = useState<OptionsEnum>()

  const {
    metadata: { firstSesstionTrackedTimestamp },
  } = useAppSelector(state => state.profile)

  const [startDate, setStartDate] = useState(
    firstSesstionTrackedTimestamp
      ? formatDate(new Date(Number(firstSesstionTrackedTimestamp)), DATE_FORMAT.FORMATTED)
      : dateFrom
  )
  const [endDate, setEndDate] = useState(dateTo)

  const handleSortClick = (column?: string) => {
    setSortParams(prev => ({
      sortBy: column,
      sortDesc: prev.sortBy === column ? !prev.sortDesc : true,
    }))
  }

  useEffect(() => {
    GetReferralExchangeSectionListTC()
  }, [])

  const options = useMemo(() => {
    return [
      {
        label: t({
          id: 'referralEarnings.awardClaime',
          message: 'award claimed',
        }),
        value: OptionsEnum.AWARD_CLAIMED,
      },
      {
        label: t({
          id: 'referralEarnings.onlyTeamMembers',
          message: 'only team Members',
        }),
        value: OptionsEnum.ONLY_TEAM_MEMBERS,
      },
    ]
  }, [])

  const optionsHandler = (option: OptionsEnum) => {
    setSelectedOption(prev => (prev === option ? undefined : option))
  }

  const getReferralEarnings = useCallback(
    (
      page: number = 0,
      options?: {
        userId?: string
        from?: string
        to?: string
        exchange?: ReferralsExchangeSectionPair
        sortParamsValues?: {
          sortBy?: string
          sortDesc?: boolean
        }
        onlyTeamMembers?: boolean
        awardClaimed?: boolean
      }
    ) => {
      GetReferralEarningsTC({
        dateFrom: options?.from ?? dateFrom,
        dateTo: options?.to ?? dateTo,
        ...(options?.userId !== undefined ? { userId: options.userId } : {}),
        ...(options?.exchange !== undefined || selectedExchange !== undefined
          ? {
              exchangeSections: options?.exchange
                ? options.exchange
                : (selectedExchange as ReferralsExchangeSectionPair),
            }
          : {}),
        ...(options?.onlyTeamMembers ?? selectedOption === OptionsEnum.ONLY_TEAM_MEMBERS
          ? { onlyTeamMembers: true }
          : {}),
        ...(options?.awardClaimed ?? selectedOption === OptionsEnum.AWARD_CLAIMED ? { awardClaimed: true } : {}),
        sortBy: options?.sortParamsValues?.sortBy ?? sortParams.sortBy,
        sortDesc: options?.sortParamsValues?.sortDesc ?? sortParams.sortDesc,
        page,
        size: pageSize,
      })
    },
    [startDate, endDate, dateFrom, dateTo, pageSize, sortParams, selectedExchange, selectedOption]
  )

  const getExchangeSections = () => {
    const data = exchangeSectionList?.data.map(el => {
      return {
        label: el.exchangeSection,
        value: el.id,
      } as SelectOption
    })
    return data
  }

  const changeDates = (dateStart: string, dateEnd: string) => {
    setStartDate(dateStart)
    setEndDate(dateEnd)
  }

  const { searchInputHandler, searchValueError, searchValueInput } = useSearchInput(startDate, endDate, () => {})

  const changeExchangeSection = (exchangePair: string) => {
    setSelectedExchange(prev => (prev === exchangePair ? '' : exchangePair))
  }

  useEffect(() => {
    if (isWthoutFilterRender.current) {
      isWthoutFilterRender.current = false
      return
    }

    getReferralEarnings(0, {
      userId: searchValueInput !== '' ? searchValueInput : undefined,
      from: startDate,
      to: endDate,
      ...{ exchange: selectedExchange as ReferralsExchangeSectionPair },
      ...(selectedOption === OptionsEnum.ONLY_TEAM_MEMBERS ? { onlyTeamMembers: true } : {}),
      ...(selectedOption === OptionsEnum.AWARD_CLAIMED ? { awardClaimed: true } : {}),
      sortParamsValues: {
        sortBy: sortParams.sortBy,
        sortDesc: sortParams.sortDesc,
      },
    })
  }, [selectedExchange, sortParams, selectedOption, searchValueInput])

  const exportCsvHandler = () => {
    ReferralEarningsExportTC({
      dateFrom: startDate ?? dateFrom,
      dateTo: endDate ?? dateTo,
      userId: searchValueInput !== '' ? searchValueInput : undefined,
      ...(selectedExchange !== undefined
        ? {
            exchangeSections: selectedExchange as ReferralsExchangeSectionPair,
          }
        : {}),
      ...(selectedOption === OptionsEnum.ONLY_TEAM_MEMBERS ? { onlyTeamMembers: true } : {}),
      ...(selectedOption === OptionsEnum.AWARD_CLAIMED ? { awardClaimed: true } : {}),
      sortBy: sortParams.sortBy,
      sortDesc: sortParams.sortDesc,
      page: earningsPage,
      size: pageSize,
    })
  }

  const translations = useMemo<Record<keyof TGetReferralEarnings, ColumnType>>(() => {
    return {
      tradingSection: {
        translation: t({
          id: 'referralEarnings.account.type',
          message: 'account type',
        }),
      },
      partnerEarnings: {
        translation: t({
          id: 'referralEarnings.partnerEarnings',
          message: 'Your income',
        }),
        sortBy: 'earnings',
      },
      tradingDay: {
        translation: t({
          id: 'referralEarnings.tradingDay',
          message: 'Trading day',
        }),
        sortBy: 'day',
      },
      tradingCommission: {
        translation: t({
          id: 'referralEarnings.userCommission',
          message: 'User commission',
        }),
        sortBy: 'commission',
      },
      userId: {
        translation: t({
          id: 'referralEarnings.userId',
          message: 'User id',
        }),
        sortBy: 'name',
      },
      userJoined: {
        translation: t({
          id: 'referralEarnings.userJoined',
          message: 'User joined date',
        }),
        sortBy: 'registration',
      },
      tradingVolume: {
        translation: t({
          id: 'referralEarnings.userVolume',
          message: 'User trade volume',
        }),
        sortBy: 'volume',
      },
      awardClaimed: {
        translation: t({
          id: 'referralEarnings.awardClaimed',
          message: 'Bonus',
        }),
      },
    }
  }, [])

  const SearchInput = () => {
    return (
      <Input
        setValue={searchInputHandler}
        value={searchValueInput}
        size={InputSize.ExtraSmall}
        errorMessage={searchValueError}
        isError={searchValueError.length > 0}
        icon={<SVG.Additional.Search />}
        placeholder={t({
          id: 'referralEarnings.uuid',
          message: 'Enter User ID to search',
        })}
        containerClassName={styles.inputContainer}
        errorClassName={styles.inputError}
        ref={inputRef}
        autofocus={inputRef.current === document.activeElement}
        onClear={() => searchInputHandler('')}
      />
    )
  }
  const tableAccessors = useMemo<TTableAccessor[]>(() => {
    if (referralEarnings.length === 0) return []
    return Object.keys(referralEarnings[0]).map((key, idx) => {
      const isActiveSort = translations[key as keyof TGetReferralEarnings].sortBy === sortParams.sortBy

      return {
        accessor: key,
        cell: info => {
          if (key === 'awardClaimed') {
            const isClaimed = info.row.original.awardClaimed
            return <>{isClaimed ? <SVG.OtherIcons.Success /> : <SVG.OtherIcons.Hold />}</>
          }
          return info.getValue()
        },
        header: (
          <div className={styles.columnWrapper}>
            {idx === 0 ? <SearchInput /> : translations[key as keyof TGetReferralEarnings].translation}

            {translations[key as keyof TGetReferralEarnings].sortBy && idx !== 0 && (
              <span
                className={clsx(styles.sortArr, {
                  //maybe will be use for id
                  [styles.searchArr]: idx === 0,
                })}
                onClick={() => handleSortClick(translations[key as keyof TGetReferralEarnings].sortBy)}
              >
                <SVG.Arrows.ArrowSort fill={isActiveSort ? '#FAFAFA' : '#676573'} />
              </span>
            )}
          </div>
        ),
        meta: {
          className: [styles.td],
        },
      }
    })
  }, [referralEarnings, translations, searchInputHandler, searchValueInput, searchValueError])

  const sumColumnFormatter = useMemo(() => {
    if (referralEarnings.length === 0) return

    const { partnerEarnings, tradingCommission, tradingVolume } = referralEarnings.reduce(
      (acc, { partnerEarnings, tradingCommission, tradingVolume }) => ({
        partnerEarnings: acc.partnerEarnings.plus(new BigNumber(partnerEarnings)),
        tradingCommission: acc.tradingCommission.plus(new BigNumber(tradingCommission)),
        tradingVolume: acc.tradingVolume.plus(new BigNumber(tradingVolume)),
      }),
      { partnerEarnings: BN_ZERO, tradingCommission: BN_ZERO, tradingVolume: BN_ZERO }
    )

    const userId = t({
      id: 'referralEarnings.total',
      message: 'Total',
    })

    return {
      partnerEarnings: { value: formatNumber(partnerEarnings.toNumber()), className: styles.highligth },
      userCommission: { value: formatNumber(tradingCommission.toNumber()) },
      userVolume: { value: formatNumber(tradingVolume.toNumber()) },
      userId: {
        value: userId,
      },
    }
  }, [referralEarnings])

  return {
    getReferralEarnings,
    earningsStatus,
    referralEarnings,
    earningsPage,
    earningReachedEnd,
    tableAccessors,
    earningUser,
    earningNoSearchResult,
    startDate,
    endDate,
    changeDates,
    sumColumnFormatter,
    searchInputHandler,
    searchValueError,
    searchValueInput,
    exchangeSectionList,
    changeExchangeSection,
    getExchangeSections,
    selectedExchange,
    sort: {
      sortBy: sortParams.sortBy,
      sortDesc: sortParams.sortDesc,
    },
    setSortParams,
    options,
    optionsHandler,
    selectedOption,
    setSelectedOption,
    ReferralEarningsExportTC,
    exportCsvHandler,
    referralsEarningExportStatus,
    referralsEarningExport,
  }
}
