import { exchangeFactory } from '@/exchanges/exchangeFactory'
import { EXCHANGES_AVAILABLE, EXCHANGE_TYPE_SELECTOR, TExchangesAvailable } from '@/core/constants'

export const getActiveExchangeFromSession = () => {
  const sessionExchange = sessionStorage.getItem(EXCHANGE_TYPE_SELECTOR)

  if (sessionExchange && EXCHANGES_AVAILABLE.includes(sessionExchange as TExchangesAvailable)) {
    return exchangeFactory[sessionExchange as TExchangesAvailable]
  }

  return exchangeFactory['BINANCE_BROKER_FUTURE']
}
