import React from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

type Props = {
  tpTriggerPrice?: string | null
  slTriggerPrice?: string | null
  className?: string
}

const TpSlDisplay: React.FC<Props> = ({ tpTriggerPrice, slTriggerPrice, className }) => {
  const hasTp = tpTriggerPrice !== null && tpTriggerPrice !== undefined && Number(tpTriggerPrice) !== 0
  const hasSl = slTriggerPrice !== null && slTriggerPrice !== undefined && Number(slTriggerPrice) !== 0

  const formattedTp = hasTp ? tpTriggerPrice : `--`
  const formattedSl = hasSl ? slTriggerPrice : `--`

  return (
    <span className={clsx(styles.root, className)}>
      <span className={styles.tpTriggerNegative}>{formattedTp}</span>/
      <span className={styles.slTriggerNegative}>{formattedSl}</span>
    </span>
  )
}

export { TpSlDisplay }
