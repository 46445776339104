import { useEffect, useState } from 'react'
import { TransactionWrapper } from '../TransactionWrapper/TransactionWrapper'
import { DepositForm } from './DepositForm/DepositForm'
import { TigerBackApi } from '@/exchanges/tigerBack/tigerBack.api'
import { useActiveExchage } from '@/utils/hooks/useActiveExchange'
import { CoinWithNetworks } from './DepositForm/types'
import { t } from '@lingui/macro'
import { useAppSelector } from '@/utils'
import { useNavigate } from 'react-router-dom'
import { urls } from '@/router/urls'
import { isExchangeBinance } from '@/utils/lib/exchange'

export type DepositeData = {
  options: CoinWithNetworks[]
}

export function DepositPage() {
  const { activeExchange } = useActiveExchage()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [errorCode, setErrorCode] = useState<number | null>(null)
  const [depositeData, setDepositeData] = useState<DepositeData | null>(null)

  const navigate = useNavigate()
  const { userKycLinkStatusRequestStatus, userKycLinkState, userKYC } = useAppSelector(state => state.kyc)

  //delete when refactor P2-588
  function isDepositBlockedForExchange(type: string): boolean {
    if (isExchangeBinance(type)) {
      if (userKycLinkStatusRequestStatus !== 'succeeded') return false

      return (
        userKycLinkState.status === 'FINAL_REJECT' ||
        userKycLinkState.status === 'REJECT' ||
        userKycLinkState.status === 'INIT'
      )
    }

    return false
  }

  const isDepositeBlocked = isDepositBlockedForExchange(activeExchange.type)
  const isLoadingBinanceKyc = isExchangeBinance(activeExchange.type)
    ? userKycLinkStatusRequestStatus === 'idle' || userKycLinkStatusRequestStatus === 'loading'
    : false

  const fetchTransferData = async () => {
    setLoading(true)
    setError(null)
    setErrorCode(null)

    try {
      const data = await TigerBackApi.getDepositData()

      if (!data) {
        throw new Error('No deposit data has been received')
      }

      if (data.error) {
        setErrorCode(data.error.status) // Сохраняем код ошибки
        throw new Error(`${data.error.source}: ${data.error.message} (code: ${data.error.status})`)
      }

      setDepositeData(data)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Unknown error')
    } finally {
      setLoading(false)
    }
  }

  //delete when refactor P2-588
  useEffect(() => {
    if (userKYC.kycTier === 'UNVERIFIED' || userKYC.kycTier === 'BASIC') {
      navigate(urls.wallet)
    }
  }, [userKYC.kycTier])

  useEffect(() => {
    if (isDepositeBlocked) {
      navigate(urls.wallet)
    }

    if (!depositeData) {
      fetchTransferData()
    }
  }, [depositeData, isDepositeBlocked])

  return (
    <TransactionWrapper
      title={t({ comment: 'Deposit', id: 'core.deposit' })}
      //delete isLoadingBinanceKyc when refactor P2-588
      loading={loading || isLoadingBinanceKyc}
      error={error}
      errorCode={errorCode}
      onRetry={fetchTransferData}
    >
      {depositeData && (
        <DepositForm depositData={depositeData} minDeposit={activeExchange.transactions.deposit.minDeposite} />
      )}
    </TransactionWrapper>
  )
}
