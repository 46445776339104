import { Button, ButtonSize, ButtonVariant } from '@/ui/kit'
import style from './style.module.scss'
import { t } from '@lingui/macro'
import { FC } from 'react'
import { PinScreenShow, PinScreenShowType } from '../../PinCodeModal'

type PinCodeSuccessProps = PinScreenShowType & { onClose: React.Dispatch<React.SetStateAction<boolean>> }

export const PinCodeSuccess: FC<PinCodeSuccessProps> = ({ onClose, setPinScreenShow }) => {
  const onCloseHandle = () => {
    onClose(false)
    setPinScreenShow(PinScreenShow.RESET)
  }
  return (
    <div className={style.container}>
      <div className={style.title}>
        {t({
          id: 'profile.pinCode.success.title',
        })}
      </div>
      <div className={style.desctiption}>
        {t({
          id: 'profile.pinCode.success.description',
        })}
      </div>
      <Button variant={ButtonVariant.Primary} size={ButtonSize.Medium} onClick={onCloseHandle} className={style.btn}>
        {t({
          id: 'profile.pinCode.close',
        })}
      </Button>
    </div>
  )
}
