import { Button, ButtonSize, ButtonVariant } from '@/ui/kit'
import style from './style.module.scss'
import { FC } from 'react'
import { t } from '@lingui/macro'

type DisableWhiteListProps = {
  setStep: React.Dispatch<React.SetStateAction<number>>
  onClose: (() => void) | undefined
}

export const DisableWhiteList: FC<DisableWhiteListProps> = ({ setStep, onClose }) => {
  return (
    <div className={style.container}>
      <div>
        {t({
          id: 'profile.whitelist.disable.text.one',
        })}
      </div>
      <div>
        {t({
          id: 'profile.whitelist.disable.text.two',
        })}
      </div>
      <div className={style.btns}>
        <Button variant={ButtonVariant.Primary} size={ButtonSize.Medium} onClick={onClose}>
          {t({
            id: 'core.return',
            message: `Return`,
          })}
        </Button>
        <Button variant={ButtonVariant.Accent} size={ButtonSize.Medium} onClick={() => setStep(2)}>
          {t({
            id: 'profile.whitelist.next',
            message: `next`,
          })}
        </Button>
      </div>
    </div>
  )
}
