import { FC } from 'react'

interface TigerXProps {
  className?: string
}
const TigerX: FC<TigerXProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none" className={className}>
    <rect width={40} height={40} fill="#1C1A24" rx={20} />
    <path
      fill="#fff"
      d="M19.506 15.457 12.927 11h14.14l-6.58 4.457c-.296.201-.684.201-.98 0ZM30.968 21.91v-3.85l-2.96 2.006a1.767 1.767 0 0 0-.775 1.464v3.85l2.961-2.007c.484-.328.774-.877.774-1.464ZM20.62 26.77 24.826 31h-9.652l4.206-4.23a.874.874 0 0 1 1.24 0ZM12.76 25.375v-3.85c0-.587-.291-1.135-.775-1.463L9.032 18.06v3.85c0 .587.29 1.135.774 1.463l2.953 2.002Z"
    />
    <path
      fill="#fff"
      d="M30.968 14.85V11l-8.613 5.838a2.872 2.872 0 0 0-1.259 2.378v3.772c0 .468.185.917.514 1.248l3.435 3.454v-.488h-.001v-6.229c0-.732.363-1.417.97-1.828l4.18-2.831c.484-.328.774-.877.774-1.464ZM18.903 22.99c0 .467-.185.916-.514 1.247l-3.435 3.454v-4.9l-.005-.004v-.717l.005.004v-1.1c0-.732-.363-1.417-.969-1.828l-4.18-2.832a1.768 1.768 0 0 1-.773-1.463V11l8.613 5.837a2.872 2.872 0 0 1 1.258 2.378v3.773Z"
    />
  </svg>
)
export { TigerX }
