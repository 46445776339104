import { FC } from 'react'

type ArrowRightProps = {
  className?: string
}
export const ArrowRight: FC<ArrowRightProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={8} height={7} fill="none" className={className}>
    <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" d="M.5 3.5h7m0 0L4.56.5m2.94 3-2.94 3" />
  </svg>
)
