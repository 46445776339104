import * as React from 'react'
const TigerXBig = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={27} height={24} fill="none">
    <path
      fill="#fff"
      d="M12.57 5.349 4.675 0H21.64l-7.895 5.349c-.356.24-.82.24-1.177 0ZM26.324 13.091v-4.62L22.77 10.88a2.121 2.121 0 0 0-.93 1.756v4.62l3.554-2.409c.58-.393.929-1.051.929-1.756ZM13.906 18.925 18.953 24H7.371l5.046-5.075a1.049 1.049 0 0 1 1.49 0ZM4.473 17.25v-4.62c0-.704-.348-1.362-.929-1.756L0 8.472v4.62c0 .704.348 1.362.929 1.756l3.544 2.402Z"
    />
    <path
      fill="#fff"
      d="M26.324 4.62V0L15.987 7.007a3.447 3.447 0 0 0-1.51 2.854v4.526c0 .562.223 1.1.618 1.498l4.121 4.144v-.586h-.002v-7.474c0-.88.436-1.7 1.164-2.193l5.017-3.399c.58-.393.929-1.052.929-1.756ZM11.845 14.387c0 .562-.222 1.1-.617 1.497l-4.12 4.145v-5.88l-.007-.005v-.86l.006.005v-1.32c0-.879-.436-1.7-1.163-2.193L.93 6.376A2.121 2.121 0 0 1 0 4.622V0l10.336 7.005c.944.64 1.51 1.71 1.51 2.854v4.527Z"
    />
  </svg>
)
export { TigerXBig }
