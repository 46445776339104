import { TitleH3 } from '@/ui/atoms/TitleH3'
import style from './style.module.scss'
import emptyBoard from '@/assets/png/empty-leader-board.png'

type Props = {
  title?: string
  subTitle?: string
}

export const EmptyBoard = ({ title, subTitle }: Props) => {
  return (
    <div className={style.wrapper}>
      <div className={style.headerWrapp}>
        <div className={style.header}>{title && <TitleH3 label={title} />}</div>
      </div>
      <div className={style.content}>
        {subTitle && <TitleH3 label={subTitle} />}
        <br />
        <img src={emptyBoard} className={style.img} alt="empty leader board" />
      </div>
    </div>
  )
}
