import { FC, ReactNode, useMemo } from 'react'
import { t } from '@lingui/macro'
import { TExchangesAvailable } from '@/core/constants'
import { SVG } from '@/assets/svg'
import { Trans } from '@lingui/react'
import { useSearchParams } from 'react-router-dom'
import { useExchangesDisabled } from '@/utils/hooks/useExchangesDisabled'
import { useAppSelector } from '@/utils'
import { exchangeFactory } from '@/exchanges/exchangeFactory'

interface IButtonActive {
  title: string
  data: TExchangesAvailable | undefined
}

export interface ICard {
  name?: string
  isDisabled?: boolean
  isAvailable?: boolean
  isAccent?: boolean
  header: { icon: FC; exchange: string }
  commission: { percent?: ReactNode | number | string; title: string | JSX.Element }
  columns?: [string, string]
  rows?: Array<[string, ReactNode]>
  button: IButtonActive
  description?: JSX.Element
}

type TExchangeVisibleCards = Partial<Record<TExchangesAvailable, ICard>>
//will be open for TigerX
// const EXCHANGES_ORDER: TExchangesAvailable[] = ['BINANCE_BROKER_FUTURE', 'BYBIT_BROKER', 'TIGER_X', 'OKX_AFFILIATE']

const EXCHANGES_ORDER: TExchangesAvailable[] = ['BINANCE_BROKER_FUTURE', 'BYBIT_BROKER', 'OKX_AFFILIATE']

export function useExchageCardsData(): ICard[] {
  const { config, userStatus } = useAppSelector(state => state.okxAffiliate)
  const [searchParams] = useSearchParams()
  const binanceDisabled = searchParams.get('binance')

  const columns = {
    maker: t({
      id: 'core.commissions.maker',
      message: 'Maker',
    }),
    taker: t({
      id: 'core.commissions.taker',
      message: 'Taker',
    }),
  }

  const commissionBinance = {
    // spot
    spotMaker: t({
      id: 'commissions.spot.makerValue',
      message: '0.1%',
    }),
    spotTaker: t({
      id: 'commissions.spot.takerValue',
      message: '0.1%',
    }),

    // Futures COIN-M
    futCoinMaker: t({
      id: 'commissions.coinm.makerValue',
      message: '0.1%',
    }),
    futCoinTaker: t({
      id: 'commissions.coinm.takerValue',
      message: '0.1%',
    }),

    // Futures USDⓈ-M
    futUsdmMaker: t({
      id: 'commissions.usdm.makerValue',
      message: '0.1%',
    }),
    futUsdmTaker: t({
      id: 'commissions.usdm.takerValue',
      message: '0.1%',
    }),
  }

  const commissionBybit = {
    // Spot
    spotMaker: (
      <Trans
        id="commissions.bybit.spot.makerValue"
        message={'0.03%'}
        components={{
          colored: <></>,
        }}
      />
    ),
    spotTaker: (
      <Trans
        id="commissions.bybit.spot.takerValue"
        message={'0.045%'}
        components={{
          colored: <></>,
        }}
      />
    ),

    // Swap
    swapMaker: (
      <Trans
        id="commissions.bybit.derivatives.makerValue"
        message={'0.03%'}
        components={{
          colored: <></>,
        }}
      />
    ),
    swapTaker: (
      <Trans
        id="commissions.bybit.derivatives.takerValue"
        message={'0.045%'}
        components={{
          colored: <></>,
        }}
      />
    ),
  }

  const comissionOkxAffiliate = {
    title: (
      <Trans id="chooseExchange.okxAffiliate.benefits" values={{ okxPercent: config.okxPercent, tigerPercent: 25 }} />
    ),
    description: (
      <Trans
        id="chooseExchange.okxAffiliate.description"
        values={{ okxPercent: config.okxPercent, tigerPercent: 25 }}
      />
    ),
  }
  const comissionTigerX = {
    title: (
      <Trans
        id="chooseExchange.tigerX.title"
        components={{
          br: <br />,
        }}
      />
    ),
    description: (
      <Trans
        id="chooseExchange.tigerX.description"
        components={{
          bottomText: (
            <div
              style={{
                marginTop: '12px',
              }}
            />
          ),
        }}
        values={{
          value: exchangeFactory['TIGER_X'].pages.chooseExchange.percent,
        }}
      />
    ),
  }

  const exchangeSource = useMemo<ICard[]>(() => {
    const source: TExchangeVisibleCards = {
      BINANCE_BROKER_FUTURE: {
        header: { icon: SVG.Settings.Binance, exchange: 'Binance' },
        commission: {
          percent: exchangeFactory['BINANCE_BROKER_FUTURE'].pages.chooseExchange.percent,
          title: t({ id: 'chooseExchange.commission', message: `Saved commission` }),
        },
        columns: [t({ id: 'chooseExchange.fees', message: `Fees` }), `${columns.maker} / ${columns.taker}`],
        rows: [
          [
            t({
              id: 'core.accounts.spot',
              message: 'Spot',
            }),
            `${commissionBinance.spotMaker} / ${commissionBinance.spotTaker}`,
          ],
          [
            t({
              id: 'core.accounts.coinm',
              message: 'Futures COIN-M',
            }),
            `${commissionBinance.futCoinMaker} / ${commissionBinance.futCoinTaker}`,
          ],
          [
            t({
              id: 'core.accounts.usdm',
              message: 'Futures USDM',
            }),
            `${commissionBinance.futUsdmMaker} / ${commissionBinance.futUsdmTaker}`,
          ],
        ],
        button: {
          title: t({ id: 'chooseExchange.defaultButton', message: 'Make default' }),
          data: 'BINANCE_BROKER_FUTURE',
        },
      },
      BYBIT_BROKER: {
        header: { icon: SVG.Settings.Bybit, exchange: 'Bybit' },
        commission: {
          percent: t({ id: 'chooseExchange.percentBybit', comment: 'Up to 82%' }),
          title: t({ id: 'chooseExchange.exclusiveFees', message: `Best trading fees` }),
        },
        columns: [t({ id: 'chooseExchange.fees', message: `Fees` }), `${columns.maker} / ${columns.taker}`],
        rows: [
          [
            t({
              id: 'core.accounts.spot',
              message: 'Spot',
            }),
            <>
              {commissionBybit.spotMaker} / {commissionBybit.spotTaker}
            </>,
          ],
          [
            t({
              id: 'core.accounts.futures',
              message: 'Futures',
            }),
            <>
              {commissionBybit.swapMaker} / {commissionBybit.swapTaker}
            </>,
          ],
        ],
        button: {
          title: t({ id: 'chooseExchange.defaultButton', message: 'Make default' }),
          data: 'BYBIT_BROKER',
        },
      },
      TIGER_X: {
        name: 'TIGER_X',
        isAccent: true,
        header: { icon: SVG.Settings.TigerX, exchange: 'Tiger X' },
        commission: {
          percent: (
            <Trans
              message="up to 45"
              id="chooseExchange.tigerX.percent"
              values={{
                value: exchangeFactory['TIGER_X'].pages.chooseExchange.percent,
              }}
            />
          ),
          title: comissionTigerX.title,
        },
        description: comissionTigerX.description,
        button: {
          title: t({ id: 'chooseExchange.defaultButton', comment: 'Make default' }),
          data: 'TIGER_X',
        },
      },
      OKX_AFFILIATE: {
        name: 'OKX_AFFILIATE',
        // isAccent: true,
        header: { icon: SVG.Settings.Okx, exchange: 'OKX' },
        commission: {
          percent: exchangeFactory['OKX_AFFILIATE'].pages.chooseExchange.percent,
          title: comissionOkxAffiliate.title,
        },
        description: comissionOkxAffiliate.description,
        button: {
          title: t({ id: 'chooseExchange.defaultButton', comment: 'Make default' }),
          data: 'OKX_AFFILIATE',
        },
      },
    }
    const result = Object.keys(source).map(key => ({
      key,
      isAvailable: exchangeFactory[key]?.pages.chooseExchange.isAvailable,
    }))
    return EXCHANGES_ORDER.reduce((acc, exchange) => {
      const found = result.find(item => item.key === exchange)
      if (found?.isAvailable) {
        acc.push(source[exchange] as ICard)
      }
      return acc
    }, [] as ICard[])
  }, [])

  return exchangeSource
}
