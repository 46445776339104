import { t } from '@lingui/macro'
import style from './style.module.scss'
import { InfoListItem } from '@/ui/organisms/InfoListItem'
import { AlertMessage } from '@/ui/organisms/AlertMessage'
import { Button, ButtonSize, ButtonVariant, Checkbox } from '@/ui/kit'
import { PinScreenShow, PinScreenShowType } from '../../PinCodeModal'
import { FC, useState } from 'react'
import { Trans } from '@lingui/react'

type PinCodeInfoProps = PinScreenShowType & {
  onClose: React.Dispatch<React.SetStateAction<boolean>>
}
export const PinCodeInfo: FC<PinCodeInfoProps> = ({ setPinScreenShow, onClose }) => {
  const [isCheked, setIsChecked] = useState(false)

  const checkHandler = () => {
    setIsChecked(pre => !pre)
  }

  return (
    <div className={style.container}>
      <div className={style.description}>
        <div>
          {t({
            id: 'profile.pinCode.setup.descrption.one',
          })}
        </div>
        <div>
          <Trans
            id="profile.pinCode.setup.descrption.two"
            components={{
              span: <span className={style.alert} />,
              link: (
                <a
                  className={style.link}
                  href={`mailto:${t({ id: 'core.supportEmail', message: 'broker.support@tiger.trade' })}`}
                />
              ),
            }}
          />
        </div>
      </div>
      <div className={style.info}>
        <InfoListItem
          text={t({
            id: 'profile.pinCode.setup.info.one',
          })}
        />
        <InfoListItem
          text={t({
            id: 'profile.pinCode.setup.info.two',
          })}
        />
      </div>
      <AlertMessage
        className={style.alert}
        text={t({
          id: 'profile.pinCode.setup.alert',
        })}
      />

      <div className={style.btnContainer}>
        <Checkbox variant="square" checked={isCheked} className={style.checkboxItem} onChange={checkHandler}>
          {t({
            id: 'profile.pinCode.setup.checkbox',
          })}
        </Checkbox>
        <div className={style.btns}>
          <Button variant={ButtonVariant.Primary} size={ButtonSize.Medium} onClick={() => onClose(false)}>
            {t({
              id: 'profile.pinCode.close',
              message: `Close`,
            })}
          </Button>
          <Button
            variant={ButtonVariant.Accent}
            size={ButtonSize.Medium}
            disabled={!isCheked}
            onClick={() => setPinScreenShow(PinScreenShow.ENTER_PIN)}
          >
            {t({
              id: 'profile.whitelist.next',
              message: `next`,
            })}
          </Button>
        </div>
      </div>
    </div>
  )
}
