import {
  INotificationConfig,
  ModalComponent,
  NotififcationPresets,
  SystemNotifiactionIcon,
} from '@/ui/molecules/SystemNotifications/notifications.type'
import { t } from '@lingui/macro'
import style from './style.module.scss'
import { Trans } from '@lingui/react'
// import { SVG } from '@/assets/svg'
import clsx from 'clsx'
// import { memo } from 'react'

interface IBinanceTechnicalWorkAlertConfig {
  isMobile: boolean
}

export function getTigerXTechnicalWorkAlert({ isMobile }: IBinanceTechnicalWorkAlertConfig): INotificationConfig {
  const now = new Date().getTime()
  const endDate = new Date('2025-04-01').setUTCHours(10, 0, 0, 0)

  const preset: INotificationConfig = {
    id: NotififcationPresets.TigerXTechnicalWork,
    icon: SystemNotifiactionIcon.Alert,
    heading: (
      <span className={clsx(isMobile && style.mobile, style.label)}>
        <Trans
          id={'core.systemAlert.tigerXTechnicalWorks.label'}
          components={{
            gradientText: <span className={style.gradient} />,
          }}
        />
      </span>
    ),
    description: <Trans id="core.systemAlertTigerXTechnicalWorks.description" />,
    // Modal: getModalComponent({ isMobile }),
    shouldShown: now < endDate,
    availableExchange: ['TIGER_X'],
    // isClosable: true,
    // link: {
    //   uri: 'https://www.binance.com/en/support/announcement/7e4e2c0c841c4eefa1fcdeb3340c23a2',
    // },
  }

  return preset
}
