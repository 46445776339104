import React, { useCallback, useEffect } from 'react'
import { t } from '@lingui/macro'
import { UserAvatar } from '@/ui/atoms/UserAvatar'
import { ProfileLevel } from '@/ui/organisms/ProfileLevel'
import { ReferralInvitedBy } from '@/ui/molecules/ReferralInvitedBy'
import { referralsActions } from '@/redux/referrals/referrals.slice'
import { makeShortString, useActions, useAppSelector } from '@/utils'
import s from './style.module.scss'
import { CopyIcon } from '@/ui/kit'
import { TeamWidget } from '@/ui/molecules/TeamWidget'
import { teamAsyncActions } from '@/redux/team/team.actions'
import { EXCHANGES_TYPE } from '@/core/constants'
import { useExchageType } from '@/utils/hooks/useExchangeType'
import { truncateEmail } from './truncateEmail'

const ProfileLeftBar = () => {
  const { GetReferralOverviewTC } = useActions(referralsActions)
  const { userEmail } = useAppSelector(state => state.profile)
  const { GetUserTeamInfoTC } = useActions(teamAsyncActions)
  const { exchangeType } = useExchageType()

  const {
    userKYC: { userId },
  } = useAppSelector(state => state.kyc)
  const {
    overview: { referrerCode, referrerLocked },
  } = useAppSelector(state => state.referrals)

  useEffect(() => {
    GetReferralOverviewTC()
  }, [GetReferralOverviewTC])

  const handleUserInfo = useCallback(() => {
    GetUserTeamInfoTC()
  }, [GetUserTeamInfoTC])

  return (
    <div className={s.root}>
      <UserAvatar size={100} />
      {userEmail === 'vassilis@centaurtrust.com' ? (
        <h2 className={s.headingExtra}>Luggo Investments Limited</h2>
      ) : (
        <h1 className={s.heading}>{t({ id: 'profile.leftTitle', message: `Your Profile` })}</h1>
      )}
      <div className={s.email}>{truncateEmail(userEmail, 18)}</div>
      <div className={s.uid}>
        <span>
          {t({
            id: 'profile.uid',
            message: 'UID',
          })}
          : {makeShortString(userId)}
        </span>
        <CopyIcon text={userId} />
      </div>
      <ProfileLevel />
      <TeamWidget className={s.team} />
      {(referrerCode || !referrerLocked) && exchangeType !== EXCHANGES_TYPE.OKX_AFFILIATE ? (
        <ReferralInvitedBy handleUserInfo={handleUserInfo} />
      ) : null}
    </div>
  )
}

export { ProfileLeftBar }
