import { useActiveExchage } from '@/utils/hooks/useActiveExchange'
import { createSafeApi, exchangeApiFactory } from '../exchangeApiFactory'
import { useMemo } from 'react'

export const useExchangeApi = () => {
  const { activeExchange } = useActiveExchage()

  return useMemo(() => {
    return createSafeApi(exchangeApiFactory[activeExchange.type])
  }, [activeExchange.type]) // 🛠 Теперь API пересоздаётся только при изменении `activeExchange.type`
}
