import { t } from '@lingui/macro'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { SVG } from '@/assets/svg'
import { addressActions } from '@/redux/address/address.slice'
import { Button, Hint, usePopperRef } from '@/ui/kit'
import { Popup } from '@/ui/molecules'
import { WhitelistSwitch } from '@/ui/organisms/WhitelistSwitch'
import { useActions, useAppSelector, useFlag } from '@/utils'
import { useGoogleAuthSettings } from '@/utils/hooks'
import style from '../../style.module.scss'

export const WhitelistManage: FC = () => {
  const { getWhitelistTC, postToggleWhitelistPermissionTC, clearWhiteListToggleStatus, setErrors } =
    useActions(addressActions)
  const { whitelistEnabled, whiteListToggleStatus, errors } = useAppSelector(state => state.address)
  const [isOpen, setIsOpen] = useState(false)
  const { enabled: isGoogleAuthEnabled } = useGoogleAuthSettings().settings
  const [ref, setRef] = usePopperRef<HTMLDivElement>()
  const [isVisible, , enableHint, disableHint] = useFlag()
  const modalOpenHandler = () => setIsOpen(prev => !prev)

  useEffect(() => {
    if (whiteListToggleStatus === 'succeeded') {
      getWhitelistTC()
      setIsOpen(false)
    }
    return () => {
      clearWhiteListToggleStatus()
    }
  }, [getWhitelistTC, whiteListToggleStatus, clearWhiteListToggleStatus])

  useEffect(() => {
    getWhitelistTC()
  }, [getWhitelistTC])

  const switchButtonText = !whitelistEnabled
    ? t({ message: 'enable', id: 'profile.whitelist.enable' })
    : t({ message: 'disable', id: 'profile.whitelist.disable' })

  const handleClickRemoveConfirm = useCallback(
    (googleCode: string) => {
      postToggleWhitelistPermissionTC({
        googleCode,
        enabled: !whitelistEnabled,
      })
    },
    [whitelistEnabled, postToggleWhitelistPermissionTC]
  )

  const isConfirmSubmitDisabled = useMemo(() => whiteListToggleStatus === 'loading', [whiteListToggleStatus])
  const whitelistDescriptionText = isGoogleAuthEnabled
    ? t({
        id: 'profile.whitelist.descriptionDefault',
        message:
          'Once this function is enabled, your account will only be able to withdraw to addresses on your whitelist.',
      })
    : t({
        id: 'profile.whitelist.googleAuth',
        message: 'You can enable whitelist after Two-factor authentication is activate',
      })

  return (
    <>
      <Popup isOpen={isOpen}>
        <WhitelistSwitch
          onCancel={modalOpenHandler}
          onSubmit={handleClickRemoveConfirm}
          isSubmitDisabled={isConfirmSubmitDisabled}
          errors={errors}
          setErrors={setErrors}
        />
      </Popup>
      <div className={style.setting}>
        <SVG.Settings.Whitelist className={style.sectionIcon} />
        <div className={style.settingContainer}>
          <div className={style.settingTitle}>
            {t({ id: 'profile.whitelist.titleDefault', message: `Withdrawal Address` })}
          </div>
          <div className={style.settingStatus}>{whitelistDescriptionText}</div>
        </div>
        {whitelistEnabled !== undefined && isGoogleAuthEnabled && (
          <div
            className={style.settingActions}
            ref={setRef}
            onMouseOver={enableHint}
            onFocus={enableHint}
            onMouseOut={disableHint}
            onBlur={disableHint}
          >
            {<Button.Primary label={switchButtonText} onClick={modalOpenHandler} />}
            {isVisible && whitelistEnabled && (
              <Hint
                placement={'bottom'}
                text={t({
                  id: 'profile.whitelist.disable.hint',
                })}
                targetRef={ref}
              />
            )}
          </div>
        )}
      </div>
    </>
  )
}
