import { ApiKeysAPI, IRestRequestGeneric, TCreateApiKeyPayloadTigerX } from '@/backend/api/apiKeyAPI'
import { AxiosResponse } from 'axios'
import { ApiKeyCreationDTO } from '@/backend/models/ApiKeyDTO'
import { IApiWrapper } from '../types'
import { ERROR_API_UNCOMPATIBLE_PAYLOAD } from '@/core/constants/errors/rest'
import { errorAsyncCreator, mapTigerXAccountName, voidFunction, voidPFunction } from '../utils'
import { LeverageApi, OverviewAPI } from '@/backend/api'
import {
  TCancelAllOpenOrdersPayload,
  TCancelAllOpenOrdersResponse,
  TCancelOpenOrderPayloadUnion,
  TCancelOpenOrderResponse,
  TCloseAllPositionsPayload,
  TCloseAllPositionsResponse,
  TClosePositionPayload,
  TClosePositionResponse,
  TCancelOpenOrderPayloadTigerXDTO,
  TCancelOpenOrderResponseTigerXDTO,
  TCancelAllOpenOrdersPayloadTigerXDTO,
  TCancelAllOpenOrdersResponseTigerXDTO,
  TClosePositionPayloadTigerXDTO,
  TClosePositionResponseTigerXDTO,
  TCloseAllPositionsPayloadTigerXDTO,
  TCloseAllPositionsResponseTigerXDTO,
} from '@/backend/models/OverviewDTO'
import {
  TSetLeveragePayloadBybit,
  TSetLeveragePayloadTigerX,
  TSetLeveragePayloadTigerXDTO,
  TSetLeveragePayloadUnion,
  TSetLeverageResponse,
  TSetLeverageResponseTigerX,
} from '@/redux/leverage/leverage.types'
import { TSetMarginPayloadBybit } from '@/backend/models/LeverageDTO'
import {
  TSellAssetPayload,
  TSellAssetPayloadBybit,
  TSellAssetPayloadTigerX,
  TSellAssetPayloadUnion,
  TSellAssetResponse,
  TSellAssetResponseByBit,
} from '@/redux/overview/overview.types'
import { TAccountType, TTigerXExchangeType } from '@/core/types/overview'
import { ETigerXExchangeAccountOriginal } from '@/exchanges/tigerx/tigerx.config'

export const TigerXApiWrapper: IApiWrapper = {
  methods: {
    apiKey: {
      createApiKey: ApiKeysAPI.createApiKey as IRestRequestGeneric<
        TCreateApiKeyPayloadTigerX,
        AxiosResponse<ApiKeyCreationDTO>
      >,
    },
    overview: {
      cancelOpenOrder: OverviewAPI.cancelOpenOrder as IRestRequestGeneric<
        TCancelOpenOrderPayloadTigerXDTO,
        AxiosResponse<TCancelOpenOrderResponseTigerXDTO>
      >,
      cancelAllOpenOrders: OverviewAPI.cancelAllOpenOrders as IRestRequestGeneric<
        TCancelAllOpenOrdersPayloadTigerXDTO,
        AxiosResponse<TCancelAllOpenOrdersResponseTigerXDTO>
      >,
      closePosition: OverviewAPI.closePosition as IRestRequestGeneric<
        TClosePositionPayloadTigerXDTO,
        AxiosResponse<TClosePositionResponseTigerXDTO>
      >,
      closeAllPositions: OverviewAPI.closeAllPositions as IRestRequestGeneric<
        TCloseAllPositionsPayloadTigerXDTO,
        AxiosResponse<TCloseAllPositionsResponseTigerXDTO>
      >,
      setPositionMode: voidFunction,
      setPositionMargin: voidFunction,
      sellAsset: OverviewAPI.sellAsset as IRestRequestGeneric<
        TSellAssetPayloadUnion,
        AxiosResponse<TSellAssetResponseByBit>
      >,
    },
    leverage: {
      setLeverage: LeverageApi.setLeverage as IRestRequestGeneric<
        TSetLeveragePayloadTigerXDTO,
        AxiosResponse<TSetLeverageResponseTigerX>
      >,
      setMargin: voidFunction,
    },
  },
  preformatters: {
    apiKey: {
      createApiKey: (payload: TCreateApiKeyPayloadTigerX) => payload,
    },
    overview: {
      cancelOpenOrder: (payload: TCancelOpenOrderPayloadUnion): TCancelOpenOrderPayloadTigerXDTO => ({
        accountType: mapTigerXAccountName(payload.accountType) as TAccountType,
        orderId: payload.orderId,
        type: payload.isAlgo ? payload.orderTypeOutput : 'SIMPLE',
      }),
      cancelAllOpenOrders: (payload: TCancelAllOpenOrdersPayload): TCancelAllOpenOrdersPayloadTigerXDTO => ({
        accountType: mapTigerXAccountName(payload.accountType) as TAccountType,
        exchangeType: payload.instrumentType.split('_')[0] as TTigerXExchangeType,
      }),
      closePosition: (payload: TClosePositionPayload): TClosePositionPayloadTigerXDTO => {
        return {
          accountType: mapTigerXAccountName(payload.accountType) as TAccountType,
          sym: payload.symbol,
          positionSide: payload.positionSide,
          positionQty: String(payload.positionAmt),
        }
      },
      closeAllPositions: (payload: TCloseAllPositionsPayload): TCloseAllPositionsPayloadTigerXDTO => ({
        accountType: mapTigerXAccountName(payload.accountType) as TAccountType,
        exchangeType: payload.instrumentType.split('_')[0],
      }),
      setPositionMode: voidFunction,
      setPositionMargin: voidFunction,
      sellAsset: (payload: TSellAssetPayload): TSellAssetPayloadTigerX => {
        return {
          accountType: 'TIGER_X',
          orderQty: payload.quantity,
          sym: payload.symbol,
        }
      },
    },
    leverage: {
      setLeverage: (payload: TSetLeveragePayloadTigerX): TSetLeveragePayloadTigerXDTO => {
        return { ...payload, accountType: ETigerXExchangeAccountOriginal.TIGER_X }
      },
      setMargin: (payload: TSetMarginPayloadBybit): TSetMarginPayloadBybit => {
        return payload
      },
    },
  },
  normalizers: {
    apiKey: {
      createApiKey: (source: ApiKeyCreationDTO): ApiKeyCreationDTO => {
        return {
          ...source,
        }
      },
    },
    overview: {
      cancelOpenOrder: (
        source: TCancelAllOpenOrdersResponseTigerXDTO,
        payload: TCancelOpenOrderPayloadUnion
      ): TCancelOpenOrderResponse => {
        return {
          accountType: source.accountType,
          instrumentType: payload.instrumentType,
          orderId: payload.orderId,
          success: source.code === 200000,
          msg: source.message,
        }
      },
      cancelAllOpenOrders: (
        source: TCancelOpenOrderResponseTigerXDTO,
        payload: TCancelOpenOrderPayloadUnion
      ): TCancelAllOpenOrdersResponse => {
        return {
          accountType: source.accountType,
          instrumentType: payload.instrumentType,
          success: source.code === 200000,
          errors: [source.message],
        }
      },
      closePosition: (
        source: TClosePositionResponseTigerXDTO,
        payload: TClosePositionPayload
      ): TClosePositionResponse => {
        return {
          accountType: source.accountType,
          instrumentType: payload.instrumentType,
          positionId: payload.positionId,
          success: source.code === 200000,
          error: source.message,
        }
      },
      closeAllPositions: (
        source: TCloseAllPositionsResponseTigerXDTO,
        payload: TCloseAllPositionsPayload
      ): TCloseAllPositionsResponse => {
        return {
          accountType: source.accountType,
          instrumentType: payload.instrumentType,
          success: source.code === 200000,
          error: source.message,
        }
      },
      setPositionMode: voidFunction,
      setPositionMargin: voidFunction,
      sellAsset: (source: TSellAssetResponseByBit, payload: TSellAssetPayloadUnion): TSellAssetResponse => {
        return {
          accountType: source.accountType,
          msg: source.retMsg,
          code: source.retCode,
        }
      },
    },
    leverage: {
      setLeverage: (source: TSetLeverageResponseTigerX, payload: TSetLeveragePayloadTigerX): TSetLeverageResponse => {
        return {
          accountType: source.accountType,
          leverage: Number(payload.leverage),
          symbol: payload.sym,
          originalSymbol: payload.originalSymbol,
        }
      },
      setMargin: voidFunction,
    },
  },
  checkers: {
    apiKey: {
      createApiKey: (payload: any): payload is TCreateApiKeyPayloadTigerX => {
        return 'spotEnabled' in payload
      },
    },
    overview: {
      cancelOpenOrder: (payload: TCancelOpenOrderPayloadTigerXDTO) => {
        return 'orderId' in payload
      },
      cancelAllOpenOrders: (payload: TCancelAllOpenOrdersPayloadTigerXDTO) => {
        return 'accountType' in payload
      },
      closePosition: (payload: TClosePositionPayloadTigerXDTO) => {
        return 'symbol' in payload
      },
      closeAllPositions: (payload: TCloseAllPositionsPayloadTigerXDTO) => {
        return 'accountType' in payload
      },
      setPositionMode: voidFunction,
      setPositionMargin: voidFunction,
      sellAsset: voidPFunction,
    },
    leverage: {
      setLeverage: (payload: TSetLeveragePayloadUnion): payload is TSetLeveragePayloadTigerX => {
        return 'accountType' in payload
      },
      setMargin: (payload: any): payload is TSetLeveragePayloadBybit => {
        return 'setMarginMode' in payload
      },
    },
  },
  caller: {
    apiKey: {
      createApiKey: async payload => {
        if (TigerXApiWrapper.checkers.apiKey.createApiKey(payload)) {
          const res = await TigerXApiWrapper.methods.apiKey.createApiKey(payload)
          return {
            ...res,
            data: TigerXApiWrapper.normalizers.apiKey.createApiKey(res.data),
          }
        }
        return errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
    },
    overview: {
      cancelOpenOrder: async data => {
        if (TigerXApiWrapper.checkers.overview.cancelOpenOrder(data)) {
          const payload = TigerXApiWrapper.preformatters.overview.cancelOpenOrder(data)

          const res = await TigerXApiWrapper.methods.overview.cancelOpenOrder(payload)
          return {
            ...res,
            data: TigerXApiWrapper.normalizers.overview.cancelOpenOrder(res.data, data),
          }
        }
        return errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
      cancelAllOpenOrders: async data => {
        if (TigerXApiWrapper.checkers.overview.cancelAllOpenOrders(data)) {
          const payload = TigerXApiWrapper.preformatters.overview.cancelAllOpenOrders(data)
          const res = await TigerXApiWrapper.methods.overview.cancelAllOpenOrders(payload)
          return {
            ...res,
            data: TigerXApiWrapper.normalizers.overview.cancelAllOpenOrders(res.data, data),
          }
        }
        return errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
      closePosition: async data => {
        if (TigerXApiWrapper.checkers.overview.closePosition(data)) {
          const payload = TigerXApiWrapper.preformatters.overview.closePosition(data)
          const res = await TigerXApiWrapper.methods.overview.closePosition(payload)
          return {
            ...res,
            data: TigerXApiWrapper.normalizers.overview.closePosition(res.data, data),
          }
        }
        return errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
      closeAllPositions: async data => {
        if (TigerXApiWrapper.checkers.overview.closeAllPositions(data)) {
          const payload = TigerXApiWrapper.preformatters.overview.closeAllPositions(data)

          const res = await TigerXApiWrapper.methods.overview.closeAllPositions(payload)
          return {
            ...res,
            data: TigerXApiWrapper.normalizers.overview.closeAllPositions(res.data, data),
          }
        }
        return errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
      setPositionMode: async (): Promise<any> => {},
      setPositionMargin: async payload => {
        if (TigerXApiWrapper.checkers.overview.setPositionMargin(payload)) {
          const res = await TigerXApiWrapper.methods.overview.setPositionMargin(payload)
          return res
        }
        throw errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
      sellAsset: async data => {
        const payload = TigerXApiWrapper.preformatters.overview.sellAsset(data)

        if (TigerXApiWrapper.checkers.overview.sellAsset(payload)) {
          const res = await TigerXApiWrapper.methods.overview.sellAsset(payload)

          return {
            ...res,
            data: TigerXApiWrapper.normalizers.overview.sellAsset(res.data, data),
          }
        }
        throw errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
    },
    leverage: {
      setLeverage: async data => {
        if (TigerXApiWrapper.checkers.leverage.setLeverage(data)) {
          const payload = TigerXApiWrapper.preformatters.leverage.setLeverage(data)
          const res = await TigerXApiWrapper.methods.leverage.setLeverage(payload)
          return {
            ...res,
            data: TigerXApiWrapper.normalizers.leverage.setLeverage(res.data, payload),
          }
        }
        return errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
      setMargin: async data => {
        if (TigerXApiWrapper.checkers.leverage.setMargin(data)) {
          const res = await TigerXApiWrapper.methods.leverage.setMargin(data)
          return {
            ...res,
            data: TigerXApiWrapper.normalizers.leverage.setMargin(res.data, data),
          }
        }
        return errorAsyncCreator(ERROR_API_UNCOMPATIBLE_PAYLOAD)
      },
    },
  },
}
