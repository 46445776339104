import { FC, ReactNode } from 'react'
import { useReferralHandler } from './hooks/useReferralHandler'
import { useKeitaroHandler } from './hooks/useKeitaroHandlers'
import { useExchangeAddParamsHandler } from './hooks/useExchangeAddParamsHandler'

interface IDataProviderProps {
  children: ReactNode
}

const UrlParamsProvider: FC<IDataProviderProps> = props => {
  // Обработка реферального кода
  useReferralHandler()

  // Обработка Keitaro ID
  useKeitaroHandler()

  // Добавляет параметры биржи если открыть сайт в новой вкладке TO DO in P2-539
  // useExchangeAddParamsHandler()

  return <>{props.children}</>
}

export { UrlParamsProvider }
