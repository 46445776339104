import { ExchangeTypeConfig } from '../exchage.types'
import { urls } from '@/router/urls'
import { EAccountType } from '@tigertrade/binance-ts'
import { EOkxInstruments } from '@/services/okx'

export const OkxAffiliateConfig: ExchangeTypeConfig = {
  name: 'OKX Affiliate',
  type: 'OKX_AFFILIATE',
  instruments: [EAccountType.SPOT, EOkxInstruments.SWAP],
  accounts: ['OKX_TRADING'],
  isAvailable: true,
  hasSubExchanges: false,
  pages: {
    profile: {
      bnbBurn: false,
      convertSmallBalance: false,
      assetMode: false,
      positionMode: false,
      additionalInfo: true,
    },
    api: {
      availableKeys: 5,
    },
    chooseExchange: {
      isAvailable: true,
      percent: 45,
    },
  },
  redirect: {
    redirectURL: urls.okxAffiliate,
    blockedPath: [urls.riskManager, '/journal'],
  },
  blockedNavMenu: [urls.wallet, urls.orders, urls.cashback, urls.leverage, urls.api, urls.historyTrade, urls.journal],
  transactions: {
    transfer: {
      isAvailable: false,
    },
    deposit: {
      isAvailable: false,
      minDeposite: null,
    },
    withdraw: {
      isAvailable: false,
      minWithdraw: 10,
    },
  },
}
