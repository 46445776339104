import React from 'react'

interface Props {
  fill?: string
}

export const ArrowSort = ({ fill }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={7} height={3} fill="none">
      <path
        fill={fill ?? '#676573'}
        d="M2.911 2.871a.607.607 0 0 0 .749 0L6.087.928c.4-.32.155-.928-.374-.928H.858C.33 0 .084.608.484.928L2.911 2.87Z"
      />
    </svg>
  )
}
