import { useCallback, useMemo } from 'react'
import { formatNumber, useAppSelector } from '@/utils'
import { AccountOverviewAssets } from '@/backend/models/OverviewDTO'
import { OVERVIEW_ACCOUNT_SYMBOLS_MAP } from '@/redux/overview/overview.defaults'
import { truncateNumber } from '@/utils/lib/truncateNumber'
import BigNumber from 'bignumber.js'
import { useExchangesDisabled } from '@/utils/hooks/useExchangesDisabled'
import { getMaximalSellingValue } from '@/utils/lib/getMaximalSellingValue'

export const useSellConnexAsset = (asset: AccountOverviewAssets) => {
  const { accountTypeForInstrument, accountType } = useAppSelector(state => state.overview)
  const { symData } = useAppSelector(state => state.tigerX)
  const { isSellAssetEnabled } = useExchangesDisabled()
  const exchangeInfo = Object.values(symData ?? {})
  const accountTypeSymbol = exchangeInfo?.filter((sym: any) =>
    sym.sym.includes(accountType === 'TIGER_X_OKX' ? 'OKX' : 'BINANCE')
  )
  const findTigerSymbol: any = accountTypeSymbol?.filter(
    (sym: any) => sym.originalSymbol.includes(asset.asset) && sym.sym.includes('SPOT')
  )
  const assetFilters = findTigerSymbol?.find((assetFilter: any) =>
    accountType === 'TIGER_X_BINANCE'
      ? assetFilter?.originalSymbol.includes(`${asset.asset}USDT`)
      : assetFilter?.originalSymbol.includes(`${asset.asset}-USDT`)
  )
  const stepConnexSize = Number(assetFilters?.lotSize ?? 0)
  const notionalSizeUSDT = Number(assetFilters?.minNotional ?? 0)
  const nationalCoinSize = Number(assetFilters?.minSize ?? 0)
  const getAssetStepSizeAndNotional = useCallback(() => {
    return { stepSize: stepConnexSize, notionalUSDT: notionalSizeUSDT, notionalCoin: nationalCoinSize }
  }, [accountType])

  const {
    equivalentValue,
    maximumSellingValue,
    stepSize,
    notional,
    isNotEnoughCoin,
    balance,
    balanceInAccountCoin,
    stableCoinSelector,
    notionalCoin,
  } = useMemo(() => {
    if (accountTypeForInstrument === undefined)
      return {
        equivalentValue: 0,
        maximumSellingValue: 0,
        assetPrice: 0,
        notional: 0,
        stepSize: 0,
        isNotEnoughCoin: true,
        balance: '',
        balanceInAccountCoin: 0,
      }

    const balance = Number(asset.balance || 0)
    const balanceInAccountCoinNumber =
      asset.quoteBalance[OVERVIEW_ACCOUNT_SYMBOLS_MAP[accountTypeForInstrument].selectorOutput] || 0
    const stableCoinSelector = OVERVIEW_ACCOUNT_SYMBOLS_MAP[accountTypeForInstrument].selectorOutput
    const assetPrice = new BigNumber(balanceInAccountCoinNumber).dividedBy(balance).toNumber()
    const { stepSize, notionalUSDT, notionalCoin } = getAssetStepSizeAndNotional()

    const maximumSellingValue = getMaximalSellingValue(Number(asset.balance), stepSize)
    const equivalentValue = truncateNumber(new BigNumber(maximumSellingValue * assetPrice).toNumber())
    const isNotEnoughCoin =
      accountType === 'TIGER_X_BINANCE' ? equivalentValue < notionalUSDT : maximumSellingValue < notionalCoin

    return {
      equivalentValue,
      maximumSellingValue,
      assetPrice,
      notional: notionalUSDT,
      stepSize,
      isNotEnoughCoin,
      balance: formatNumber(balance),
      balanceInAccountCoin: new BigNumber(balanceInAccountCoinNumber).toString(),
      stableCoinSelector,
      notionalCoin,
    }
  }, [accountTypeForInstrument, asset.balance])

  const quantity = maximumSellingValue

  const symbol = assetFilters?.originalSymbol

  return {
    equivalentValue,
    maximumSellingValue,
    notional,
    isNotEnoughCoin,
    quantity,
    symbol: symbol as any,
    canSellAsset: isSellAssetEnabled,
    balance,
    balanceInAccountCoin,
    stableCoinSelector,
    minCoin: notionalCoin,
  }
}
