import React, { FC } from 'react'
import { t } from '@lingui/macro'
import { TitleH1 } from '@/ui/atoms/TitleH1'
import { ReferralInvitedBy } from '@/ui/molecules/ReferralInvitedBy'
import { useAppSelector } from '@/utils'
import s from './style.module.scss'
import { Button } from '@/ui/kit'
import { useNavigate } from 'react-router-dom'
import { urls } from '@/router/urls'

const ReferralLeftBar: FC = () => {
  const { overview } = useAppSelector(state => state.referrals)
  const { invitedTotal, invitedMonth, referrerCode, referrerLocked } = overview
  const navigate = useNavigate()

  return (
    <aside className={s.root}>
      <TitleH1 label={t({ id: 'referralPage.heading', message: 'Your referral program' })} />

      <section className={s.invited}>
        <div className={s.invitedTitle}>{t({ id: 'referralPage.youInvited', message: 'You invited' })}</div>
        <div className={s.invitedCount}>
          {invitedTotal}
          {invitedMonth > 0 && (
            <div className={s.invitedMonth}>
              +{invitedMonth} {t({ id: 'referralPage.invitedMonth', message: 'this month' })}
            </div>
          )}
        </div>
      </section>

      <div className={s.transactions}>
        <Button.Primary
          onClick={() => {
            navigate(urls.referralEarnings)
          }}
          label={t({
            id: 'referralPage.statistics.title',
          })}
          className={s.transactionsBtn}
        />
      </div>

      {referrerCode || !referrerLocked ? <ReferralInvitedBy /> : null}
    </aside>
  )
}

export { ReferralLeftBar }
