import { googleAuthAPI } from '@/backend/api'
import { urls } from '@/router/urls'
import { Loader } from '@/ui/kit/Loader'
import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// component to activate TigerX for user by hyperlink
export const TigerXActivcation: FC = () => {
  const navigate = useNavigate()
  useEffect(() => {
    const loadData = async () => {
      await googleAuthAPI.activateTigerX()
      window.location.href = urls.root
    }

    loadData()
  }, [])

  return <Loader />
}
