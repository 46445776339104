import nameof from 'ts-nameof.macro'
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  ReferralOverviewResponseDTO,
  ApplyReferralCodeRequestDTO,
  CreateReferralCodeRequestDTO,
  CreateReferralCodeResponseDTO,
  UpdateReferralCodeRequestDTO,
  UpdateReferralCustomCodeRequestDTO,
  GetReferralCodeInfoDTO,
  TGetReferralExchangeSectionsDTO,
  TGetReferralEarningsPayload,
  TReferralEarningsExportDTO,
} from '@/backend/models/ReferralsDTO'
import { ThunkError } from '@/types'
import { ReferralsApi } from '@/backend/api'
import { ThunkCreator } from '@/utils/lib/ThunkCreator'

export const GetReferralOverviewTC = createAsyncThunk<ReferralOverviewResponseDTO, void, ThunkError>(
  nameof(ReferralsApi.getReferralOverview),
  async (payload, thunkAPI) => {
    return await ThunkCreator<ReferralOverviewResponseDTO>(
      { apiMethod: ReferralsApi.getReferralOverview, payload },
      thunkAPI
    )
  }
)

export const GetReferralCodeInfoTC = createAsyncThunk<GetReferralCodeInfoDTO, string, ThunkError>(
  nameof(ReferralsApi.getReferralCodeInfo),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ReferralsApi.getReferralCodeInfo, payload }, thunkAPI)
  }
)

export const ApplyReferralCodeTC = createAsyncThunk<void, ApplyReferralCodeRequestDTO, ThunkError>(
  nameof(ReferralsApi.applyReferralCode),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ReferralsApi.applyReferralCode, payload }, thunkAPI)
  }
)

export const UpdateReferralCodeTC = createAsyncThunk<void, UpdateReferralCodeRequestDTO, ThunkError>(
  nameof(ReferralsApi.updateReferralCode),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ReferralsApi.updateReferralCode, payload }, thunkAPI)
  }
)

export const UpdateCustomReferralCodeTC = createAsyncThunk<void, UpdateReferralCustomCodeRequestDTO, ThunkError>(
  nameof(ReferralsApi.updateCustomReferralCode),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ReferralsApi.updateCustomReferralCode, payload }, thunkAPI)
  }
)

export const CreateReferralCodeTC = createAsyncThunk<
  CreateReferralCodeResponseDTO,
  CreateReferralCodeRequestDTO,
  ThunkError
>(nameof(ReferralsApi.createReferralCode), async (payload, thunkAPI) => {
  return await ThunkCreator({ apiMethod: ReferralsApi.createReferralCode, payload }, thunkAPI)
})

export const DeleteReferralCodeTC = createAsyncThunk<void, string, ThunkError>(
  nameof(ReferralsApi.deleteReferralCode),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ReferralsApi.deleteReferralCode, payload }, thunkAPI)
  }
)

export const GetReferralExchangeSectionListTC = createAsyncThunk<TGetReferralExchangeSectionsDTO, void, ThunkError>(
  nameof(ReferralsApi.getReferralExchangeSectionList),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: ReferralsApi.getReferralExchangeSectionList, payload }, thunkAPI)
  }
)

export const ReferralEarningsExportTC = createAsyncThunk<
  TReferralEarningsExportDTO,
  TGetReferralEarningsPayload,
  ThunkError
>(nameof(ReferralsApi.referallEarningsExport), async (payload, thunkAPI) => {
  return await ThunkCreator({ apiMethod: ReferralsApi.referallEarningsExport, payload }, thunkAPI)
})

export const GetReferralEarningsTC = createAsyncThunk<
  Awaited<ReturnType<typeof ReferralsApi.getReferralEarnings>>['data'],
  Parameters<typeof ReferralsApi.getReferralEarnings>[0],
  ThunkError
>(nameof(ReferralsApi.getReferralEarnings), async (payload, thunkAPI) => {
  return await ThunkCreator({ apiMethod: ReferralsApi.getReferralEarnings, payload }, thunkAPI)
})

export const GetReferralBenefitsTC = createAsyncThunk<
  Awaited<ReturnType<typeof ReferralsApi.getBenefits>>['data'],
  Parameters<typeof ReferralsApi.getBenefits>[0],
  ThunkError
>(nameof(ReferralsApi.getBenefits), async (payload, thunkAPI) => {
  return await ThunkCreator({ apiMethod: ReferralsApi.getBenefits, payload }, thunkAPI)
})
