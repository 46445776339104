import { Auth0Provider } from '@auth0/auth0-react'
import React, { useCallback } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { INIT_SEARCH_PARAM, urls } from '@/router/urls'
import { AuthHandlerProvider } from './AuthHandlerProvider'

export interface IAuthProviderProps {
  children: React.ReactNode
}

const AuthProvider: React.FC<IAuthProviderProps> = props => {
  // const navigate = useNavigate()
  const { pathname } = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()

  const handleRedirectCallback = useCallback(() => {
    if (!searchParams.has(INIT_SEARCH_PARAM)) {
      searchParams.set(INIT_SEARCH_PARAM, 'true')
      setSearchParams(searchParams)
    }
  }, [searchParams, setSearchParams])

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      onRedirectCallback={handleRedirectCallback}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'openid profile email offline_access',
        screen_hint: pathname.startsWith(urls.signup) ? 'signup' : undefined,
      }}
      useRefreshTokens
    >
      <AuthHandlerProvider>{props.children}</AuthHandlerProvider>
    </Auth0Provider>
  )
}

export { AuthProvider }
