import { BinanceApi } from './binance/binance.api'
// import { BinanceVipApi } from './binanceVip/binanceVip.api'
import { BybitApi } from './bybit/bybit.api'
import { OkxAffiliateApi } from './okxAffiliate/okxAffiliate.api'
import { TigerXApi } from './tigerx/tegerx.api'

export const exchangeApiFactory: Record<string, any> = {
  BINANCE_BROKER_FUTURE: BinanceApi,
  BINANCE_VIP: BinanceApi,
  BYBIT_BROKER: BybitApi,
  // OKX_AFFILIATE: OkxAffiliateApi,
  TIGER_X: TigerXApi,
}

// Объект-заглушка для API (если метода нет, он просто логирует)
export const createSafeApi = (apiInstance: any) => {
  return new Proxy(apiInstance, {
    get(target, prop) {
      if (prop in target) {
        return target[prop]
      }
      return () => {
        console.warn(`Metod ${String(prop)} not supported for this exhange - safeApi`)
        return Promise.resolve(null) // Возвращает пустое значение, а не ломает код
      }
    },
  })
}
