import clsx from 'clsx'
import style from './style.module.scss'

import { FC } from 'react'
import { useAppSelector } from '@/utils'
import { ETigerXInstruments } from '@/exchanges/tigerx/tigerx.config'
import { QuestionButton } from '@/ui/atoms/QuestionButton'
import { Trans } from '@lingui/react'
import { t } from '@lingui/macro'
import { formatUniMMR, formatUpnlValue, getFilteredAccountData } from './utils'

const OrdersPageWidgetTigerX: FC = () => {
  const { account } = useAppSelector(state => state.tigerX)
  const { instrumentType } = useAppSelector(state => state.overview)
  const filteredData = getFilteredAccountData(account, instrumentType)
  const balanceUsdt = Number(filteredData?.equity ?? 0) - Number(filteredData?.upnl ?? 0)

  return (
    <div className={style.balanceInfo}>
      <div className={style.itemBlock}>
        <div className={clsx(style.head)}>
          <div className={style.hintBlock}>
            <div className={clsx(style.hintTitle)}>
              {t({
                id: 'orders.tigerX.balance',
              })}
            </div>
            <div className={style.hint}>
              <QuestionButton text={<Trans id="orders.tigerX.balance.hint" />} />
            </div>
          </div>
        </div>
        <div className={clsx(style.price, style.balance, style.priceInfo)}>
          {Number(filteredData?.equity ?? 0).toFixed(2)}
        </div>
      </div>
      <div className={style.itemBlock}>
        <div className={style.head}>
          {t({
            id: 'orders.tigerX.availableMargin',
            message: 'Available margin',
          })}
          <div className={style.hintMargin}>
            <QuestionButton text={<Trans id="orders.tigerX.availableMargin.hint" />} />
          </div>
        </div>
        <div className={style.priceInfo}>{Number(filteredData?.availableMargin ?? 0).toFixed(2)}</div>
      </div>
      <div className={style.itemBlock}>
        <div className={style.head}>
          {t({
            id: 'orders.tigerX.balance.usdt',
            message: 'Balance, USDT',
          })}
        </div>
        <div className={style.priceInfo}>{formatUpnlValue(balanceUsdt.toFixed(2))}</div>
      </div>
      <div className={style.itemBlock}>
        <div className={style.head}>
          {t({
            id: 'orders.tigerX.unrealizedPnl',
          })}
        </div>
        <div className={style.priceInfo}>{formatUpnlValue(Number(filteredData?.upnl ?? 0).toFixed(2))}</div>
      </div>
      <div className={style.itemBlock}>
        <div className={clsx(style.head)}>
          <div className={style.hintBlock}>
            <div className={style.hintTitle}>
              {t({
                id: 'orders.tigerX.uniMmr',
              })}
            </div>
            <QuestionButton
              text={
                <Trans
                  id="orders.tigerX.uniMmr.hint"
                  components={{
                    br: <br />,
                  }}
                />
              }
            />
          </div>
        </div>
        <div className={clsx(style.priceInfo, style.unimmr)}>{formatUniMMR(filteredData?.uniMMR ?? '0')}</div>
      </div>
    </div>
  )
}

export { OrdersPageWidgetTigerX }
