import { axiosMainService } from '@/services/axios'
import { BalanceAsset, TransactionAssets, WithdrawAndDepositAssets } from '@/backend/api'
import { KYCAvailableLimitsDTO } from '@/backend/models/KycDTO'
import { GetWhitelistPayload } from '@/backend/api/addressAPI'
import { WalletAddress } from './types'

const cryptoAPIURL = 'binance-broker-crypto/protected/api/v1/'
const universalAPIURL = 'binance-broker-universal/protected/api/v1/'
const transferApiURL = 'binance-broker-transfer/protected/api/v1/'

export const tigerBackRest = {
  async getCoins() {
    return await axiosMainService.get<WithdrawAndDepositAssets>(`${cryptoAPIURL}coins`)
  },

  async getCoinsBalance() {
    return await axiosMainService.get<BalanceAsset[]>(`${cryptoAPIURL}assets/spot`)
  },

  async getAssetsForTransactions() {
    return await axiosMainService.get<TransactionAssets>(`${universalAPIURL}assets`)
  },

  async getAvailableLimitsKYC(payload?: string) {
    return await axiosMainService.get<KYCAvailableLimitsDTO>(
      `${transferApiURL}limits/available?currency=${payload || 'CHF'}`
    )
  },

  async getWithdrawAddress() {
    return await axiosMainService.get<WalletAddress[]>(`${cryptoAPIURL}wallets/withdraw`)
  },

  async getWhiteList() {
    return await axiosMainService.get<GetWhitelistPayload>(`${cryptoAPIURL}wallets/whitelist`)
  },

  async getWithdraw() {
    return await axiosMainService.get<any>(`${cryptoAPIURL}withdraw`, {})
  },
}
