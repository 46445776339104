import { InfoListItem } from '@/ui/organisms/InfoListItem'
import style from './style.module.scss'
import { Button, Checkbox, ButtonVariant, ButtonSize } from '@/ui/kit'
import { FC, useState } from 'react'
import { t } from '@lingui/macro'

type EnableWhiteListProps = {
  setStep: React.Dispatch<React.SetStateAction<number>>
  onClose: (() => void) | undefined
}

export const EnableWhiteList: FC<EnableWhiteListProps> = ({ setStep, onClose }) => {
  const [isCheked, setIsChecked] = useState(false)

  const checkHandler = () => {
    setIsChecked(pre => !pre)
  }
  return (
    <div className={style.container}>
      <div className={style.headContnet}>
        <div className={style.title}>
          {t({
            id: 'profile.whitelist.enable.info.title',
          })}
        </div>
        <InfoListItem
          text={t({
            id: 'profile.whitelist.enable.description.first',
          })}
        />
        <InfoListItem
          text={t({
            id: 'profile.whitelist.enable.description.second',
          })}
        />
        <InfoListItem
          text={t({
            id: 'profile.whitelist.enable.description.third',
          })}
        />
      </div>
      <div className={style.btnContainer}>
        <Checkbox variant="square" checked={isCheked} className={style.checkboxItem} onChange={checkHandler}>
          {t({
            id: 'profile.whitelist.enable.checkbox',
          })}
        </Checkbox>
        <div className={style.btns}>
          <Button variant={ButtonVariant.Primary} size={ButtonSize.Medium} onClick={onClose}>
            {t({
              id: 'core.return',
              message: `Return`,
            })}
          </Button>
          <Button
            variant={ButtonVariant.Accent}
            size={ButtonSize.Medium}
            disabled={!isCheked}
            onClick={() => setStep(2)}
          >
            {t({
              id: 'profile.whitelist.next',
              message: `next`,
            })}
          </Button>
        </div>
      </div>
    </div>
  )
}
