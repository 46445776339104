import { Button, ButtonSize, Input, InputSize, Select } from '@/ui/kit'
import styles from './style.module.scss'
import { t } from '@lingui/macro'
import { TitleH3 } from '@/ui/atoms/TitleH3'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { BinanceConvertPopup } from './BinanceConvertPopup/BinanceConvertPopup'
import { SVG } from '@/assets/svg'
import { useActions, useAppSelector, useToggle } from '@/utils'
import overviewActions from '@/redux/overview/overview.actions'
import { PreviewData, VALID_TIME, defaultAmount } from './helpers'
import { Loader } from '@/ui/kit/Loader'
import { debounce } from 'lodash'

export const BinanceConvert = () => {
  const { binanceConvert, balances } = useAppSelector(state => state.overview)
  const [amountConvert, setAmountConvert] = useState(defaultAmount)
  const { GetBinanceConvertExchangeInfoTC, GetConvertQuoteTC } = useActions(overviewActions)

  const [isOpenPopup, togglePopup] = useToggle(false)

  const [errorMessage, setErrorMessage] = useState('')
  const [preview, setPreview] = useState<PreviewData>({
    fromAsset: '',
    toAsset: '',
    fromAmount: '',
    validTime: VALID_TIME,
  })
  const amountConvertRef = useRef(amountConvert)
  const assets = balances.BINANCE_SPOT.assets

  useEffect(() => {
    GetBinanceConvertExchangeInfoTC({})
  }, [])

  useEffect(() => {
    if (binanceConvert.previewInfo && binanceConvert.previewInfo.toAmount) {
      updateAmountConvert('to', 'value', binanceConvert.previewInfo.toAmount)
    }
  }, [binanceConvert.previewInfo])

  useEffect(() => {
    amountConvertRef.current = amountConvert
  }, [amountConvert])

  // default pair btc > usdt
  useEffect(() => {
    if (binanceConvert.coins.length > 0 && !amountConvert.from.symbol && !amountConvert.to.symbol) {
      const defaultPair = binanceConvert.coins.find(pair => pair.fromAsset === 'BTC' && pair.toAsset === 'USDT')

      if (defaultPair) {
        const { fromAsset, toAsset, fromAssetMinAmount, fromAssetMaxAmount, toAssetMinAmount, toAssetMaxAmount } =
          defaultPair

        // Найти баланс для "from"
        const fromAssetData = assets.find(asset => asset.asset === fromAsset)
        const fromBalance = fromAssetData?.balance || '0'

        // Установить значения для amountConvert
        setAmountConvert({
          from: {
            value: '',
            symbol: fromAsset,
            balance: fromBalance,
            placeholder: `${fromAssetMinAmount} - ${fromAssetMaxAmount}`,
            minAmount: parseFloat(fromAssetMinAmount), // Сохраняем минимальное значение
            maxAmount: parseFloat(fromAssetMaxAmount),
          },
          to: {
            value: '',
            symbol: toAsset,
            balance: toAssetMaxAmount,
            placeholder: `${toAssetMinAmount} - ${toAssetMaxAmount}`,
          },
        })
      }
    }
  }, [binanceConvert.coins, assets, amountConvert])

  const trError = {
    less: t({
      id: 'binance.convert.error.less',
      comment: 'Value must be at least',
    }),
    more: t({
      id: 'binance.convert.error.more',
      comment: 'Value must not exceed',
    }),
    moreBalance: t({
      id: 'binance.convert.error.moreBalance',
      comment: 'Value more than available balance',
    }),
  }

  const toOptions = useMemo(() => {
    if (!amountConvert.from.symbol) return []
    return binanceConvert.coins
      .filter(pair => pair.fromAsset === amountConvert.from.symbol)
      .map(pair => ({ value: pair.toAsset, label: pair.toAsset }))
  }, [binanceConvert.coins, amountConvert.from.symbol])

  const uniqueFromCoins = useMemo(() => {
    const uniqueAssets = new Set()
    return binanceConvert.coins
      .filter(coin => {
        if (!uniqueAssets.has(coin.fromAsset)) {
          uniqueAssets.add(coin.fromAsset)
          return true
        }
        return false
      })
      .map(coin => ({
        value: coin.fromAsset,
        search: coin.fromAsset,
        label: coin.fromAsset,
        selected: coin.fromAsset,
      }))
  }, [binanceConvert.coins])

  const debouncedUpdateAmountRate = useCallback(
    debounce(value => {
      const currentAmountConvert = amountConvertRef.current
      if (
        parseFloat(value) > 0 &&
        currentAmountConvert.from.symbol &&
        currentAmountConvert.to.symbol &&
        parseFloat(value) <= parseFloat(currentAmountConvert.from.balance)
      ) {
        GetConvertQuoteTC({
          fromAsset: currentAmountConvert.from.symbol,
          toAsset: currentAmountConvert.to.symbol,
          fromAmount: value,
          validTime: VALID_TIME,
        })
      }
    }, 500),
    []
  )

  function updateAmountRate(value: string, symbol: string) {
    if (
      parseFloat(value) > 0 &&
      amountConvert.from.symbol &&
      symbol &&
      parseFloat(value) <= parseFloat(amountConvert.from.balance)
    ) {
      GetConvertQuoteTC({
        fromAsset: amountConvert.from.symbol,
        toAsset: symbol,
        fromAmount: value,
        validTime: VALID_TIME,
      })
    }
  }

  function updateAmountConvert(field: 'from' | 'to', key: 'value' | 'symbol', value: any) {
    setAmountConvert(prev => ({
      ...prev,
      [field]: {
        ...prev[field],
        [key]: value,
      },
    }))
  }

  function setAmountFrom(value: string) {
    const numericValue = parseFloat(value)

    const minAmount = amountConvert.from.minAmount || 0
    const maxAmount = amountConvert.from.maxAmount || Infinity

    // Проверка на диапазон значений
    if (numericValue < minAmount) {
      setErrorMessage(`${trError.less} ${minAmount}`)
    } else if (numericValue > maxAmount) {
      setErrorMessage(`${trError.more} ${maxAmount}`)
    } else if (numericValue > parseFloat(amountConvert.from.balance)) {
      setErrorMessage(`${trError.moreBalance} ${amountConvert.from.balance}`)
    } else {
      setErrorMessage('') // Очистка сообщения об ошибке, если значение в пределах диапазона
    }

    updateAmountConvert('from', 'value', value)
    debouncedUpdateAmountRate(value)
  }

  function handleChangeFrom(coin: string) {
    const selectedAsset = assets.find(asset => asset.asset === coin)
    const balance = selectedAsset ? selectedAsset.balance : '0'

    const selectedPair = binanceConvert.coins.find(pair => pair.fromAsset === coin)
    const minAmount = selectedPair ? selectedPair.fromAssetMinAmount : ''
    const maxAmount = selectedPair ? selectedPair.fromAssetMaxAmount : ''
    const placeholder = minAmount && maxAmount ? `${minAmount} - ${maxAmount}` : ''

    setAmountConvert({
      from: {
        ...amountConvert.from,
        value: '',
        symbol: coin,
        balance: balance, // Устанавливаем найденный баланс
        placeholder: placeholder,
        minAmount,
        maxAmount,
      },
      to: {
        ...amountConvert.to,
        value: '',
        symbol: '',
        balance: '0',
        placeholder: '',
      },
    })

    setErrorMessage('')
  }

  function handleChangeTo(coin: string) {
    updateAmountConvert('to', 'symbol', coin)

    const selectedPair = binanceConvert.coins.find(
      pair => pair.fromAsset === amountConvert.from.symbol && pair.toAsset === coin
    )
    const maxAmount = selectedPair ? selectedPair.toAssetMaxAmount : '0'
    const minAmount = selectedPair ? selectedPair.toAssetMinAmount : ''
    const placeholder = minAmount && maxAmount ? `${minAmount} - ${maxAmount}` : '0'

    // Обновляем balance в amountConvert.to
    setAmountConvert(prev => ({
      ...prev,
      to: {
        ...prev.to,
        symbol: coin,
        value: '',
        balance: maxAmount, // Устанавливаем найденный maxAmount
        placeholder: placeholder, // Устанавливаем placeholder с диапазоном
      },
    }))

    updateAmountRate(amountConvert.from.value, coin)
  }

  function openPopup() {
    setPreview({
      fromAsset: amountConvert.from.symbol ? amountConvert.from.symbol : '',
      toAsset: amountConvert.to.symbol ? amountConvert.to.symbol : '',
      fromAmount: amountConvert.from.value,
      validTime: VALID_TIME,
    })
    togglePopup()
  }

  function closePopup() {
    togglePopup()
  }

  function handleSetMax() {
    const balance = parseFloat(amountConvert.from.balance) // Баланс пользователя
    const maxAmount = amountConvert.from.maxAmount || Infinity // Максимально допустимое значение
    const minAmount = amountConvert.from.minAmount || 0 // Минимально допустимое значение

    // Устанавливаем значение только из баланса
    setAmountConvert(prev => ({
      ...prev,
      from: {
        ...prev.from,
        value: balance.toString(),
      },
    }))

    // Проверка и установка ошибки
    if (balance < minAmount) {
      setErrorMessage(`${trError.less} ${minAmount}`) // Ошибка: меньше минимального значения
    } else if (balance > maxAmount) {
      setErrorMessage(`${trError.more} ${maxAmount}`) // Ошибка: больше максимального значения
    } else {
      setErrorMessage('')
    }

    debouncedUpdateAmountRate(balance.toString())
  }

  function handleChange() {
    const fromSymbol = amountConvert.to.symbol
    const toSymbol = amountConvert.from.symbol

    // Получаем баланс нового "from" из assets
    const fromBalance = assets.find(asset => asset.asset === fromSymbol)?.balance || '0'

    // Ищем прямую пару для "from" -> "to"
    const selectedPair = binanceConvert.coins.find(pair => pair.fromAsset === fromSymbol && pair.toAsset === toSymbol)

    // Устанавливаем поля "to" в зависимости от наличия пары
    setAmountConvert({
      from: {
        ...amountConvert.to,
        value: '',
        balance: fromBalance,
      },
      to: {
        ...amountConvert.from,
        value: '',
        symbol: selectedPair ? toSymbol : '',
        balance: selectedPair ? selectedPair.toAssetMaxAmount : '0',
        placeholder: selectedPair ? `${selectedPair.toAssetMinAmount} - ${selectedPair.toAssetMaxAmount}` : '',
      },
    })

    setErrorMessage('')
  }

  // disabled buttons
  const isMaxButtonDisabled = amountConvert.from.balance === '0'
  const isChangeButtonDisabled = !(amountConvert.from.symbol && amountConvert.to.symbol)
  const isPreviewDisabled =
    parseFloat(amountConvert.from.value) < (amountConvert.from.minAmount || 0) || // Меньше минимального значения
    parseFloat(amountConvert.from.value) > (amountConvert.from.maxAmount || Infinity) || // Больше максимального значения
    parseFloat(amountConvert.from.value) > parseFloat(amountConvert.from.balance) ||
    parseFloat(amountConvert.from.value) <= 0 ||
    amountConvert.to.symbol === '' ||
    amountConvert.from.value === '' ||
    amountConvert.from.symbol === ''

  if (binanceConvert.isLoadingCoins) {
    return (
      <div className={styles.wrapper}>
        <Loader />
      </div>
    )
  }

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.contentWrapp}>
          <div className={styles.header}>
            <TitleH3 label={t({ id: 'binance.convert.titile', comment: 'Binance Convert' })} />
          </div>
          <div className={styles.content}>
            <div className={styles.market}>
              <div>{t({ id: 'binance.convert.market', comment: 'Market' })}</div>
              <div className={styles.wallet}>{t({ id: 'binance.convert.wallet', comment: 'Wallet' })}</div>
            </div>
            <div className={styles.label}>{t({ id: 'core.from', comment: 'From' })}</div>
            <Input
              size={InputSize.Medium}
              setValue={setAmountFrom}
              value={amountConvert.from.value}
              placeholder={amountConvert.from.placeholder}
              info={
                <>
                  {t({ message: 'Available', id: 'core.transaction.available' })}
                  <span className={styles.available}>: {amountConvert.from.balance}</span>
                </>
              }
              errorMessage={errorMessage}
              inputAttrs={{ type: 'number' }}
            >
              <div className={styles.selectWrapp}>
                <Button.Primary
                  label="Max"
                  className={styles.maxBtn}
                  onClick={handleSetMax}
                  disabled={isMaxButtonDisabled}
                />
                <Select
                  size={Select.Size.Medium}
                  onChange={handleChangeFrom}
                  value={amountConvert.from.symbol}
                  options={uniqueFromCoins}
                  variant={Select.Variant.Raw}
                  withSearch
                />
              </div>
            </Input>
            <div className={styles.changeWrapp}>
              <button onClick={handleChange} className={styles.changeBtn} disabled={isChangeButtonDisabled}>
                <SVG.OtherIcons.Change />
              </button>
            </div>
            <div className={styles.label}>{t({ id: 'core.to', comment: 'To' })}</div>
            <Input
              size={InputSize.Medium}
              setValue={() => {}}
              value={amountConvert.to.value}
              placeholder={amountConvert.to.placeholder}
              info={
                <>
                  {t({ message: 'Available', id: 'core.transaction.available' })}
                  <span className={styles.available}>: {amountConvert.to.balance}</span>
                </>
              }
              inputAttrs={{ type: 'number' }}
              disabled
            >
              <div className={styles.selectWrapp}>
                <Select
                  size={Select.Size.Medium}
                  onChange={handleChangeTo}
                  value={amountConvert.to.symbol}
                  options={toOptions}
                  variant={Select.Variant.Raw}
                  withSearch
                />
              </div>
            </Input>
          </div>
          <div className={styles.btnWrapp}>
            <Button.White
              size={ButtonSize.Medium}
              onClick={openPopup}
              label={t({ message: 'Preview', id: 'core.preview' })}
              disabled={isPreviewDisabled}
              className={styles.btn}
            />
          </div>
        </div>
      </div>
      <BinanceConvertPopup isOpen={isOpenPopup} onClose={closePopup} previewData={preview} />
    </>
  )
}
