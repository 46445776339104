import { t } from '@lingui/macro'
import { FC, memo, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { SVG } from '@/assets/svg'
import { urls } from '@/router/urls'
import { TitleH2 } from '@/ui/atoms/TitleH2'
import { Button, ButtonVariant } from '@/ui/kit'
import { useAppSelector, useMobileSizeDetect } from '@/utils'
import style from './style.module.scss'
import clsx from 'clsx'
import { EXCHANGES_AVAILABLE } from '@/core/constants'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { isExchangeBinance } from '@/utils/lib/exchange'
import { useExchangesDisabled } from '@/utils/hooks/useExchangesDisabled'

const TopUpAccount: FC = () => {
  const [isMobile] = useMobileSizeDetect()
  const { instrumentType, exchangeType } = useAppSelector(state => state.overview)
  const navigate = useNavigate()
  const { isDepositEnabled } = useExchangesDisabled()

  const isSpot = instrumentType === 'SPOT' || instrumentType === 'spot'

  const BUTTONS = useMemo(
    () => [
      {
        id: 1,
        label: t({ message: `Transfer`, id: 'core.transfer' }),
        icon: <SVG.Actions.Transfer className={clsx(!isMobile && style.whiteIcon)} />,
        href: urls.transfer,
        btnType: 'transfer',
        dataTestId: DataTestIds.TopUpTransfer,
        exchangeType: ['BINANCE_BROKER_FUTURE'],
        variant: isMobile ? ButtonVariant.Primary : ButtonVariant.Accent,
      },
      {
        id: 2,
        label: t({ message: `Deposit`, id: 'core.deposit' }),
        icon: <SVG.Actions.Deposit />,
        href: urls.deposite,
        btnType: 'deposit',
        dataTestId: DataTestIds.TopUpDeposit,
        exchangeType: EXCHANGES_AVAILABLE,
        variant: isMobile ? ButtonVariant.Primary : ButtonVariant.AccentDark,
        disabled: !isDepositEnabled,
      },
    ],
    [isMobile]
  )

  return (
    <div className={clsx(style.topUpAccount, isMobile && style.topUpAccountMobile)}>
      <TitleH2
        label={t({ message: `Top up your account`, id: 'topUpAccount.title' })}
        className={clsx(isMobile && style.title)}
      />

      <div className={style.description}>
        {t({
          message: `Transfer assets from one of your wallets or deposit funds to Spot wallet.`,
          id: 'topUpAccount.description',
        })}
      </div>

      {isSpot && (
        <div className={style.buttons}>
          {BUTTONS.map(item => {
            if (!item.exchangeType.includes(exchangeType)) {
              return null
            }

            return (
              <Button
                key={item.id}
                variant={item.variant}
                onClick={() => navigate(item.href)}
                label={item.label}
                rightIcon={item.icon}
                dataTestId={item.dataTestId}
                disabled={item.disabled}
              />
            )
          })}
        </div>
      )}

      {!isSpot && isExchangeBinance(exchangeType) && (
        <div className={style.buttons}>
          <Button
            variant={isMobile ? ButtonVariant.Primary : ButtonVariant.Accent}
            onClick={() => navigate(urls.transfer)}
            label={t({ message: 'Transfer', id: 'core.transfer' })}
            rightIcon={<SVG.Actions.Transfer className={clsx(!isMobile && style.whiteIcon)} />}
            dataTestId={DataTestIds.TopUpTransfer}
          />
        </div>
      )}
    </div>
  )
}

export default memo(TopUpAccount)
