import { createSlice } from '@reduxjs/toolkit'
import { riskManagerAsyncActions } from './riskManager.actions'
import { initialState } from './riskManager.state'
import { RISK_MANAGER_DATA_DEFAULT, TRADING_STATUS_DEFAULT } from './riskManager.defaults'
import { E_RISK_MANAGER_DRAWDOWN_TYPE, E_RISK_MANAGER_TYPE, RiskManagerReason } from './riskManager.types'
import { customRound } from '@/utils/lib/formatNumber'
import { DATE_FORMAT, formatDate } from '@/utils/lib/formatDate'
import BigNumber from 'bignumber.js'

export const slice = createSlice({
  name: `[Tiger Trade Account Risk Manager]`,
  initialState,
  reducers: {
    setSetTeamRiskManagerReset: state => {
      state.rmSetRequestStatus = 'idle'
    },
    setUpdateTeamRiskManagerReset: state => {
      state.rmUpdateRequestStatus = 'idle'
    },
    setDeleteTeamRiskManagerReset: state => {
      state.rmDeleteRequestStatus = 'idle'
    },
    setGetTeamRiskManagerReset: state => {
      state.rmGetRequestStatus = 'idle'
      state.riskManagerSettings = RISK_MANAGER_DATA_DEFAULT
    },
    setErrors: state => {
      state.errors = []
    },
    setEventsReset: state => {
      state.events = []
      state.eventsRequestStatus = 'idle'
    },
    setEventByIdReset: state => {
      state.eventById = null
      state.eventByIdRequestStatus = 'idle'
    },
  },
  extraReducers: builder => {
    builder
      .addCase(riskManagerAsyncActions.GetRiskManagerTC.pending, (state, action) => {
        state.rmGetRequestStatus = 'loading'
      })
      .addCase(riskManagerAsyncActions.GetRiskManagerTC.rejected, (state, action) => {
        state.rmGetRequestStatus = 'failed'
      })
      .addCase(riskManagerAsyncActions.GetRiskManagerTC.fulfilled, (state, action) => {
        state.rmGetRequestStatus = 'succeeded'
        state.riskManagerSettings = {
          ...RISK_MANAGER_DATA_DEFAULT,
          ...action.payload,
        }
      })
      .addCase(riskManagerAsyncActions.SetRiskManagerTC.pending, (state, action) => {
        state.rmSetRequestStatus = 'loading'
      })
      .addCase(riskManagerAsyncActions.SetRiskManagerTC.rejected, (state, action) => {
        state.rmSetRequestStatus = 'failed'
        state.errors = action.payload?.fieldsErrors! || []
      })
      .addCase(riskManagerAsyncActions.SetRiskManagerTC.fulfilled, (state, action) => {
        state.rmSetRequestStatus = 'succeeded'
      })
      .addCase(riskManagerAsyncActions.UpdateRiskManagerTC.pending, (state, action) => {
        state.rmUpdateRequestStatus = 'loading'
      })
      .addCase(riskManagerAsyncActions.UpdateRiskManagerTC.rejected, (state, action) => {
        state.rmUpdateRequestStatus = 'failed'
        state.errors = action.payload?.fieldsErrors! || []
      })
      .addCase(riskManagerAsyncActions.UpdateRiskManagerTC.fulfilled, (state, action) => {
        state.rmUpdateRequestStatus = 'succeeded'
        state.riskManagerSettings = {
          ...state.riskManagerSettings!,
          ...action.meta.arg,
        }
      })
      .addCase(riskManagerAsyncActions.DeleteRiskManagerTC.pending, (state, action) => {
        state.rmDeleteRequestStatus = 'loading'
      })
      .addCase(riskManagerAsyncActions.DeleteRiskManagerTC.rejected, (state, action) => {
        state.rmDeleteRequestStatus = 'failed'
      })
      .addCase(riskManagerAsyncActions.DeleteRiskManagerTC.fulfilled, (state, action) => {
        state.rmDeleteRequestStatus = 'succeeded'
        state.riskManagerSettings = {
          ...state.riskManagerSettings!,
          enabled: false,
        }
      })
      .addCase(riskManagerAsyncActions.GetRiskManagerTradingStatusTC.pending, (state, action) => {
        state.tradingStatusRequestStatus = 'loading'
      })
      .addCase(riskManagerAsyncActions.GetRiskManagerTradingStatusTC.rejected, (state, action) => {
        state.tradingStatusRequestStatus = 'failed'
      })
      .addCase(riskManagerAsyncActions.GetRiskManagerTradingStatusTC.fulfilled, (state, action) => {
        state.tradingStatusRequestStatus = 'succeeded'
        state.tradingStatus = {
          ...TRADING_STATUS_DEFAULT,
          ...action.payload,
        }
      })
      .addCase(riskManagerAsyncActions.GetRiskManagerEventsTC.pending, (state, action) => {
        state.eventsRequestStatus = 'loading'
      })
      .addCase(riskManagerAsyncActions.GetRiskManagerEventsTC.rejected, (state, action) => {
        state.eventsRequestStatus = 'failed'
      })
      .addCase(riskManagerAsyncActions.GetRiskManagerEventsTC.fulfilled, (state, action) => {
        state.events = [
          ...state.events,
          ...action.payload.events.map(event => {
            return {
              ...event,
              eventTimeFormatted: formatDate(event.eventTime, DATE_FORMAT.WITH_SECONDS),
              targetBalance: {
                ...event.targetBalance,
                formattedValue: customRound(event.targetBalance.value),
                value: event.targetBalance.value,
              },
              actualBalance: {
                ...event.actualBalance,
                formattedValue: customRound(event.actualBalance.value),
              },
              reason: event.reason
                ? {
                    ...event.reason,
                    drawdownValueFormatted:
                      event.reason.drawdownType === E_RISK_MANAGER_DRAWDOWN_TYPE.usdt
                        ? customRound(event.reason.drawdownValue)
                        : event.reason.drawdownValue.toString(),
                  }
                : undefined,
            }
          }),
        ]
        state.eventsRequestStatus = 'succeeded'
      })
      .addCase(riskManagerAsyncActions.GetRiskManagerAvailableTC.pending, (state, action) => {
        state.rmAvailableStatus = 'loading'
      })
      .addCase(riskManagerAsyncActions.GetRiskManagerAvailableTC.rejected, (state, action) => {
        state.rmAvailableStatus = 'failed'
      })
      .addCase(riskManagerAsyncActions.GetRiskManagerAvailableTC.fulfilled, (state, action) => {
        const isAvailable = !!action.payload?.isRiskManagerAvailable ?? false

        state.isRmAvailable = isAvailable && action.payload.type === E_RISK_MANAGER_TYPE.internal
        state.availability = action.payload
        state.rmAvailableStatus = 'succeeded'
      })
      .addCase(riskManagerAsyncActions.GetRiskManagerEventByIdTC.pending, (state, action) => {
        state.eventByIdRequestStatus = 'loading'
      })
      .addCase(riskManagerAsyncActions.GetRiskManagerEventByIdTC.rejected, (state, action) => {
        state.eventById = null
        state.eventByIdRequestStatus = 'failed'
      })
      .addCase(riskManagerAsyncActions.GetRiskManagerEventByIdTC.fulfilled, (state, action) => {
        if (state.eventByIdRequestStatus === 'idle') return
        state.eventById = {
          ...action.payload,
          positions: action.payload.positions.map(position => {
            return {
              ...position,
              entryPriceFormatted: customRound(position.entryPrice),
              priceFormatted: customRound(position.price),
              volume: {
                ...position.volume,
                baseCurrency: {
                  ...position.volume.baseCurrency,
                  formattedValue: customRound(position.volume.baseCurrency.value),
                },
                quotationCurrency: {
                  ...position.volume.quotationCurrency,
                  formattedValue: customRound(
                    new BigNumber(position.volume.baseCurrency.value).multipliedBy(position.entryPrice).toString()
                  ),
                },
              },
            }
          }),
        }
        state.eventByIdRequestStatus = 'succeeded'
      })

      .addCase(riskManagerAsyncActions.GetVoluntaryBlockTC.pending, (state, action) => {
        state.isLoadingVoluntaryBlock = true
      })
      .addCase(riskManagerAsyncActions.GetVoluntaryBlockTC.rejected, (state, action) => {
        state.isLoadingVoluntaryBlock = false
      })
      .addCase(riskManagerAsyncActions.GetVoluntaryBlockTC.fulfilled, (state, action) => {
        state.isLoadingVoluntaryBlock = false
      })
  },
})

export const riskManagerActions = {
  ...slice.actions,
  ...riskManagerAsyncActions,
}

export const riskManagerReducer = slice.reducer
