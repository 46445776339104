import { useMemo } from 'react'
import { useAppSelector } from './useAppSelector'

export const useActiveExchage = () => {
  const { activeExchange } = useAppSelector(state => state.app)

  const result = useMemo(() => {
    return { activeExchange }
  }, [activeExchange])

  return result
}
