export const isInvalidPattern = (pin: string) => {
  if (/^(\d)\1+$/.test(pin)) return true

  const isSequential = (str: string) => {
    const digits = str.split('').map(Number)
    return (
      digits.every((d, i, arr) => i === 0 || d === arr[i - 1] + 1) ||
      digits.every((d, i, arr) => i === 0 || d === arr[i - 1] - 1)
    )
  }

  if (isSequential(pin)) return true

  return false
}
