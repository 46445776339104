import { memo, useCallback, useMemo, useState } from 'react'
import { t } from '@lingui/macro'
import { Button, Input, InputSize, Select, SelectOption } from '@/ui/kit'
import { NoCoins, Prompt } from '@/ui/molecules'
import style from './style.module.scss'
import { useMobileSizeDetect, useScrollToError } from '@/utils/hooks'
import { NetworkSelectOption } from '@/ui/atoms/NetworkSelectOption'
import { CoinSelectOption } from '@/ui/atoms/CoinSelectOption'
import BigNumber from 'bignumber.js'
import { InputDropdown, InputDropdownSize } from '@/ui/kit/InputDropdown'
import { useNavigate } from 'react-router-dom'
import { urls } from '@/router/urls'
import { DataTestIds } from '@/utils/lib/dataTestIds'
import { SVG } from '@/assets/svg'
import { Whitelist } from '@/ui/organisms/Whitelist'
import clsx from 'clsx'
import { capitalize } from '@/utils/lib/capitalize'
import { Trans } from '@lingui/react'
import { DangerWithTooltip } from '@/ui/molecules'
import { WithdrawConfirmation } from '@/ui/organisms/WithdrawTransaction/WithdrawConfirmation'
import { checkIsAddressCorrect } from './helpers'
import { formatTransactionValue, MAX_DECIMAL_WITHDRAW, removeTransactionFormat } from '../../helpers'
import { checkIsDecimalsQuantityCorrect } from '@/utils'
import { TitleH3 } from '@/ui/atoms/TitleH3'
import { WalletAddress } from '@/exchanges/tigerBack/types'
import { CoinWithNetworks } from './types'
import { formatDate } from '@/utils/lib/formatDate'

export type NetworkOption = SelectOption & {
  name: string
  fee: number
}

type AssetOption = SelectOption & {
  balance: number
}

export type PropsType = {
  withdrawData: {
    coins: CoinWithNetworks[]
    addresses: WalletAddress[]
    availableLimit: number
    whitelistEnabled: boolean
    withdraw: {
      canWithdraw: boolean
      blockType: 'PASSWORD_CHANGE' | 'WHITE_LIST' | 'PIN_CODE'
      blockUntil: Date
      pinCodeMaxAttempts: number
      pinCodeCurrentAttempt: number
    }
  }
  minWithdraw: number
  hasSubExchanges: boolean
  subExchanges?: SelectOption[]
}

export const WithdrawForm = memo<PropsType>(({ withdrawData, minWithdraw, hasSubExchanges, subExchanges }) => {
  const initialValue = withdrawData.coins[0]
  const navigate = useNavigate()
  const scrollToError = useScrollToError()

  const [coin, setCoin] = useState<AssetOption | any | undefined>(initialValue)
  const [networks, setNetworks] = useState<any | null>(initialValue?.networks ?? null)
  const [activeNetwork, setActiveNetwork] = useState<any | null>(null)
  const [amount, setAmount] = useState<string>('')
  const [amountFormatted, setAmountFormatted] = useState<string>('')
  const [address, setAddress] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [subExchange, setSubExchange] = useState<any | null>(hasSubExchanges ? initialValue.subExchangeType : null)
  const [walletId, setWalletId] = useState<string>('')

  const whitelistEnabled = withdrawData.whitelistEnabled
  const withdrawEnabled = withdrawData.withdraw.canWithdraw

  const coinsOptions = useMemo(() => {
    const filteredCoins = hasSubExchanges
      ? withdrawData.coins?.filter(coin => coin.subExchangeType === subExchange)
      : withdrawData.coins

    return filteredCoins?.map(option => ({
      value: option.coin,
      search: option.coin,
      label: (
        <CoinSelectOption
          asset={option.coin}
          balance={formatTransactionValue(option.balance.toString(), MAX_DECIMAL_WITHDRAW)}
        />
      ),
      selected: option.coin,
    }))
  }, [withdrawData.coins, hasSubExchanges, subExchange])

  const networksOptions = useMemo(() => {
    if (!Array.isArray(networks) || networks.length === 0) return []

    return networks.map((item: any) => {
      const fee = `${t({ message: 'Fee:', id: 'core.fee' })} ${item.withdrawFee ?? '-'} ${item.coin}`

      return {
        value: item.network,
        name: item.name,
        fee: item.withdrawFee,
        label: <NetworkSelectOption symbol={item.name} name={item.alias} fee={fee} disabled={!item.withdrawEnable} />,
        selected: <NetworkSelectOption symbol={item.name} name={item.alias} large />,
        rightIcon: !item.withdrawEnable && (
          <DangerWithTooltip text={t({ comment: 'Network suspended', id: 'withdrawOption.networkSuspended' })} />
        ),
        disabled: !item.withdrawEnable,
      }
    })
  }, [networks])

  const addresseOptions = useMemo(() => {
    if (!Array.isArray(withdrawData.addresses) || withdrawData.addresses.length === 0) return []

    // Собираем список coin с балансом, если надо — по конкретному exchangeType
    const coinsWithBalance = withdrawData.coins
      ?.filter(coin => {
        if (coin.subExchangeType) {
          return coin.subExchangeType === subExchange
        }
        return true
      })
      .map(coin => coin.coin)

    return withdrawData.addresses.map((item: any) => {
      const isBlockedByTime = item?.blockUntil ? item?.blockUntil : false
      const isWithdrawDisabled = !item?.withdrawEnable

      const hasBalanceForCoin = coinsWithBalance?.includes(item.currency)
      const isAddressDisabled = isBlockedByTime || isWithdrawDisabled || !hasBalanceForCoin

      let dangerText: string | undefined = undefined

      if (isBlockedByTime) {
        dangerText = `${t({ comment: 'Address blocked', id: 'withdrawOption.addressBlocked' })}: ${formatDate(
          item?.blockUntil
        )}`
      } else if (!hasBalanceForCoin) {
        dangerText = t({ comment: 'Insufficient balance', id: 'withdrawOption.insufficientBalance' })
      }

      return {
        id: item.id,
        value: item.address,
        currency: item.currency,
        name: item.name,
        search: item.name,
        network: item.network,
        address: item.address,
        group: item.internal,
        disabled: isAddressDisabled,
        rightIcon: !item?.withdrawEnable && (
          <DangerWithTooltip text={t({ comment: 'Network suspended', id: 'withdrawOption.networkSuspended' })} />
        ),
        label: (
          <NetworkSelectOption
            symbol={`${item.name} ${item.internal ? 'Broker' : ''}`}
            className={style.symbolEllipsis}
            name={`${item?.network ?? ''} (${item.currency ?? ''})`}
            disabled={isAddressDisabled}
            dangerText={dangerText}
          />
        ),
        selected: <NetworkSelectOption symbol={item.name} name={item.network} large />,
      }
    })
  }, [withdrawData.addresses, withdrawData.coins, subExchange])

  const handleCoinChange = (value: string) => {
    const findCoin = withdrawData.coins.find(coin => {
      if (hasSubExchanges) {
        return coin.coin === value && coin.subExchangeType === subExchange
      }
      return coin.coin === value
    })

    if (findCoin) {
      setCoin(findCoin)
      setNetworks(findCoin.networks)
      setActiveNetwork(null)
    }
  }

  const handleNetworkChange = (value: string) => {
    const network = networks.find((item: any) => item.network === value)

    if (network) {
      setActiveNetwork(network)
    }
  }

  const handleChangeSubExchange = (value: string) => {
    const findCoin = withdrawData.coins.find(coin => {
      if (hasSubExchanges) {
        return coin.subExchangeType === value
      }

      return coin
    })

    if (findCoin) {
      setSubExchange(value)
      setActiveNetwork(null)
      setCoin(findCoin)
      setNetworks(findCoin.networks)
      setAddress('')
    }
  }

  const handleSetMax = () => {
    if (!coin?.balance) return

    const maxFormattedAmount = formatTransactionValue(coin?.balance.toString(), MAX_DECIMAL_WITHDRAW)

    setAmountFormatted(maxFormattedAmount)
    setAmount(removeTransactionFormat(maxFormattedAmount))
  }

  const handleOptionClick = (item: any) => {
    const findCoin = withdrawData.coins.find(coin => {
      if (hasSubExchanges) {
        return coin.coin === item.currency && coin.subExchangeType === subExchange
      }
      return coin.coin === item.currency
    })

    if (findCoin) {
      setCoin(findCoin)
      setNetworks(findCoin.networks)

      const findNetwork = findCoin.networks.find((network: any) => network.network === item.network)

      if (findNetwork) {
        setActiveNetwork(findNetwork)
      }

      setWalletId(item.id)
    }
  }

  const handleAmountChange = (value: string) => {
    const clearValue = removeTransactionFormat(value, MAX_DECIMAL_WITHDRAW) // Очищенное значение без запятых
    const formattedValue = formatTransactionValue(clearValue, MAX_DECIMAL_WITHDRAW) // Форматируем для UI

    setAmount(clearValue)
    setAmountFormatted(formattedValue)
  }

  const handleAddressChange = (value: string) => {
    setAddress(value)

    const matched = addresseOptions.find(opt => opt.address === value)
    if (!matched) {
      setWalletId('')
    }
  }

  const navigateCreateAddress = () => navigate(urls.createAddresses)
  const addressNavigate = () => navigate(urls.addresses)

  const receiveAmount = useMemo(() => {
    const amountBN = new BigNumber(amount)

    if (amountBN.isNaN() || amountBN.isLessThanOrEqualTo(0) || !amount || !amountBN.isFinite()) {
      return null
    }

    if (activeNetwork) {
      const result = amountBN.minus(activeNetwork.withdrawFee)
      return result.isGreaterThan(0) ? result.toNumber() : 0
    }

    return amount
  }, [amount, activeNetwork])

  const minAmount = useMemo(() => {
    return Math.max(activeNetwork?.withdrawMin ?? 0, minWithdraw, activeNetwork?.withdrawFee ?? 0)
  }, [activeNetwork?.withdrawMin, activeNetwork?.withdrawFee, minWithdraw])

  const groupTitles = [
    {
      id: 'wallet',
      element: <Trans id="core.transaction.myWallet" />,
    },
    {
      id: 'address',
      element: t({ message: 'Address book', id: 'core.transaction.addressBook' }),
    },
  ]

  const getAmountErrorMessage = () => {
    if (amount === '') {
      return t({ message: 'Enter amount', id: 'withdrawConfirmation.messages.enterAmount' })
    }

    if (Number(amount) > Number(coin?.balance)) {
      return t({ message: 'Insufficient funds', id: 'withdrawConfirmation.messages.isufFunds' })
    }

    if (activeNetwork?.withdrawMax && Number(amount) > activeNetwork.withdrawMax) {
      return `${t({
        message: 'Maximum amount is',
        id: 'withdrawConfirmation.messages.maxIs',
      })} ${activeNetwork?.withdrawMax}`
    }

    if (Number(amount) < minAmount) {
      return `${t({ message: 'Minimum amount is', id: 'withdrawConfirmation.messages.minIs' })} ${minAmount}`
    }

    if (!checkIsDecimalsQuantityCorrect(amount, MAX_DECIMAL_WITHDRAW)) {
      return `${t({
        message: 'Only ',
        id: 'withdrawConfirmation.messages.onlyDec-1',
      })} ${MAX_DECIMAL_WITHDRAW} ${t({
        message: 'decimal places are allowed',
        id: 'withdrawConfirmation.messages.onlyDec-2',
      })}`
    }

    return ''
  }

  const amountErrorMessage = getAmountErrorMessage()

  const isAddressCorrect = checkIsAddressCorrect(address, activeNetwork?.addressRegex)
  const isWithdrawAvailable = Boolean(amountErrorMessage === '' && isAddressCorrect && activeNetwork?.name && coin)

  const processForm = useCallback(() => {
    setIsWithdrawAttemptDetected(true)
    setIsModalVisible(isWithdrawAvailable)
    scrollToError()
  }, [isWithdrawAvailable, scrollToError])

  const isDisableConfirm =
    !withdrawEnabled ||
    !activeNetwork ||
    isNaN(Number(amount)) ||
    Number(amount) < minAmount ||
    Number(amount) > Number(withdrawData.availableLimit) ||
    (coin && Number(amount) > Number(coin.balance))
  // !isAddressCorrect

  /////////////////////////////////////////////////////////////////////////////
  const [isMobile] = useMobileSizeDetect()
  const [isWithdrawAttemptDetected, setIsWithdrawAttemptDetected] = useState(false)

  const isAddressInvalid = isWithdrawAttemptDetected && !!activeNetwork && !isAddressCorrect
  const isNetworkInvalid = isWithdrawAttemptDetected && !activeNetwork

  const amountError = useMemo(() => {
    const error = [
      isWithdrawAttemptDetected && !coin && t({ message: 'Select Coin', id: 'withdrawConfirmation.selectCoin' }),
      isWithdrawAttemptDetected && !!activeNetwork && amountErrorMessage,
    ]
      .filter(Boolean)
      .join(` ${t({ message: 'and', id: 'core.and' })} `)

    return capitalize(error.toLowerCase())
  }, [isWithdrawAttemptDetected, coin, activeNetwork, amountErrorMessage])

  const addressError =
    isAddressInvalid && t({ message: `Incorrect Spot address`, id: 'withdrawConfirmation.inccorectSpotAddress' })

  const WITHDRAW_BLOCKTYPE = {
    PASSWORD_CHANGE: t({ comment: 'PASSWORD_CHANGE', id: 'withdrawform.blocktype.password' }),
    WHITE_LIST: t({ comment: 'WHITE_LIST', id: 'withdrawform.blocktype.whitelist' }),
    PIN_CODE: t({ comment: 'PIN_CODE', id: 'withdrawform.blocktype.pincode' }),
  }

  if (!withdrawEnabled) {
    return (
      <div className={style.withdrawBlock}>
        <TitleH3 label={t({ comment: 'Block Withdraw', id: 'withdrawform.block.title' })} />
        {withdrawData.withdraw?.blockType && (
          <Prompt promptType="text" text={WITHDRAW_BLOCKTYPE[withdrawData.withdraw.blockType] ?? ''} />
        )}
      </div>
    )
  }

  return (
    <div className={clsx(isMobile && style.mobile, style.withdraw)}>
      {hasSubExchanges && subExchanges && subExchanges.length > 0 && (
        <Select
          size={Select.Size.Large}
          options={subExchanges}
          label={t({ message: 'From', id: 'core.transaction.from' })}
          value={subExchange}
          onChange={handleChangeSubExchange}
          dataTestId={DataTestIds.TransferAccountTypeSectionFrom}
        />
      )}
      <div>
        <InputDropdown
          value={address}
          size={InputDropdownSize.Medium}
          label={t({ message: `Spot`, id: 'core.spot' })}
          placeholder={t({ message: 'Enter address', id: 'withdrawConfirmation.enterAddress' })}
          onChange={handleAddressChange}
          group
          groupTitles={groupTitles}
          options={addresseOptions}
          error={addressError as string}
          className={style.networkSelect}
          optionClick={handleOptionClick}
          handleButtonClick={navigateCreateAddress}
          dataTestId={DataTestIds.WithdrawAddressInput}
          Icon={<SVG.OtherIcons.Address />}
          iconClick={addressNavigate}
          disabled={whitelistEnabled}
        />
        {whitelistEnabled && (
          <Whitelist whitelistEnabled={whitelistEnabled} className={clsx(isMobile && style.whiteList)} />
        )}
      </div>
      <div className={style.coins}>
        <Input
          size={InputSize.Large}
          setValue={handleAmountChange}
          value={amountFormatted}
          placeholder="0"
          errorMessage={amountError}
          containerClassName={style.input}
          dataTestId={DataTestIds.AmountInput}
          info={
            coin && (
              <>
                {t({ message: 'Available', id: 'core.transaction.available' })}{' '}
                <span className={style.available} data-testid={DataTestIds.AmountAvailableField}>
                  {formatTransactionValue(coin.balance.toString(), MAX_DECIMAL_WITHDRAW)} {coin.value}
                </span>
              </>
            )
          }
        >
          <div className={style.inputAside}>
            {coin && (
              <Button.Primary
                label="Max"
                className={style.maxBtn}
                onClick={handleSetMax}
                dataTestId={DataTestIds.AmountMaxButton}
              />
            )}
            {withdrawData.coins && withdrawData.coins.length > 0 ? (
              <Select
                size={Select.Size.Medium}
                variant={Select.Variant.Raw}
                value={coin?.coin}
                options={coinsOptions}
                placeholder={t({ message: 'Select Coin', id: 'withdrawConfirmation.selectCoin' })}
                className={style.coinSelect}
                onChange={handleCoinChange}
                dataTestId={DataTestIds.CoinSelector}
                disabled={whitelistEnabled}
              />
            ) : (
              <NoCoins />
            )}
          </div>
        </Input>
        <Select
          size={Select.Size.Medium}
          options={networksOptions}
          value={activeNetwork?.network}
          onChange={handleNetworkChange}
          placeholder={t({ message: 'Select Network', id: 'withdrawConfirmation.selectNetwork' })}
          disabled={!coin || whitelistEnabled}
          errorMessage={isNetworkInvalid && t({ message: 'Select Network', id: 'withdrawConfirmation.selectNetwork' })}
          dataTestId={DataTestIds.NetworkSelector}
        />
      </div>
      {coin && (
        <div className={style.willReceiveWrap}>
          {subExchange && (
            <div className={style.fee}>
              <div>{t({ comment: 'Exchange Type', id: 'core.transaction.exchangeType' })}</div>
              <div>X {subExchange}</div>
            </div>
          )}
          {activeNetwork && (
            <div className={style.fee} data-testid={DataTestIds.WithdrawFeeAmount}>
              <div>{t({ comment: 'Fee network', id: 'withdrawConfirmation.fee' })}</div>
              <div>
                {activeNetwork?.withdrawFee} {coin.coin}
              </div>
            </div>
          )}
          <div className={style.fee}>
            <div>{t({ comment: 'Min Amount', id: 'core.transaction.minAmount' })}</div>
            <div>
              {minAmount} {coin.coin}
            </div>
          </div>
          <div className={style.fee}>
            <a href={urls.kycLevels} className={style.link}>
              {t({ comment: 'Available limit', id: 'core.transaction.availableLimit' })}
            </a>
            <div>{formatTransactionValue(withdrawData.availableLimit.toString(), MAX_DECIMAL_WITHDRAW)} USD</div>
          </div>
          {receiveAmount !== null && receiveAmount !== undefined && (
            <div className={style.receive} data-testid={DataTestIds.WithdrawSendingAmount}>
              <div className={style.receiveDescription}>
                {t({ comment: 'You will receive', id: 'withdrawConfirmation.willReceive' })}
              </div>
              <div className={style.receiveValue}>
                {formatTransactionValue(receiveAmount.toString(), MAX_DECIMAL_WITHDRAW)} {coin.coin}
              </div>
            </div>
          )}
        </div>
      )}
      <div className={style.promptWrap} data-testid={DataTestIds.DepositAlertDescription}>
        <Prompt
          promptType={'transaction'}
          coin={coin?.coin}
          networkSymbol={activeNetwork?.name}
          networkType={activeNetwork?.network}
        />
      </div>
      <Button.Accent
        size={Button.Size.Large}
        className={style.button}
        label={t({ message: 'Confirm withdraw', id: 'withdrawConfirmation.confirmTransaction' })}
        onClick={processForm}
        dataTestId={DataTestIds.WithdrawConfirmButton}
        disabled={isDisableConfirm}
      />
      {isModalVisible && (
        <WithdrawConfirmation
          amount={amount}
          hasSubExchanges={hasSubExchanges}
          subExchange={subExchange ?? undefined}
          network={activeNetwork.network}
          tokenSymbol={coin?.coin}
          walletAddress={address}
          closeModal={() => setIsModalVisible(false)}
          walletId={walletId}
        />
      )}
    </div>
  )
})
