import { Deposit } from './Deposit'
import { Withdraw } from './Withdraw'
import { Phone } from './Phone'
import { Burn } from './Burn'
import { SingleAsset } from './SingleAsset'
import { MultiAsset } from './MultiAsset'
import { Binance } from './Binance'
import { Bybit } from './Bybit'
import { Okx } from './OKX'
import { OkxSmall } from './OKXSmall'
import { BinanceSmall } from './BinanceSmall'
import { BybitSmall } from './BybitSmall'
import { Hedge } from './Hedge'
import { Address } from './Address'
import { Whitelist } from './Whitelist'
import { BinanceSquare } from './BinanceSquare'
import { ApiRepair } from './ApiRepair'
import { Block } from './Block'
import { Shield } from './Shield'
import { TigerX } from './TigerX'
import { PinCode } from './PinCode'

export const Settings = {
  Deposit,
  Withdraw,
  Phone,
  Burn,
  SingleAsset,
  MultiAsset,
  Binance,
  Okx,
  Bybit,
  BybitSmall,
  BinanceSmall,
  OkxSmall,
  Hedge,
  Address,
  Whitelist,
  BinanceSquare,
  ApiRepair,
  Block,
  Shield,
  PinCode,
  TigerX,
}
