import { t } from '@lingui/macro'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonVariant, InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import { defineFlowStep, DefineFlowStepResult } from './utils'
import {
  useActions,
  useAppSelector,
  UseGoogleAuthCodeResult,
  UseGoogleAuthSettingsResult,
  useKYC,
  useMobileSizeDetect,
} from '@/utils/hooks'
import { SuccessVerification } from '../SuccessVerification'
import { ErrorBase } from '@/ui/kit/Errors/ErrorBase'
import { urls } from '@/router/urls'
import { Popup2FAWithIntro } from '../Popup2FAWithIntro'
import style from './styles.module.scss'
import styles from './style.module.scss'
import { profileActions } from '@/redux/profile/profile.slice'
import { AvailableScreenSize } from '@/types'
import { EInteractiveModalVariant } from '@/ui/kit/Modals/InteractiveModal/InteractiveModal'
import { defineMetadata } from '@/utils/lib/metadata/mapper'
import { PromoMessage } from '@/ui/molecules/PromoMessage'

interface PopupSequenceProps {
  googleAuthSettings: UseGoogleAuthSettingsResult
  googleAuthCode: UseGoogleAuthCodeResult
  allowedPopups?: DefineFlowStepResult[]
}

export const PopupSequence = (params: PopupSequenceProps) => {
  const { allowedPopups, googleAuthSettings, googleAuthCode } = params

  const [isMobile, setMode] = useMobileSizeDetect()

  const { userEmail, metadata } = useAppSelector(state => state.profile)

  const { cashback } = useAppSelector(state => state.cashback)
  const { userKYC } = useAppSelector(state => state.kyc)
  const { exchangeStatus } = useAppSelector(state => state.app)

  const { UpdateMetadata } = useActions(profileActions)

  const kYC = useKYC()
  const navigate = useNavigate()

  const addKYSSuccessToShownPopupList = useCallback(() => {
    UpdateMetadata(
      defineMetadata({
        shownPopupList: [...metadata.shownPopupList, 'kyc_success'],
      })
    )
  }, [metadata, UpdateMetadata])

  const flowStep = defineFlowStep({
    kYC,
    googleAuthSettings,
    allowedPopups,
    shownPopupList: metadata.shownPopupList,
    userEmail,
    exchangeStatus,
    cashback,
  })

  const [currentFlowStep, setCurrentFlowStep] = useState<DefineFlowStepResult | null>(flowStep)
  const closePopup = useCallback(() => {
    setCurrentFlowStep(null)
  }, [])

  useEffect(() => {
    setCurrentFlowStep(flowStep)
  }, [flowStep])

  const handleCloseKYCSuccess = useCallback(() => {
    closePopup()
    addKYSSuccessToShownPopupList()
  }, [closePopup, addKYSSuccessToShownPopupList])

  const handleClosePromoMessage = useCallback(() => {
    UpdateMetadata(
      defineMetadata({
        shownPopupList: [...metadata.shownPopupList, 'promomessage'],
      })
    )

    closePopup()
  }, [metadata, UpdateMetadata])

  // TODO: убрать, когда добавится мобильная версия для "kyc"
  const handleChangeSizeMode = useCallback(() => {
    if (!isMobile) {
      return
    }
    setMode(AvailableScreenSize.Desktop)
  }, [isMobile, setMode])

  return (
    <>
      <InteractiveModal isOpen={currentFlowStep === 'loading'}>
        <div />
      </InteractiveModal>

      <InteractiveModal isOpen={currentFlowStep === 'kyc_unverified'}>
        <ErrorBase
          customHeader={t({
            id: 'popupSequence.unverified.title',
            message: `Unverified`,
          })}
          onClickAnotherAttemptButton={() => {
            handleChangeSizeMode()
            navigate(urls.kyc)
          }}
          anotherAttemptButtonText={t({
            id: 'popupSequence.unverified.action',
            message: `Verify me`,
          })}
          anotherAttemptButtonVariant={ButtonVariant.Accent}
          isAnotherAttemptButtonVisible={true}
          customText={t({
            id: 'popupSequence.unverified.subtitle',
            message: `Complete verification to deposit funds`,
          })}
          className={style.customError}
          isSupportMessage={true}
          isCloseWindowVisible
          closeWindowText={t({
            id: 'core.back',
            message: `Back`,
          })}
          onClickCloseCallback={() => {
            closePopup()
            navigate(-1)
          }}
        />
      </InteractiveModal>

      <InteractiveModal isOpen={currentFlowStep === 'kyc_in_progress_1'}>
        <ErrorBase
          customHeader={t({
            id: 'popupSequence.inProgress1.title',
            message: `Verification in progress`,
          })}
          anotherAttemptButtonText={t({
            id: 'popupSequence.inProgress1.action',
            message: `Add data`,
          })}
          onClickAnotherAttemptButton={() => {
            closePopup()
            handleChangeSizeMode()
            navigate(urls.kyc)
          }}
          anotherAttemptButtonVariant={ButtonVariant.Accent}
          isAnotherAttemptButtonVisible={true}
          customText={t({
            id: 'popupSequence.inProgress1.subtitle',
            message: `Provide the required documents to finish the verification process`,
          })}
          isCustomButtonVisible={true}
          customButtonVariant={ButtonVariant.Primary}
          customButtonText={t({
            id: 'core.back',
            message: `Back`,
          })}
          onClickCustomButton={() => {
            closePopup()
            navigate(-1)
          }}
          className={style.customError}
          isSupportMessage={true}
        />
      </InteractiveModal>

      <InteractiveModal isOpen={currentFlowStep === 'kyc_in_progress_2'}>
        <ErrorBase
          customHeader={t({
            id: 'popupSequence.inProgress2.title',
            message: `Verification under review`,
          })}
          anotherAttemptButtonText={t({
            id: 'popupSequence.inProgress2.action',
            message: `Check my status`,
          })}
          onClickAnotherAttemptButton={() => {
            closePopup()
            handleChangeSizeMode()
            navigate(urls.kyc)
          }}
          anotherAttemptButtonVariant={ButtonVariant.Accent}
          isAnotherAttemptButtonVisible={true}
          customText={t({
            id: 'popupSequence.inProgress2.subtitle',
            message: `We’ll inform you about the outcome of the verification soon`,
          })}
          isCustomButtonVisible={true}
          customButtonVariant={ButtonVariant.Primary}
          customButtonText={t({
            id: 'core.back',
            message: `Back`,
          })}
          onClickCustomButton={() => {
            closePopup()
            navigate(-1)
          }}
          className={style.customError}
          isSupportMessage={true}
        />
      </InteractiveModal>

      <InteractiveModal isOpen={currentFlowStep === 'kyc_failed'}>
        <ErrorBase
          customHeader={t({
            id: 'popupSequence.failed.title',
            message: `Verification failed`,
          })}
          anotherAttemptButtonText={t({
            id: 'popupSequence.failed.action',
            message: `Try again now`,
          })}
          onClickAnotherAttemptButton={() => {
            handleChangeSizeMode()
            navigate(urls.kyc)
          }}
          anotherAttemptButtonVariant={ButtonVariant.Accent}
          isAnotherAttemptButtonVisible={true}
          isCustomButtonVisible={true}
          customButtonText={t({
            id: 'core.back',
            message: `Back`,
          })}
          customButtonVariant={ButtonVariant.Primary}
          onClickCustomButton={() => {
            closePopup()
            navigate(-1)
          }}
          customText={t({
            id: 'popupSequence.failed.subtitle',
            message: `We weren’t able to upgrade you to the Standard level verification`,
          })}
          isSupportMessage={true}
          className={style.customError}
        />
      </InteractiveModal>

      <InteractiveModal isOpen={currentFlowStep === 'kyc_success'}>
        <SuccessVerification
          isUnlimited={userKYC.kycTier === 'ADVANCED_PLUS'}
          onClickDepositButton={handleCloseKYCSuccess}
          onClickReturnButton={handleCloseKYCSuccess}
        />
      </InteractiveModal>

      <InteractiveModal isOpen={currentFlowStep === 'exchange_init'}>
        <ErrorBase
          customHeader={t({ message: 'Please wait', id: 'core.exchangeSetup.title' })}
          customText={t({ message: 'We are setting your trading account up', id: 'core.exchangeSetup.subtitle' })}
          className={style.customError}
          isCloseWindowVisible
          closeWindowText={t({
            id: 'core.back',
            message: `Back`,
          })}
          onClickCloseCallback={() => {
            closePopup()
            navigate(-1)
          }}
        />
      </InteractiveModal>

      <InteractiveModal
        variant={EInteractiveModalVariant.MEDIUM}
        isOpen={currentFlowStep === 'promomessage'}
        className={styles.promoMessage}
      >
        <PromoMessage />
        <InteractiveModalParts.Button
          className={styles.okButton}
          text={t({ id: 'core.close', message: `Close` })}
          variant={ButtonVariant.Accent}
          onClick={handleClosePromoMessage}
        />
      </InteractiveModal>

      <Popup2FAWithIntro
        isOpen={currentFlowStep === 'twoFA'}
        googleAuthSettings={googleAuthSettings}
        googleAuthCode={googleAuthCode}
        header={
          <Fragment>
            {t({
              id: 'popupSequence.2fa.message-1',
              message: 'Confirm your account',
            })}
            <br />
            {t({
              id: 'popupSequence.2fa.message-2',
              message: 'to make the transaction',
            })}
          </Fragment>
        }
        onSuccess={closePopup}
        onClose={() => {
          closePopup()
          navigate(-1)
        }}
      />
    </>
  )
}
