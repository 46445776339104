import { BinanceConfig } from './binance/binance.config'
import { BinanceVipConfig } from './binanceVip/binanceVip.config'
import { BybitConfig } from './bybit/bybit.config'
import { OkxAffiliateConfig } from './okxAffiliate/okxAffiliate.config'
import { TigerXConfig } from './tigerx/tigerx.config'

export const exchangeFactory: Record<string, any> = {
  BINANCE_BROKER_FUTURE: {
    ...BinanceConfig,
  },
  BINANCE_VIP: {
    ...BinanceVipConfig,
  },
  BYBIT_BROKER: {
    ...BybitConfig,
  },
  OKX_AFFILIATE: {
    ...OkxAffiliateConfig,
  },
  TIGER_X: {
    ...TigerXConfig,
  },
}

// Извлекаем типы доступных бирж из фабрики и добавляем их в список
export const availableExchanges = Object.values(exchangeFactory)
  .filter(config => config.isAvailable) // Проверяем доступность
  .map(config => config.type) // Извлекаем типы
