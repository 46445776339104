import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Controller } from 'react-hook-form'
import { t } from '@lingui/macro'
import { Button, Input, InputSize, Select } from '@/ui/kit'
import { IFormControlData, useControlForm } from './hooks/useControlForm'
import { IAllocateTigerToken } from '@/core/infra/v1/TigerToken/AllocateTigerToken.interface'
import { urls } from '@/router/urls'
import s from './style.module.scss'

export interface IAllocateTokensFormProps {
  formData: IFormControlData
  handleSubmit: (data: IAllocateTigerToken) => void
}

//todo: add validation to form
export const AllocateTokensForm: React.FC<IAllocateTokensFormProps> = props => {
  const { formData, handleSubmit } = props
  const { form, assetsOptions, vlidateBySubmit } = useControlForm(formData)
  const {
    control,
    formState: { errors },
  } = form

  const navigate = useNavigate()

  const handleDepositClick = useCallback(() => {
    localStorage.setItem('transactionType', 'deposit')
    navigate(urls.deposite)
  }, [navigate])

  return (
    <>
      <form onSubmit={form.handleSubmit(vlidateBySubmit(handleSubmit))}>
        <div className={s.fieldInput}>
          <Controller
            control={control}
            name={'amount'}
            render={({ field }) => (
              <Input
                size={InputSize.Small}
                placeholder={t({ message: `Enter your amount`, id: 'tigerToken.form.amount' })}
                value={field.value}
                setValue={field.onChange}
                label={t({ message: 'Send', id: 'core.send' })}
                inputAttrs={{ type: 'number' }}
                errorMessage={errors.amount?.message || errors?.asset?.message}
                disabled={!assetsOptions.length}
              />
            )}
          />
          <div className={s.coinSelect}>
            {assetsOptions?.length ? (
              <Controller
                control={control}
                name={'asset'}
                render={({ field }) => (
                  <Select
                    label={t({ message: `Spot balance`, id: 'tigerToken.form.spotBalance' })}
                    placeholder={t({ message: `Select`, id: 'tigerToken.form.selectAsset' })}
                    onChange={field.onChange}
                    size={Select.Size.Medium}
                    value={field.value}
                    options={assetsOptions}
                    variant={Select.Variant.Raw}
                  />
                )}
              />
            ) : null}
          </div>
        </div>
        <Button.Accent
          type="submit"
          label={t({ message: `Allocate`, id: 'tigerToken.form.allocate' })}
          description={t({ message: `Without comission`, id: 'core.withoutCommission' })}
          className={s.button}
          disabled={!assetsOptions.length}
        />
      </form>

      {!assetsOptions.length && (
        <>
          <div className={s.noFunds}>
            {t({ message: 'You don’t have enough funds to allocate', id: 'tigerToken.form.allocate.dontEnugh' })}
          </div>
          <Button.Accent
            label={t({ message: `Deposit now`, id: 'tigerToken.form.allocate.depositeNow' })}
            className={s.button}
            onClick={handleDepositClick}
          />
        </>
      )}
    </>
  )
}

AllocateTokensForm.displayName = 'BuyTokenForm'
