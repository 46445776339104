import {
  GetSpotLeaderboardTC,
  GetFuturesLeaderboardTC,
  GetPnlLeaderboardTC,
  GetTeamMemberTC,
  PutUpdateNicknameTC,
} from './leaderboard.thunks'

export const leaderboardAsyncActions = {
  GetSpotLeaderboardTC,
  GetFuturesLeaderboardTC,
  GetPnlLeaderboardTC,
  GetTeamMemberTC,
  PutUpdateNicknameTC,
}
