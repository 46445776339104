import { useEffect, useState } from 'react'
import { TransactionWrapper } from '../TransactionWrapper/TransactionWrapper'
import { TigerBackApi } from '@/exchanges/tigerBack/tigerBack.api'
import { CoinWithNetworks } from './WithdrawForm/types'
import { WithdrawForm } from './WithdrawForm/WithdrawForm'
import { useActiveExchage } from '@/utils/hooks/useActiveExchange'
import { t } from '@lingui/macro'
import { useAppSelector } from '@/utils'
import { useNavigate } from 'react-router-dom'
import { urls } from '@/router/urls'

export type DepositeData = {
  options: CoinWithNetworks[]
}

export function WithdrawPage() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { activeExchange } = useActiveExchage()
  const [error, setError] = useState<string | null>(null)
  const [errorCode, setErrorCode] = useState<number | null>(null)
  const [withdrawData, setWithdrawData] = useState<any | null>(null)

  const { userKYC } = useAppSelector(state => state.kyc)

  const fetchTransferData = async () => {
    setLoading(true)
    setError(null)
    setErrorCode(null)

    try {
      const data = await TigerBackApi.getWithdrawData()

      if (!data) {
        throw new Error('No withdraw data has been received')
      }

      if (data.error) {
        setErrorCode(data.error.status) // Сохраняем код ошибки
        throw new Error(`${data.error.source}: ${data.error.message} (code: ${data.error.status})`)
      }

      setWithdrawData(data)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Unknown error')
    } finally {
      setLoading(false)
    }
  }

  //delete when refactor P2-588
  useEffect(() => {
    if (userKYC.kycTier === 'UNVERIFIED' || userKYC.kycTier === 'BASIC') {
      navigate(urls.wallet)
    }
  }, [userKYC.kycTier])

  useEffect(() => {
    if (!withdrawData) {
      fetchTransferData()
    }
  }, [withdrawData])

  function getSubExchangesByType(type: string) {
    switch (type) {
      case 'TIGER_X':
        return [
          { label: 'X Binance', value: 'BINANCE' },
          { label: 'X OKX', value: 'OKX' },
        ]

      default:
        return undefined
    }
  }
  const subExchanges = activeExchange.hasSubExchanges ? getSubExchangesByType(activeExchange.type) : undefined

  return (
    <TransactionWrapper
      title={t({ message: `Withdraw`, id: 'core.withdraw' })}
      loading={loading}
      error={error}
      errorCode={errorCode}
      onRetry={fetchTransferData}
    >
      {withdrawData && (
        <WithdrawForm
          withdrawData={withdrawData}
          minWithdraw={activeExchange.transactions.withdraw.minWithdraw}
          hasSubExchanges={activeExchange.hasSubExchanges}
          subExchanges={subExchanges}
        />
      )}
    </TransactionWrapper>
  )
}
